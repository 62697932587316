import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { change, Field, formValueSelector } from 'redux-form'
import Autocomplete from '../../../../../../components/form/Autocomplete'
import DateFieldCustom from '../../../../../../components/form/DateFieldCustom'
import Input from '../../../../../../components/form/Input'
import Radio from '../../../../../../components/form/Radio'
import ItineraireTechniqueIcon from '../../../../../../components/icon/ItineraireTechniqueIcon'
import { CHOIX_DATES, colors, ETAPE_TYPE, TYPE_PLANCHE } from '../../../../../../utils/constants'
import { fields, getAutocompleteOptions } from '../NouvelleCulture'
import Typography from '@material-ui/core/Typography'
import { formatInteger, getJanuaryInCampain, normalizeDate, normalizeNumber } from '../../../../../../utils/utils'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import moment from 'moment'
import classnames from "classnames";

const NB_DAYS = 7

const styles = () => getStyles({
	form: {
		width: '100%'
	},
	titleField: {
		marginTop: 30,
		marginBottom: 19,
		marginLeft: 10,
		fontWeight: 'bold',
		fontSize: 18
	},
	icon: {
		marginRight: 10,
		marginTop: 5
	},
	blocText: {
		marginTop: 20,
		fontSize: 14,
		marginLeft: 10,
		marginBottom: 10
	},
	blocText2: {
		marginTop: 20,
		fontSize: 16,
		marginBottom: 10
	},
	ligneText: {
		marginBottom: 10
	},
	important: {
		fontWeight: 'bold',
		marginBottom: 20
	},
	radio: {
		width: '100%'
	},
	paddingLeft: {
		paddingLeft: '10px'
	},
	paddingBot: {
		paddingBottom: '10px'
	},
	flex: {
		display: 'flex',
		justifyContent: 'flex-start'
	},
	optionDetail: {
		color: colors.placeholder,
		marginLeft: 10,
		fontSize: '0.85em',
		marginRight: 10,
		paddingRight: 25
	}
})

const formattedMessageMenuBase = 'planning.cultureForm'

const getDebutSemis = (assolement, campagne, debutSemisWeek, extraDays = 0, format = 'YYYY-MM-DD') => {
	// Calcul de la date de début de semis via la campagne et l'itk
	const campagneFound = (assolement && assolement.campagne) || campagne
	return getJanuaryInCampain(campagneFound)
		.add(debutSemisWeek - 1, 'weeks')
		.startOf('isoWeek')
		.add(extraDays, 'days')
		.format(format)
}

const getFinSemis = (assolement, campagne, itk, extraDays = 0, format = 'YYYY-MM-DD') => {
	// Calcul de la date de début de semis via la campagne et l'itk
	const campagneFound = (assolement && assolement.campagne) || campagne
	// on retire un jour pour obtenir un dimanche
	return getJanuaryInCampain(campagneFound)
		.add((itk.debutSemis - 1) + itk.dureeSemis, 'weeks')
		.startOf('isoWeek')
		.add(extraDays, 'days')
		.subtract(1, 'days')
		.format(format)
}

const getExtraWeeks = (allDetails, itk) => {
	const itkDetails = allDetails.filter(details => details.idItk === itk.id)
	const etapeR = itkDetails.find(detail => detail.typeEtape === ETAPE_TYPE.RECOLTE)
	const etapeSC = itkDetails.find(detail => detail.typeEtape === ETAPE_TYPE.SEMI_EN_CONTENANT)
	const etapeSD = itkDetails.find(detail => detail.typeEtape === ETAPE_TYPE.SEMI_DIRECT)
	return etapeR?.timeline - (etapeSC ? etapeSC?.timeline : etapeSD?.timeline)
}

const getDebutRecolte = (campagne, itk, allDetails) => {
	return getDebutSemis(null, campagne, itk.debutSemis, getExtraWeeks(allDetails, itk) * NB_DAYS, 'DD/MM')
}

const getFinRecolte = (campagne, itk, allDetails) => {
	return getFinSemis(null, campagne, itk, getExtraWeeks(allDetails, itk) * NB_DAYS, 'DD/MM')
}

const sortOptions = (options, choixDate, dateValue, campagne, allDetails) => {
	if (dateValue) {
		if (choixDate === CHOIX_DATES.SEMIS) {
			// Options dont la période de semis contient la date choisie
			const optionsWithDateValue = options.filter(option => {
				const debutSemis = getDebutSemis(null, campagne, option.itk.debutSemis)
				const finSemis = getFinSemis(null, campagne, option.itk, 0, 'DD/MM')
				return moment(dateValue).isSameOrAfter(moment(debutSemis)) && moment(dateValue).isSameOrBefore(moment(finSemis, 'DD/MM'))
			})
			// Options dont la période de semis ne contient pas la date choisie
			const optionsWithoutDateValue = options.filter(option => optionsWithDateValue.indexOf(option) < 0)
			// On retourne toute les options en affichant en premier celles dont la période de semis contient la date choisie en les triant par
			// ordre de proximité entre la date de début de semis et la date choisie, puis on affiche celles dont la période ne la contient pas
			// en appliquant le même tri
			return [
				...optionsWithDateValue.sort((o1, o2) => Math.abs(moment(dateValue).diff(moment(getDebutSemis(null, campagne, o1.itk.debutSemis)), 'days')) > Math.abs(moment(dateValue).diff(moment(getDebutSemis(null, campagne, o2.itk.debutSemis)), 'days'))),
				...optionsWithoutDateValue.sort((o1, o2) => Math.abs(moment(dateValue).diff(moment(getDebutSemis(null, campagne, o1.itk.debutSemis)), 'days')) > Math.abs(moment(dateValue).diff(moment(getDebutSemis(null, campagne, o2.itk.debutSemis)), 'days')))
			]
		} else {
			// Options dont la période de récolte contient la date choisie
			const optionsWithDateValue = options.filter(option => {
				const debutRecolte = getDebutRecolte(campagne, option.itk, allDetails)
				const finRecolte = getFinRecolte(campagne, option.itk, allDetails)
				return moment(dateValue).isSameOrAfter(moment(debutRecolte)) && moment(dateValue).isSameOrBefore(moment(finRecolte, 'DD/MM'))
			})
			// Options dont la période de récolte ne contient pas la date choisie
			const optionsWithoutDateValue = options.filter(option => optionsWithDateValue.indexOf(option) < 0)
			// On retourne toute les options en affichant en premier celles dont la période de récolte contient la date choisie en les triant par
			// ordre de proximité entre la date de début de récolte et la date choisie, puis on affiche celles dont la période ne la contient pas
			// en appliquant le même tri
			return [
				...optionsWithDateValue.sort((o1, o2) => Math.abs(moment(dateValue).diff(moment(getDebutRecolte(campagne, o1.itk, allDetails)), 'days')) > Math.abs(moment(dateValue).diff(moment(getFinRecolte(campagne, o2.itk, allDetails)), 'days'))),
				...optionsWithoutDateValue.sort((o1, o2) => Math.abs(moment(dateValue).diff(moment(getDebutRecolte(campagne, o1.itk, allDetails)), 'days')) > Math.abs(moment(dateValue).diff(moment(getFinRecolte(campagne, o2.itk, allDetails)), 'days')))
			]
		}
	} else {
		return options
	}
}

const ItineraireForm = ({ campagne, planches, assolement, itks, itkDetails, formName, plancheValue, itkValue, choixDate, dateValue, change, editItk = false, intl, classes }) => {

	// Information sur les éléments sélectionnés par autocomplete
	const plancheSelected = editItk ? assolement.planche : planches && plancheValue && planches.find(planche => planche.id === plancheValue)
	const itkSelected = itks && itkValue && itks.find(itk => itk.id === itkValue)

	const changeForm = (field, value) => change(formName, field, value)

	// Initialisation des informations itk après sélection du modèle
	const initializeItkForm = itk => {
		if (!!itk) {
			if (itk.espacement) {
				changeForm(fields.ESPACEMENT, itk.espacement)
			}
			if (plancheSelected) {
				plancheSelected.typePlanche === TYPE_PLANCHE.PERMANENTE ? changeForm(fields.NB_RANGS, itk.nbRang) : changeForm(fields.DENSITE, itk.densite)
			}
		}
	}

	React.useEffect(() => {
		if (editItk) {
			changeForm(fields.ESPACEMENT, assolement.espacement)
			// pour init le champ date on va rechercher dans la list des action le type SD et récup la date de debut dessus
			const assolementMap = Object.values(assolement?.assolementEtapeMap)
			if (assolementMap) {
				let semi_Etape = assolementMap.filter(etape => etape.type === ETAPE_TYPE.SEMI_DIRECT)
				if (semi_Etape.length <= 0) {
					semi_Etape = assolementMap.filter(etape => etape.type === ETAPE_TYPE.SEMI_EN_CONTENANT)
				}
				changeForm(fields.DATE, new Date(semi_Etape[0].dateDebut))
				plancheSelected && plancheSelected.typePlanche === TYPE_PLANCHE.PERMANENTE ? changeForm(fields.NB_RANGS, assolement.nbRangs) : changeForm(fields.DENSITE, assolement.densite)
			}
		}
	}, [])

	React.useEffect(() => {
		if (itks && itks.length === 1) {
			changeForm(fields.ITK, itks[0].id)
			initializeItkForm(itks[0])
		}
		changeForm(fields.CHOIX_DATE, CHOIX_DATES.SEMIS)
	}, [itks])

	return (
		<React.Fragment>
			<div className={classes.form}>
				{!editItk && <Grid>
					<Grid className={classes.titleField}>
						<span className={classes.icon}><ItineraireTechniqueIcon/></span>
						<FormattedMessage id={`${formattedMessageMenuBase}.itineraireTechnique.title`}/>
					</Grid>
				</Grid>}
				{plancheSelected && <Grid>
					{!editItk && <Grid>
						<Grid>
							{itks && itks.length > 0 && <Field
								name={fields.ITK}
								component={Autocomplete}
								label={<FormattedMessage id={`${formattedMessageMenuBase}.itineraireTechnique.labelField`}/>}
								options={sortOptions(getAutocompleteOptions(itks, true), choixDate, dateValue, campagne, itkDetails)}
								defaultValue={itks.length === 1 && { label: itks[0].designation, code: itks[0].id }}
								onChange={(ev, code) => initializeItkForm(itks.find(itk => itk.id === code))}
								renderOption={option => (
									<Grid container direction="column">
										<Grid item>
											{option.label}
										</Grid>
										<Grid container item direction="row" justify="space-between" className={classes.optionDetail}>
											<Grid item>
												<FormattedMessage
													id={`${formattedMessageMenuBase}.itineraireTechnique.options.semis`}
													values={{
														debut: getDebutSemis(null, campagne, option.itk.debutSemis, 0, 'DD/MM'),
														fin: getFinSemis(null, campagne, option.itk, 0, 'DD/MM')
													}}
												/>
											</Grid>
											<Grid item>
												<FormattedMessage
													id={`${formattedMessageMenuBase}.itineraireTechnique.options.recolte`}
													values={{
														debut: getDebutRecolte(campagne, option.itk, itkDetails),
														fin: getFinRecolte(campagne, option.itk, itkDetails)
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
								)
								}
							/>}
							{(!itks || itks.length === 0) &&
								<Grid className={classes.radioText}><FormattedMessage
									id={`${formattedMessageMenuBase}.itineraireTechnique.emptyState`}/></Grid>
							}
							{itkSelected && <Grid className={classes.blocText}>
								<Grid className={classes.ligneText}><FormattedMessage
									id={`${formattedMessageMenuBase}.itineraireTechnique.periodeSemis`}/>
									<span className={classes.important}>{getDebutSemis(assolement, campagne, itkSelected.debutSemis, 0, 'DD/MM/YYYY')}</span>
									<span> au </span>
									<span className={classes.important}>{getFinSemis(assolement, campagne, itkSelected, 0, 'DD/MM/YYYY')}</span>
								</Grid>
								<Grid className={classes.ligneText}><FormattedMessage
									id={`${formattedMessageMenuBase}.itineraireTechnique.nbGraines`}/> <span
									className={classes.important}> {normalizeNumber(itkSelected.nbGraines)} </span></Grid>
							</Grid>}
						</Grid>
					</Grid>}
					<Grid className={classes.blocText2}>
						{((!editItk && itkSelected) || (editItk && assolement)) && <Grid>
							<Grid className={classes.paddingLeft}><Typography variant="body1"><FormattedMessage
								id={`${formattedMessageMenuBase}.dates.prevoir`}/></Typography></Grid>
							<Field
								name={fields.CHOIX_DATE}
								component={Radio}
								label=""
								choices={[{
									value: CHOIX_DATES.SEMIS,
									label: <FormattedMessage id={`${formattedMessageMenuBase}.dates.semis`}/>
								},
									{
										value: CHOIX_DATES.RECOLTE,
										label: <FormattedMessage id={`${formattedMessageMenuBase}.dates.recolte`}/>
									}]}
								row
								style={{ justifyContent: 'space-between' }}
								containerClass={classes.radio}
							/>
						</Grid>}
						{!editItk && itkSelected && <Grid className={classes.paddingBot}>
							<Field
								name={fields.DATE}
								component={DateFieldCustom}
								format={normalizeDate}
								type="text"
								style={choixDate === CHOIX_DATES.SEMIS ? { width: '48%', marginRight: '50%', paddingLeft: '10px' } : { width: '48%', marginLeft: '52%' }}
								periodeDate={[getDebutSemis(assolement, campagne, itkSelected.debutSemis), getFinSemis(assolement, campagne, itkSelected)]}
								showFooterInfo
							/>
						</Grid>}
						{editItk && assolement && <Grid className={classes.paddingBot}>
							<Field
								name={fields.DATE}
								component={DateFieldCustom}
								format={normalizeDate}
								type="text"
								style={choixDate === CHOIX_DATES.SEMIS ? { width: '48%', marginRight: '50%', paddingLeft: '10px' } : { width: '48%', marginLeft: '52%' }}
								periodeDate={[assolement.semisDebut, assolement.semisFin]}
								showFooterInfo
							/>
						</Grid>}
						{(editItk || (itkValue && dateValue)) && <Grid className={classnames(classes.padding, classes.flex)}>
							<Field
								name={fields.ESPACEMENT}
								component={Input}
								adornment={<FormattedMessage id={`${formattedMessageMenuBase}.espacement.unite`}/>}
								label={<FormattedMessage id={`${formattedMessageMenuBase}.espacement.labelField`}/>}
								type="number"
							/>
							{plancheSelected && plancheSelected.typePlanche === TYPE_PLANCHE.PERMANENTE && <Field
								name={fields.NB_RANGS}
								component={Input}
								type="number"
								label={<FormattedMessage id={`${formattedMessageMenuBase}.nbRangs.labelField`}/>}
								format={formatInteger}
							/>}
							{plancheSelected && plancheSelected.typePlanche !== TYPE_PLANCHE.PERMANENTE && <Field
								name={fields.DENSITE}
								component={Input}
								adornment={<FormattedMessage id={`${formattedMessageMenuBase}.densite.unite`}/>}
								label={<FormattedMessage id={`${formattedMessageMenuBase}.densite.labelField`}/>}
								type="number"
							/>}
						</Grid>}
					</Grid>
				</Grid>}
			</div>
		</React.Fragment>
	)
}

ItineraireForm.propTypes = {
	planches: PropTypes.array,
	itks: PropTypes.array,
	itkDetails: PropTypes.array,
	plancheValue: PropTypes.string,
	itkValue: PropTypes.string,
	choixDate: PropTypes.string,
	dateValue: PropTypes.string,
	campagne: PropTypes.object
}

const mapStateToProps = (state, props) => {
	const selector = formValueSelector(props.formName)
	return {
		plancheValue: selector(state, fields.LOCALISATION),
		itkValue: selector(state, fields.ITK),
		choixDate: selector(state, fields.CHOIX_DATE),
		dateValue: selector(state, fields.DATE),
		campagne: getCurrentCampagne(state)
	}
}

const actions = {
	change: change
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(ItineraireForm)
