import { fetchFactory, urlUtils } from 'isotope-client'

export const getAllTacheByIdUser = () => fetchFactory(`/suiviTaches/mobileTachesByUser`)

export const etatTacheDemarrerPauseCours = (idTache) => fetchFactory(
	urlUtils.buildUrlWithParams('/suiviTaches/etatTacheDemarrerPauseCours', {
		idTache: idTache
	}),
	{
		method: 'POST'
	}
)

export const demarrageImpossible = (values, idTache) => fetchFactory(
	urlUtils.buildUrlWithParams('/suiviTaches/demarrageImpossible', {
		idTache: idTache
	}),
	{
		method: 'POST',
		body: JSON.stringify(values)
	}
)

export const finDeTache = (values, idTache) => fetchFactory(
	urlUtils.buildUrlWithParams('/suiviTaches/finDeTache', {
		idTache: idTache
	}),
	{
		method: 'POST',
		body: JSON.stringify(values)
	}
)

export const isConnectionOk = () => fetchFactory('/suiviTaches/isConnectionOk',
	{
		method: 'POST'
	}
)