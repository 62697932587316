import React from 'react'
import {Checkbox, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from '@material-ui/core'
import {ScrollSyncPane} from 'react-scroll-sync'
import {makeStyles, withStyles} from '@material-ui/styles'
import {getStyles} from 'isotope-client'
import {FormattedMessage, useIntl} from 'react-intl'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {getAffectations, refreshAffectationsTable, resetAffectationsOnglet, setAffectationsData, setAffectationsSelectedRows} from '../../services/actions'
import NoResultPage from '../../../../../components/NoResultPage'
import moment from 'moment'
import AffectationIcon from '../common/AffectationIcon'
import TacheStatutIcon from '../common/TacheStatutIcon'
import {useGestionTacheContext} from '../../GestionTacheProvider'
import FormattedTacheLieuMessage from '../common/FormattedTacheLieuMessage'
import {formatDuration} from '../../../../../utils/utils'
import classNames from 'classnames'
import * as selectors from '../../services/selectors'
import PropTypes from 'prop-types'
import {affecterUsers, desaffecterUsers, getInfosTacheForDetailsPanel} from '../../gestionTachesApi'
import {openTacheDetail} from '../../injectors/openTacheDetailsInjector'
import {injectTachePanel} from '../../form/tacheDetailPanel'
import InfoTache from '../../../../common/information/InfoTache'
import {getFilterValues} from '../../../planning/services/toolbar/toolbarSelector'
import {formatFilters} from '../../utils/tacheUtils'
import {useSnackbar} from '../../../../../components/layout/snackbar/SnackbarContext'
import LoaderAdvanced from 'react-loader-advanced'
import CircularProgress from '@material-ui/core/CircularProgress'
import HeaderDiffCell from '../common/HeaderDiffCell'
import Selected from '@material-ui/icons/LibraryAddCheck'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import {ReactComponent as Gauge} from '../../../../../icons/gauge.svg'
import Typography from '@material-ui/core/Typography'

const StyledCell = withStyles(theme => ({
	root: {
		borderRight: '1px solid #c0c0c0',
		textAlign: 'center',
		height: 40,
		padding: '0 5px',
		boxSizing: 'border-box',
	},
	head: {
		height: 50,
		left: 'unset',
		lineHeight: '1.3',
		fontWeight: 'bold',
		backgroundColor: theme.palette.primary.light,
		'& span': {
			cursor: 'pointer'
		}
	}
}))(TableCell)

const pageSizes = [10, 20, 50]

const useStyles = makeStyles(theme => getStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
	},
	tableContainer: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		'& .Loader': {
			width: '100%'
		}
	},
	leftContainer: {
		maxHeight: 'calc(100vh - 280px)',
		flex: '0 0 auto',
		display: 'block',
		scrollbarWidth: 'none',
		msOverflowStyle: 'none',
		'&::-webkit-scrollbar': {
			width: 0
		}
	},
	left: {
		maxWidth: 960,
		zIndex: 10,
		borderLeft: '1px solid #c0c0c0',
		tableLayout: 'fixed'
	},
	rightContainer: {
		maxHeight: 'calc(100vh - 280px)',
		maxWidth: 925,
		display: 'block'
	},
	right: {
		tableLayout: 'fixed',
		width: 'auto'
	},
	tachesSelectionneesDuree: {
		fontWeight: 'bold',
		fontSize: 16
	},
	checkbox: {
		width: 60
	},
	tache: {
		width: 265
	},
	lieu: {
		width: 115
	},
	statut: {
		width: 110
	},
	priorite: {
		width: 90
	},
	other: {
		width: 100
	},
	ressource: {
		width: 100,
		minWidth: 100
	},
	rowSelected: {
		backgroundColor: theme.palette.table.selectedRow
	},
	ressourceWarning: {
		color: theme.palette.error.main
	},
	selectedTaches: {
		width: 317
	},
	capaciteTotale: {
		width: 300
	},
	chargeTotale: {
		width: 312
	},
	cell: {
		backgroundColor: theme.palette.primary.light,
		marginBottom: 5,
		marginInline: 2,
		height: 65,
		fontFamily: 'Lato'
	},
	hourCell: {
		width: 96,
		minWidth: 20
	},
	iconContainer: {
		width: 50,
		padding: 10
	},
	icon: {
		fill: theme.palette.primary.main
	},
	iconBig: {
		width: 36,
		height: 36
	},
	kpiContainer: {
		paddingLeft: 5
	},
	total: {
		fontWeight: 'bold',
		fontSize: 20,
		color: theme.palette.primary.main
	},
	label: {
		fontSize: 13
	},
}))

/**
 * Utilitaire permettant d'annuler des appels serveur
 */
const makeCancelable = (promise) => {
	let hasCanceled_ = false

	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then((val) =>
			hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
		)
		promise.catch((error) =>
			hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
		)
	})

	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true
		}
	}
}

const AffectationTable = (
	{
		getAffectations,
		selected,
		setSelected,
		data,
		setData,
		shouldRefresh,
		reset,
		openTacheDetail,
		filters,
		refreshAffectationsTable,
		selectedAffectation,
		reference,
		loading,
		nbElement
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const filterValues = React.useMemo(() => formatFilters(filters), [filters])
	const { refreshHeaderData } = useGestionTacheContext()
	const { snackSuccess, snackError } = useSnackbar()


	const { jourSelection, headerData } = useGestionTacheContext()
	const { page, size, total, content } = data

	const chargeTachesSelectionnees = React.useMemo(
		() => selected.reduce((acc, tache) => acc.add(moment.duration(tache.chargeTravail)), moment.duration(0)),
		[selected]
	)
	const isError = React.useMemo(() => moment.duration(headerData.capaciteTotale).asMinutes() < moment.duration(headerData.chargeTravailTotale).asMinutes(), [headerData])


	// DEBOUNCE
	const promiseRef = React.useRef()
	const getData = React.useCallback((jour, page, pageSize, formattedFilters = {}) => {
		if (promiseRef.current) {
			promiseRef.current.cancel()
			promiseRef.current = undefined
		}

		const newPromise = makeCancelable(getAffectations(moment(jour).format('YYYY-MM-DD'), page, pageSize, formattedFilters))
		promiseRef.current = newPromise

		newPromise.promise
			.then((data) => {
				setData({
					page: data.number,
					size: data.size,
					total: data.totalElements,
					content: data.content
				})
			})
	}, [getAffectations, setData])

	React.useEffect(() => {
		getData(jourSelection, 0, 50, filterValues)
		return () => {
			if (promiseRef.current) {
				promiseRef.current.cancel()
				promiseRef.current = undefined
			}
		}
	}, [getData, jourSelection, filters])

	const handlePageChange = React.useCallback(newPage => {
		getData(jourSelection, newPage, size)
	}, [getData, size, jourSelection])

	const handleSizeChange = React.useCallback(newSize => {
		getData(jourSelection, page, newSize)
	}, [getData, page, jourSelection])

	const handleSelectAll = React.useCallback(() => {
		if (selected.length < content.length) {
			setSelected(content)
		} else {
			setSelected([])
		}
	}, [content, selected, setSelected])

	const handleSelect = React.useCallback((tache, value) => {
		if (value) {
			setSelected([
				...selected,
				tache
			])
		} else {
			setSelected(selected.filter(selectedTache => selectedTache.id !== tache.id))
		}
	}, [setSelected, selected])

	const isRowSelected = React.useCallback((row) => selected.some(tache => tache.id === row.id), [selected])

	React.useEffect(() => {
		if (shouldRefresh) {
			getData(jourSelection, page, size)
		}
	}, [shouldRefresh, getData, jourSelection, page, size])

	// clean up à l'unmount
	React.useEffect(() => () => reset(), [reset])

	const handleClickHeaderColumn = (idUser) => {
		if (selectedAffectation && selectedAffectation.length > 0) {
			if (selectedAffectation.some(affectation => !affectation.ressources || affectation.ressources.length === 0 || !affectation.ressources.includes(idUser))) {
				affecterUsers({ taches: selectedAffectation.map(selectedAffectation => selectedAffectation.id), users: [idUser] }, moment(jourSelection).format('YYYY-MM-DD'))
					.then(() => {
						refreshAffectationsTable()
						refreshHeaderData(jourSelection)
						snackSuccess({ id: 'gestionTaches.ecranAffectations.snackbar.success.affectation' })
					})
					.catch(() => {
						snackError({ id: 'gestionTaches.ecranAffectations.snackbar.errors.affectation' })
					})
			} else {
				desaffecterUsers({ taches: selectedAffectation.map(selectedAffectation => selectedAffectation.id), users: [idUser] }, moment(jourSelection).format('YYYY-MM-DD'))
					.then(() => {
						refreshAffectationsTable()
						refreshHeaderData(jourSelection)
						snackSuccess({ id: 'gestionTaches.ecranAffectations.snackbar.success.desaffectation' })
					})
					.catch(() => {
						snackError({ id: 'gestionTaches.ecranAffectations.snackbar.errors.desaffectation' })
					})
			}
		}
	}

	const RightTable = React.forwardRef((props, ref) => {
		// Très important ScrollSyncPane casse si la table se render au changement de page
		// Il faut déclarer ScrollSyncPane directement en tant que parent d'une div plutôt que d'un composant personnalisé
		return <LoaderAdvanced
			show={!headerData.ressources}
			message={<CircularProgress/>}
			backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.5)'}}
			contentStyle={{width: '100%'}}
			hideContentOnLoad
		>
			<ScrollSyncPane group={['vertical', 'horizontal']}>
				<div className={classes.rightContainer} ref={ref}>
					<Table stickyHeader className={classes.right}>
						<TableHead>
							<TableRow>
								{(headerData.ressources || []).map((value, key) => <StyledCell
									className={classes.ressource}
									key={key}
								>
									<span onClick={() => handleClickHeaderColumn(value.id)}>{value.alias}
										<Typography style={{fontStyle: 'italic'}} >
											<FormattedMessage id={`enums.roles.` + value?.roles[0]?.code?.toUpperCase()} />
										</Typography>
									</span>
								</StyledCell>)}
							</TableRow>
						</TableHead>
						<TableBody>
							{content.map((row, key) => {
								const isSelected = isRowSelected(row)
								return <TableRow key={key} className={classNames({[classes.rowSelected]: isSelected}, `row-${key}`)}>
									{(headerData.ressources || []).map((ressource, key2) => <StyledCell key={key2}>
										{row.ressources.some(tacheRessource => tacheRessource.toString() === ressource.id.toString()) && <AffectationIcon/>}
									</StyledCell>)}
								</TableRow>
							})}
						</TableBody>
					</Table>
				</div>
			</ScrollSyncPane>
		</LoaderAdvanced>
	})

	if (!content.length && !loading) {
		return <NoResultPage />
	}

	return <div className={classes.root}>
		<LoaderAdvanced
			show={loading}
			message={<CircularProgress />}
			backgroundStyle={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
			hideContentOnLoad
		>
			<>
				<Grid container direction="row" wrap="nowrap">
					<Grid item>
						<Grid container className={classNames(classes.cell, classes.selectedTaches)} alignItems="center">
							<Grid item className={classes.iconContainer}>
								<Selected className={classNames(classes.icon, classes.iconBig)} />
							</Grid>
							<Grid item className={classes.kpiContainer} direction="column">
								<Grid item>
									<span className={classes.tachesSelectionneesDuree}>{chargeTachesSelectionnees.format('h[h]mm', {trim: false})}</span>
								</Grid>
								<FormattedMessage id="gestionTaches.ecranAffectations.header.tachesSelectionnees">
									{text => <span className={classes.label}>{text}</span>}
								</FormattedMessage>
							</Grid>
						</Grid>
					</Grid>

					<Grid item>
						<Grid container className={classNames(classes.cell, classes.capaciteTotale)} alignItems="center">
							<Grid item className={classes.iconContainer}>
								<Gauge className={classNames(classes.icon, classes.iconBig)} />
							</Grid>
							<Grid item className={classes.kpiContainer}>
								<Grid item>
									<span className={classes.total}>{formatDuration(headerData.capaciteTotale)}</span>
								</Grid>
								<FormattedMessage
									id="gestionTaches.ecranAffectations.header.capaciteTotale"
									values={{
										jour: intl.formatDate(jourSelection, { weekday: 'long' })
									}}
								>
									{text => <span className={classes.label}>{text}</span>}
								</FormattedMessage>
							</Grid>
						</Grid>
					</Grid>

					<Grid item>
						<Grid container className={classNames(classes.cell, classes.chargeTotale)} alignItems="center">
							<Grid item className={classes.iconContainer}>
								<TimelapseIcon className={classNames(classes.icon, classes.iconBig)} />
							</Grid>
							<Grid item className={classes.kpiContainer}>
								<Grid item>
									<span className={classNames(classes.total, { [classes.error]: isError })}>{formatDuration(headerData.chargeTravailTotale)}</span>
								</Grid>
								<FormattedMessage
									id="gestionTaches.ecranAffectations.header.chargeTotale"
									values={{
										jour: intl.formatDate(jourSelection, { weekday: 'long' })
									}}
								>
									{text => <span className={classes.label}>{text}</span>}
								</FormattedMessage>
							</Grid>
						</Grid>
					</Grid>

					{(headerData.ressources || []).map((ressource, key) => (
						<Grid item key={`header-affectation-ressource-${key}`}>
							<HeaderDiffCell
								className={classNames(classes.cell, classes.ressource, classes.hourCell)}
								valeur={ressource.differentiel}
							/>
						</Grid>
					))}
				</Grid>

				<Grid className={classes.tableContainer}>
					<Grid>
						<ScrollSyncPane group="vertical">
							<Grid className={classes.leftContainer}>
								<Table stickyHeader className={classes.left}>
									<TableHead>
										<TableRow>
											<StyledCell className={classes.checkbox}>
												<Checkbox
													indeterminate={selected.length > 0 && selected.length < content.length}
													checked={selected.length === content.length}
													onChange={handleSelectAll}
												/>
											</StyledCell>
											<StyledCell className={classes.tache}>
												<FormattedMessage id="gestionTaches.ecranAffectations.table.headers.tache" />
											</StyledCell>
											<StyledCell className={classes.priorite}>
												<FormattedMessage id="gestionTaches.ecranAffectations.table.headers.priorite" />
											</StyledCell>
											<StyledCell className={classes.lieu}>
												<FormattedMessage id="gestionTaches.ecranAffectations.table.headers.lieu" />
											</StyledCell>
											<StyledCell className={classes.statut}>
												<FormattedMessage id="gestionTaches.ecranAffectations.table.headers.statut" />
											</StyledCell>
											<StyledCell className={classes.other}>
												<FormattedMessage id="gestionTaches.ecranAffectations.table.headers.chargeTravail" />
											</StyledCell>
											<StyledCell className={classes.other}>
												<FormattedMessage id="gestionTaches.ecranAffectations.table.headers.chargeParPersonne" />
											</StyledCell>
											<StyledCell className={classes.other}>
												<FormattedMessage id="gestionTaches.ecranAffectations.table.headers.nbPersonnes" />
											</StyledCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{content.map((row, key) => {
											const isSelected = isRowSelected(row)
											return <TableRow key={key} className={classNames({ [classes.rowSelected]: isSelected },`row-${key}`)}>
												<StyledCell>
													<Checkbox
														checked={isSelected}
														onChange={(event, value) => handleSelect(row, value)}
													/>
												</StyledCell>
												<StyledCell
													style={{ cursor: 'pointer' }}
													onClick={() => getInfosTacheForDetailsPanel(row.id)
														.then((res) => openTacheDetail(res))
													}
												>
													<InfoTache tache={row} inTable />
												</StyledCell>
												<StyledCell>{row.priorite}</StyledCell>
												<StyledCell><FormattedTacheLieuMessage typeLieu={row.typeLieu} lieu={row.lieu} /></StyledCell>
												<StyledCell><TacheStatutIcon statut={row.statut} isEnRetard={row.enRetard} /></StyledCell>
												<StyledCell>{formatDuration(row.chargeTravail)}</StyledCell>
												<StyledCell>{formatDuration(row.chargeUnitaire)}</StyledCell>
												<StyledCell className={classNames({
													[classes.ressourceWarning]: row.ressources.length < row.nbPersonnes
												})}>{row.nbPersonnes}</StyledCell>
											</TableRow>
										})}
									</TableBody>
								</Table>
								<Grid container justify={"flex-start"}>
									<TablePagination
										component="div"
										count={total}
										rowsPerPage={size}
										page={page}
										rowsPerPageOptions={pageSizes}
										labelRowsPerPage={intl.formatMessage({ id: 'isotope.datatable.footer.elementsPerPage' })}
										labelDisplayedRows={({ from, to, count }) => (
											intl.formatMessage({ id: 'isotope.datatable.footer.pagination' }, {
												startIndex: from,
												endIndex: to,
												total: count
											})
										)}
										onChangePage={(_, page) => handlePageChange(page)}
										onChangeRowsPerPage={(event) => handleSizeChange(event.target.value)}
									/>
								</Grid>
							</Grid>
						</ScrollSyncPane>
					</Grid>
					{/*Pas de parent ici pour que les ScrollSyncPane restent liés*/}
					<RightTable ref={reference} />
				</Grid>

			</>
		</LoaderAdvanced>
	</div>
}

const mapStateToProps = state => ({
	data: selectors.getAffectationsData(state),
	selected: selectors.getAffectationsSelectedRows(state),
	shouldRefresh: selectors.getAffectationsShouldRefresh(state),
	filters: getFilterValues(state),
	selectedAffectation: selectors.getAffectationsSelectedRows(state),
	loading: selectors.affectationLoading(state),
	nbElement: selectors.getAffectationsData(state).total
})

const actions = {
	getAffectations,
	setData: setAffectationsData,
	setSelected: setAffectationsSelectedRows,
	reset: resetAffectationsOnglet,
	refreshAffectationsTable
}


AffectationTable.propTypes = {
	getAffectations: PropTypes.func,
	getRessources: PropTypes.func,
	openTacheDetail: PropTypes.func
}

export default compose(
	openTacheDetail,
	injectTachePanel,
	connect(mapStateToProps, actions)
)(AffectationTable)
