import React from 'react'
import { getAllTacheByIdUser, isConnectionOk } from '../mobileApi'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import { useMobileContext } from '../MobileProvider'

const Socket = () => {
	const { setTache, setTachesNonTerminees, setTachesTerminees, setInitialScreenSize } = useMobileContext()
	const { snackError } = useSnackbar()

	const checkConnection = () => isConnectionOk()
		.catch(() => snackError({ id: 'mobile.bandeau' }))

	React.useEffect(() => {
		getAllTacheByIdUser()
			.then((res) => {
				setTachesNonTerminees(res.tachesNonTerminees)
				setTachesTerminees(res.tachesTerminees)
				setTache(res.premiereTacheARealiser)
				setInitialScreenSize(window.innerHeight)
				checkConnection()
			})
			.catch(() => snackError({ id: 'mobile.snackbar.error.erreurReseau' }))
	}, [snackError])

	React.useEffect(() => {
		const interval = setInterval(() => {
			checkConnection()
		}, 300000)
		return () => clearInterval(interval)
	}, [snackError, setTache])

	return <></>
}

export default Socket
