import { TACHE_TYPE_ENTITE } from '../../../utils/constants'
import { sortObjectByLabel } from '../../../utils/utils'
import { getAllPlanches, getItkDetail } from '../../fo/planning/services/planningApi'
import { getAllBlocs } from './modeleTacheApi'

export const getTypeEtapeOptions = (itk) => getItkDetail(itk).then(res => res.map(value => ({ label: value.typeEtape, value: value.id })))

export const getSurfacesOptions = (idFerme) => getAllPlanches(idFerme).then(res => res.map(planche => ({ label: planche.nom, code: planche.id }))).then(sortObjectByLabel)

export const getBlocsOptions = (idFerme) => getAllBlocs(idFerme).then(res => res.map(bloc => ({ label: bloc.nom, code: bloc.id }))).then(sortObjectByLabel)

export const displayItk = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.ASSOLEMENT || typeEntity === TACHE_TYPE_ENTITE.ETAPE

export const displayEtape = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.ETAPE

export const displaySurface = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.SURFACE

export const displayBlocs = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.BLOC

export const displayAssolementForMoTache = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.ASSOLEMENT