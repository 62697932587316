import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import BesoinsProductionFilters from '../filters/BesoinsProductionFilters'
import NoResultPage from '../../../../../components/NoResultPage'
import DataTableWithLoader from '../../../../../components/layout/DataTableWithLoader'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { Rating } from '@material-ui/lab'
import {
	createOrUpdateBesoinsProduction,
	exportBesoinsProduction,
	getBesoinsProduction,
	getFermeLongueurPlanche
} from '../../service/besoinsProductionApi'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import { connect } from 'react-redux'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import IconButton from '../../../../../components/IconButton'
import { formatOption, getAutocompleteOptions } from '../../../planning/component/form/NouvelleCulture'
import { getAllCulturesWithItk, getAllItks } from '../../../planning/services/planningApi'
import FormButton from '../../../../../components/form/FormButton'
import { colors, FIN_CAMPAGNE, ONGLETS_BESOINS_PRODUCTION, TYPE_DIALOG } from '../../../../../utils/constants'
import { DialogContext } from '../../../../../components/layout/dialog'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import ClearIcon from '@material-ui/icons/Clear'
import CommentIcon from '@material-ui/icons/Comment'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import PeriodSelector from '../../../listAssolement/component/ToolBar/PeriodSelector'
import moment from 'moment'
import Button from '../../../../../components/Button'
import CommentaireForm, { COMMENTAIRE_FORM } from './commentaire/CommentaireForm'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSnackbar } from '../../../../../components/layout/snackbar/SnackbarContext'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import QuantiteForm, { fields, getQuantiteHebdo, getRendement } from './quantite/QuantiteForm'
import Badge from '@material-ui/core/Badge'
import classnames from 'classnames'
import { change, reset } from 'redux-form'
import { useSolverStatusContext } from '../../SolverStatusContextProvider'
import StyledTabs from '../../../../../components/layout/tab/StyledTabs'
import StyledTab from '../../../../../components/layout/tab/StyledTab'
import { checkExportStateUntilFound } from '../../../../../utils/exportUtils'
import Tuile from '../../../../bo/components/Tuile'
import { setActiveCampagne } from '../../../../common/campagne/campagneAction'
import { LocalDate } from '../../../../../utils/date/local-date';
import { PLANNING_RANGE } from '../../../../common/campagne/campagneReducer';
import { DateFormat, DateUnit } from '../../../../../utils/date/model/date';
import { getNotifications } from '../../../../common/notification/notificationActions';
import { getNbWeeksISOInYear } from '../../../../../utils/utils'


const styles = () => getStyles({
	cultureName: {
		textAlign: 'left',
		paddingLeft: 12
	},
	titrePopin: {
		display: 'flex',
		alignItems: 'center',
		gap: 15,
		letterSpacing: 0,
		width: 550,

		'& svg': {
			color: colors.primary
		}
	},
	table: {
		// 440 px correspond approximativement à la taille de tout les autres éléments
		maxHeight: 'calc(100vh - 400px)',
		overflowY: 'auto',
		marginTop: 20,

		'& .MuiTableCell-root': {
			borderTop: '1px solid #c0c0c073',
			fontSize: 14,
			padding: '10px 2px',

			'& input': {
				fontSize: 14
			}
		}
	},
	bouton: {
		height: 36,
		minWidth: 'auto',
		paddingLeft: 20,
		paddingRight: 20
	},
	smallIcon: {
		'& .MuiSvgIcon-root': {
			width: '0.75em',
			height: '0.75em'
		}
	},
	contenuPopin: {
		padding: '10px 30px'
	},
	contenuPopinSuppression: {
		'& div': {
			marginLeft: 20
		},

		'& p > span': {
			marginLeft: 25,

			'& > span': {
				fontSize: 11,
				color: colors.empty,
				margin: 6
			}
		}
	},
	autocomplete: {
		backgroundColor: '#f7f6f6 !important',
		height: '35.3px',
		paddingTop: '0 !important',
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8
	},
	badge: {
		'& .MuiBadge-badge': {
			height: 10,
			width: 10,
			top: 2,
			right: 2,
			borderRadius: 6
		}
	},
	iconButton: {
		padding: '5px !important'
	},
	tabs: {
		borderBottom: '0.5px solid lightgray',
		marginLeft: '1%',
		maxWidth: '98%'
	},
	replacementText: {
		marginLeft: '1%',
		marginTop: 20
	}
})

const addIndex = (data) => {
	return data.map((item, index) => {
		return {
			...item,
			id: item.id ?? `${Date.now()}${Math.floor(Math.random() * 100)}`,
			index
		}
	})
}

const OngletRecueil = ({
	classes,
	change,
	campagnesActives,
	campagnesCloturees,
	campagne,
	isBesoinNotSaved,
	setIsBesoinNotSaved,
	setActiveCampagne,
	reset,
	getNotifications
}) => {
	const [loading, setLoading] = React.useState(false)
	const [fermeLongueurPlanche, setFermeLongueurPlanche] = React.useState(undefined)
	const [initialData, setInitialData] = React.useState([])
	const [data, setData] = React.useState(initialData)
	const [indexCampagne, setIndexCampagne] = React.useState(undefined)
	const [filter, setFilter] = React.useState(undefined)
	const [cultures, setCultures] = React.useState([])
	const [itks, setItks] = React.useState([])
	const { openDialog, closeDialog } = React.useContext(DialogContext)
	const { snackSuccess, snackError } = useSnackbar()
	const { refreshSolverInfosForCurrentCampagne } = useSolverStatusContext()
	const intl = useIntl()

	const hasCampagnesCloturees = campagnesCloturees && campagnesCloturees.length > 0
	const isOngletCampagnesCloturees = hasCampagnesCloturees && indexCampagne === 0

	React.useEffect(() => {
		if (campagnesActives && campagnesActives.length > 0) {
			setActiveCampagne(campagnesActives[0])
			if (hasCampagnesCloturees) {
				setIndexCampagne(1)
			} else {
				setIndexCampagne(0)
			}
		}
	}, [campagnesCloturees, campagnesActives])

	React.useEffect(() => {
		if (campagnesActives[indexCampagne]?.id) {
			setLoading(true)
			getBesoinsProduction(campagnesActives[indexCampagne]?.id)
				.then(response => {
					let data = []
					if (response && response.length > 0) {
						data = addIndex(response.map(d => ({ ...d, fromApi: true })))
					}
					setData(data)
					setInitialData(data)
				})
				.finally(() => setLoading(false))
			getFermeLongueurPlanche().then(setFermeLongueurPlanche)
			getAllCulturesWithItk(campagnesActives[indexCampagne]?.idFerme).then(setCultures)
			getAllItks(campagnesActives[indexCampagne]?.idFerme).then(setItks)
		}
	}, [campagnesActives, indexCampagne])

	React.useEffect(() => data && initialData && setIsBesoinNotSaved(data !== initialData), [data, initialData])

	React.useEffect(() => {
		if (indexCampagne !== undefined) {
			if (isOngletCampagnesCloturees) {
				setActiveCampagne(undefined)
			} else {
				let index = indexCampagne
				if (hasCampagnesCloturees) {
					// Si on a des campagnes clôturées, on a un onglet en plus au début
					index = index - 1
				}
				setActiveCampagne(campagnesActives[index])
			}
		}
	}, [indexCampagne])

	const changeCampagneCloturee = (campagne) => {
		setActiveCampagne(campagne ? campagne.data : undefined)
	}

	const bornes = React.useMemo(() => ({
		dateDebut: campagne?.dateDebut,
		dateFin: LocalDate.fromAPI(campagne?.dateFin)?.plus(PLANNING_RANGE, DateUnit.month)?.toDisplayDate(DateFormat.SHORT_DATE_WITH_DASH)
	}), [campagne])

	/**
	 * Permet de récupérer les périodes de récoltes en considérant tous les itks de la culture
	 * @param itks les itks de la culture de la ligne
	 * @param row la culture concernée
	 * @returns {*} un tableau avec des objets {debut, fin}
	 */
	const getPeriodesRecolte = (itks, row) => {
		// l'année de la date de fin de la campagne correspond toujours à l'année de la campagne
		const nbIsoWeeksInCampagne = getNbWeeksISOInYear(LocalDate.fromAPI(campagne.dateFin).year)
		const periodesRecoltes = itks.filter(itk => itk.idCulture === row.culture)
			.map(itk => {
					const debutPeriode = itk.debutSemis + itk.details.find(etape => etape.typeEtape === 'R').timeline
					let finPeriode = itk.debutSemis + itk.dureeSemis + itk.details.find(etape => etape.typeEtape === 'FR').timeline
					// la période s'étale sur 2 années
					if (finPeriode > nbIsoWeeksInCampagne) {
						return [{ debut: debutPeriode, fin: nbIsoWeeksInCampagne }, { debut: 1, fin: finPeriode - nbIsoWeeksInCampagne }]
					}
					return { debut: debutPeriode, fin: finPeriode }
				}
			)
		return periodesRecoltes.flat()
	}
	const headers = React.useMemo(() => {
		const headers = [
			{
				key: 'culture',
				name: <FormattedMessage id="besoinsProduction.recueil.table.headers.culture" />,
				tableHeaderColumnProps: {
					width: '22%',
					align: 'center'
				},
				render: row => {
					const culture = cultures.find(c => c.id === row.culture)
					if (campagne.finished || row.fromApi) {
						return culture && <p className={classes.cultureName}>{culture.nom}</p>
					} else {
						return <Autocomplete
							options={getAutocompleteOptions(cultures)}
							value={culture && formatOption(culture)}
							onChange={(event, value) => {
								const culture = value.code
								if (row.nbPlanches) {
									const rendement = getRendement(culture, itks, fermeLongueurPlanche)
									const quantiteKg = Math.round(row.nbPlanches * rendement)
									changeValues(['quantiteKg', 'culture'], row, { quantiteKg, culture })
									change(`quantite-${row.id}`, fields.QUANTITE_KG, quantiteKg)
								} else {
									changeValue('culture', row, culture)
								}
							}}
							getOptionLabel={option => option.label}
							getOptionSelected={(option, value) => !!value && option.code === value.code}
							disableClearable={true}
							classes={{
								inputRoot: classes.autocomplete
							}}
							renderInput={params => <TextField {...params} variant="filled"
															  className={classes.autocomplete} />}
						/>
					}
				}
			},
			{
				key: 'quantite',
				name: <FormattedMessage id="besoinsProduction.recueil.table.headers.quantite" />,
				tableHeaderColumnProps: {
					width: campagne.finished ? '15%' : '38%',
					align: 'center'
				},
				render: row => <QuantiteForm
					formName={`quantite-${row.id}`}
					row={row}
					cultures={cultures}
					itks={itks}
					fermeLongueurPlanche={fermeLongueurPlanche}
					changeValue={changeValue}
					changeValues={changeValues}
					campagne={campagne}
				/>
			},
			{
				key: 'periode',
				name: <FormattedMessage id="besoinsProduction.recueil.table.headers.periode" />,
				tableHeaderColumnProps: {
					align: 'center',
					width: '18%'
				},
				render: row => <PeriodSelector
					onSubmit={values => {
						changeValues(
							['dateDebutRecolte', 'dateFinRecolte'],
							row,
							{
								dateDebutRecolte: moment(values.dateDebut).format(moment.HTML5_FMT.DATE),
								dateFinRecolte: moment(values.dateFin).format(moment.HTML5_FMT.DATE)
							}
						)
					}}
					period={{
						dateDebut: row.dateDebutRecolte ? moment(row.dateDebutRecolte).toISOString() : undefined,
						dateFin: row.dateFinRecolte ? moment(row.dateFinRecolte).toISOString() : undefined
					}}
					initialValues={{
						dateDebut: row.dateDebutRecolte ? moment(row.dateDebutRecolte).toISOString() : undefined,
						dateFin: row.dateFinRecolte ? moment(row.dateFinRecolte).toISOString() : undefined
					}}
					bornes={bornes}
					disabled={campagne.finished}
					formName={`periode-${row.id}`}
					isInput
					fromBesoinsProduction
					periodsItk={getPeriodesRecolte(itks, row)}
				/>
			},
			{
				key: 'importance',
				name: <FormattedMessage id="besoinsProduction.recueil.table.headers.importance" />,
				tableHeaderColumnProps: {
					align: 'center',
					width: '12%'
				},
				render: row => <Rating
					name={`importance-${row.id}`}
					value={row.importance}
					emptyIcon={<StarBorderIcon fontSize="inherit" />}
					disabled={campagne.finished}
					icon={<StarIcon fontSize="inherit" style={{ color: '#757575' }} />}
					onChange={(event, value) => changeValue('importance', row, value)}
				/>
			}
		]

		if (!campagne?.finished) {
			headers.push({
				key: 'action',
				tableHeaderColumnProps: {
					align: 'center',
					width: '10%'
				},
				render: row => <>
					<IconButton onClick={() => openPopinCommentaire(row)} className={classes.iconButton}>
						<Badge color="error" variant="dot" invisible={!row.commentaire} className={classes.badge}>
							<CommentIcon />
						</Badge>
					</IconButton>
					<IconButton onClick={() => copyLigne(row)}
								className={classes.iconButton}><FileCopyIcon /></IconButton>
					<IconButton onClick={() => {
						const culture = cultures.find(c => c.id === row.culture)
						if (culture || row.dateDebutRecolte || row.quantiteKg) {
							openPopinSuppression(row, culture)
						} else {
							deleteLigne(row.index)
						}
					}} className={classes.iconButton}><ClearIcon /></IconButton>
				</>
			})
		}

		return headers
	}, [campagne, data, cultures, itks, fermeLongueurPlanche, bornes])

	const getDisplayData = () => {
		const filteredCultureIds = cultures.filter(culture => !filter || culture.nom.toLowerCase().startsWith(filter.toLowerCase()))
			.map(culture => culture.id)
		return (data ?? []).filter(d => (!d.culture && !filter) || filteredCultureIds.includes(d.culture))
	}

	const copyLigne = (row) => {
		const dataCopy = data.slice()
		const rowCopy = { ...row, id: undefined, fromApi: false }
		dataCopy.splice(row.index, 0, rowCopy)
		setData(addIndex(dataCopy))
	}

	const resetAll = () => {
		setData(initialData)
		data.forEach(d => reset(`quantite-${d.id}`))
	}

	const openPopinSuppression = (row, culture) => openDialog(
		<span className={classes.titrePopin}>
            <DeleteSweepIcon />
            <FormattedMessage id="besoinsProduction.recueil.popin.suppression.title" />
        </span>,
		<span className={classnames(classes.contenuPopin, classes.contenuPopinSuppression)}>
			<p><FormattedMessage id="besoinsProduction.recueil.popin.suppression.message" /></p>
			<div>
				{culture && <p>
					<FormattedMessage id="besoinsProduction.recueil.popin.fields.culture" /> : <span><b>{culture.nom}</b></span>
				</p>}
				{row.quantiteKg && <p><FormattedMessage
					id="besoinsProduction.recueil.popin.fields.quantite" /> : <span><b>{row.quantiteKg}</b> <span><FormattedMessage
					id="besoinsProduction.unites.kg" /></span></span><span><b>{getQuantiteHebdo(row)}</b> <span><FormattedMessage
					id="besoinsProduction.unites.kgParSemaineFull" /></span></span></p>}
				{row.dateDebutRecolte && <p><FormattedMessage
					id="besoinsProduction.recueil.popin.fields.periode" /> : <span><span><FormattedMessage
					id="besoinsProduction.date.debut" /></span> <b><FormattedDate
					value={row.dateDebutRecolte} /></b> <span><FormattedMessage id="besoinsProduction.date.fin" /></span> <b><FormattedDate
					value={row.dateFinRecolte} /></b></span></p>}
			</div>
		</span>,
		[
			<Button variant="text" withBorder={false} onClick={() => deleteLigne(row.index)}>
				<FormattedMessage id="actions.confirm" />
			</Button>
		], TYPE_DIALOG.ACTION, 'actions.close'
	)

	const deleteLigne = (index) => {
		closeDialog()
		const dataCopy = data.slice()
		dataCopy.splice(index, 1)
		setData(addIndex(dataCopy))
	}

	const changeValue = (field, row, value) => {
		const dataCopy = data.slice()
		dataCopy.splice(row.index, 1, { ...row, [field]: value })
		setData(dataCopy)
	}

	const hasChampNonRempli = () => {
		return data.some(d => !d.culture || !d.dateDebutRecolte || !d.dateFinRecolte || !d.importance || !d.nbPlanches || !d.quantiteKg)
	}

	const handleSubmit = () => {
		createOrUpdateBesoinsProduction({
			idFerme: campagne.idFerme,
			idClient: campagne.idClient,
			idCampagne: campagne.id,
			donneesProduction: data.map(d => ({
				...d,
				id: d.fromApi ? d.id : undefined
			}))
		}).then(result => {
			snackSuccess({ id: 'besoinsProduction.recueil.actions.valider.succes' })
			const data = addIndex(result.map(d => ({ ...d, fromApi: true })))
			setInitialData(data)
			setData(data)
			getNotifications()
			refreshSolverInfosForCurrentCampagne()
		})
	}

	const changeValues = (fields, row, value) => {
		const dataCopy = data.slice()
		let newValue = { ...row }
		fields.forEach(field => newValue = { ...newValue, [field]: value[field] })
		dataCopy.splice(row.index, 1, newValue)
		setData(dataCopy)
	}

	const exportToGsheet = () => {
		exportBesoinsProduction(campagne.id)
			.then(checkExportStateUntilFound(snackSuccess))
			.catch(() => snackError({ id: 'snackbar.error.exportProduction' }))
	}

	const openPopinCommentaire = (row) => openDialog(
		<span className={classes.titrePopin}>
            <CommentIcon />
            <FormattedMessage id="besoinsProduction.recueil.popin.commentaire.title" />
        </span>,
		<CommentaireForm
			onSubmit={values => {
				changeValue('commentaire', row, values.commentaire)
				closeDialog()
			}}
			row={row}
			cultures={cultures}
			initialValues={{ commentaire: row.commentaire }}
		/>,
		[
			<FormButton variant="text" withBorder={false} formName={COMMENTAIRE_FORM}>
				<FormattedMessage id="actions.save" />
			</FormButton>
		], TYPE_DIALOG.ACTION, 'actions.close'
	)

	const formatTuileCampagne = (campagne) => ({
		uniqueId: campagne.id,
		icon: EventBusyIcon,
		title: campagne.nom,
		description: FIN_CAMPAGNE.CLOTUREE.label,
		data: campagne
	})

	return (
		<Grid container direction="row">
			<Grid item direction="row" justify="flex-start" className={classes.tabs}>
				<StyledTabs value={indexCampagne} onChange={(_, index) => setIndexCampagne(index)} variant="scrollable">
					{hasCampagnesCloturees && <StyledTab key={0} withoutBorder
														 label={intl.formatMessage({ id: 'besoinsProduction.recueil.campagnesCloturees.title' })} />}
					{campagnesActives.map(c => <StyledTab key={c.id} withoutBorder label={c.nom} />)}
				</StyledTabs>
			</Grid>
			<BesoinsProductionFilters
				onSubmit={values => setFilter(values.commencePar)}
				onglet={ONGLETS_BESOINS_PRODUCTION.RECUEIL}
				replacementText={isOngletCampagnesCloturees && !campagne.id &&
					<Typography className={classes.replacementText}><i><FormattedMessage
						id="besoinsProduction.recueil.campagnesCloturees.message" /></i></Typography>}
				campagne={campagne}
				changeCampagneCloturee={changeCampagneCloturee}
				addLignes={(nbLigne) => {
					const dataCopy = data.slice()
					for (let i = 0; i < nbLigne; i++) {
						dataCopy.unshift({
							culture: null,
							importance: 0,
							dateDebutRecolte: null,
							dateFinRecolte: null,
							quantiteKg: 0,
							nbPlanches: 0,
							commentaire: null
						})
					}
					setData(addIndex(dataCopy))
				}}
			>
				{isOngletCampagnesCloturees && !campagne.id ?
					<Grid container item justify="center" style={{ paddingTop: 20 }}>
						<Grid container item xs={3}>
							{campagnesCloturees.map(campagne => <Tuile key={campagne.id}
																	   handleSelection={changeCampagneCloturee}
																	   tuile={formatTuileCampagne(campagne)}
																	   selection={campagne} />)}
						</Grid>
					</Grid> : <Grid container direction="column">
						{
							!campagne?.finished && <Grid container item direction="row" justify="flex-end"
														 style={{ paddingLeft: '1%', paddingRight: '1%' }}>
								<Grid item xs={4}>
									<Button
										type="secondary"
										startIcon={<CloudUploadIcon />}
										onClick={exportToGsheet}
										disabled={isBesoinNotSaved}
										className={classes.bouton}
									>
										<FormattedMessage id="besoinsProduction.recueil.actions.export" />
									</Button>
								</Grid>
								<Grid container item xs={8} justify="flex-end" alignItems="center" style={{ gap: 30 }}>
									{isBesoinNotSaved && <Typography style={{ color: colors.error }}><FormattedMessage
										id="besoinsProduction.recueil.notSaved" /></Typography>}
									<Button
										type="secondary"
										startIcon={<CancelIcon />}
										className={classnames(classes.bouton, classes.smallIcon)}
										onClick={resetAll}
									>
										<FormattedMessage id="besoinsProduction.recueil.actions.annuler" />
									</Button>
									<Button
										type="primary"
										startIcon={<SaveIcon />}
										className={classes.bouton}
										onClick={() => {
											if (hasChampNonRempli()) {
												snackError({ id: 'besoinsProduction.recueil.actions.valider.erreur' })
											} else {
												handleSubmit()
											}
										}}
									>
										<FormattedMessage id="besoinsProduction.recueil.actions.valider.bouton" />
									</Button>
								</Grid>
							</Grid>
						}
						<Grid container item direction="row" className={classes.table}>
							<DataTableWithLoader
								nom="RecueilBesoinsProductionTable"
								data={getDisplayData()}
								loading={loading}
								noResultFragment={<NoResultPage
									emptyStateMessageId={`besoinsProduction.recueil.empty.campagne${isOngletCampagnesCloturees ? 'Cloturee' : 'Active'}`} />}
								noPagination
								tableProps={{
									stickyHeader: true
								}}
								headers={headers}
							/>
						</Grid>
					</Grid>}
			</BesoinsProductionFilters>
		</Grid>
	)
}

const actions = {
	change,
	reset,
	setActiveCampagne,
	getNotifications
}

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(OngletRecueil)