import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import { MenuItem } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import Select from '../../../../components/form/Select'
import { OUI_NON } from '../../../fo/mobile/utils/constantsSuiviTache'
import Input from '../../../../components/form/Input'
import { TYPE_PERIODE_RECURRENCE } from '../../../../utils/constants'
import { JOURS_CAPS } from '../../../fo/gestionTache/utils/constants'
import { MODELE_TACHE_EDITION_FORM } from '../ModeleTachePage'
import AutocompleteMulti from '../../../../components/form/AutocompleteMulti'
import { displayAssolementForMoTache, displayBlocs, displayEtape, displaySurface, getBlocsOptions, getSurfacesOptions } from '../moTacheUtils'
import { FIELDS, formattedMessageBase } from '../PopinModeleTache'

const RecurrenceSubPanel = ({
	flagRecurrence,
	flagCreationCampagne,
	periodeType,
	typeEntity,
	classes,
	idFerme
}) => {
	return (
		<ExpansionPanel className={classes.expandPanel}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				className={classes.header}
			>
				<FormattedMessage id={`${formattedMessageBase}.recurrence`} />
			</ExpansionPanelSummary>
			<Grid container item justify={'flex-start'}>
				<Field
					name={FIELDS.FLAG_RECURRENCE.name}
					component={Select}
					containerClassName={classes.littleRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_RECURRENCE.name}`} />}
					fromBo
				>
					{Object.entries(OUI_NON).map(([key, ouiNon]) =>
						<MenuItem key={key} value={ouiNon.value}>
							{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`} />}
						</MenuItem>
					)}
				</Field>
			</Grid>
			{
				flagRecurrence === OUI_NON.OUI.value
					? <Grid container item justify={'flex-start'}>
						<Field
							name={FIELDS.DUREE_RECURRENCE.name}
							component={Input}
							containerClassName={classes.littleRow}
							type="number"
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.DUREE_RECURRENCE.name}`} />}
							fromBo
						/>
						<Field
							name={FIELDS.PERIODE_NB.name}
							component={Input}
							containerClassName={classes.littleRow}
							type="number"
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.PERIODE_NB.name}`} />}
							fromBo
						/>
						<Field
							name={FIELDS.PERIODE_TYPE.name}
							component={Select}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.PERIODE_TYPE.name}`} />}
							fromBo
						>
							{Object.entries(TYPE_PERIODE_RECURRENCE).map(([key, typePeriode]) => <MenuItem key={key} value={typePeriode.value}>
								{<FormattedMessage id={`${formattedMessageBase}.${typePeriode.value}`} />}
							</MenuItem>)}
						</Field>
					</Grid>
					: <React.Fragment />
			}
			{
				flagRecurrence === OUI_NON.OUI.value && periodeType === TYPE_PERIODE_RECURRENCE.SEMAINES.value
					? <Grid container item justify={'flex-start'}>
						<Field
							name={FIELDS.JOURS.name}
							component={Select}
							containerClassName={classes.littleRow}
							multiple
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.JOURS.name}`} />}
							fromBo
						>
							{
								Object.values(JOURS_CAPS).map((jour, index) => (
									<MenuItem key={index} value={jour} classes={{ selected: classes.menuItem }}>
										{<FormattedMessage id={`enums.jours.${jour}`} />}
									</MenuItem>
								))
							}
						</Field>
					</Grid>
					: <React.Fragment />
			}
			{
				typeEntity && !displayAssolementForMoTache(typeEntity) && !displayEtape(typeEntity)
					? <div>
						<Grid container item className={classes.insideHeader}>
							<FormattedMessage id={`${formattedMessageBase}.creationAutomatique`} />
						</Grid>
						<Grid container justify={'flex-start'}>
							<Field
								name={FIELDS.FLAG_CREATION_CAMPAGNE.name}
								component={Select}
								containerClassName={classes.littleRow}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_CREATION_CAMPAGNE.name}`} />}
								fromBo
							>
								{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
									{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`} />}
								</MenuItem>)}
							</Field>
							{
								flagCreationCampagne === OUI_NON.OUI.value
									? <Field
										name={FIELDS.CREATION_SEMAINE_N.name}
										component={Input}
										containerClassName={classes.mediumRow}
										type="number"
										label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.CREATION_SEMAINE_N.name}`} />}
										formName={MODELE_TACHE_EDITION_FORM}
										fromBo
									/>
									: <React.Fragment />
							}
							{
								flagCreationCampagne === OUI_NON.OUI.value && displaySurface(typeEntity)
									? <Field
										name={FIELDS.SURFACES_CREATION_CAMPAGNE.name}
										component={AutocompleteMulti}
										containerClassName={classes.mediumRow}
										label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.SURFACES_CREATION_CAMPAGNE.name}`} />}
										getOptions={() => getSurfacesOptions(idFerme)}
										formName={MODELE_TACHE_EDITION_FORM}
										fromBo
									/>
									: <React.Fragment />
							}
							{
								flagCreationCampagne === OUI_NON.OUI.value && displayBlocs(typeEntity)
									? <Field
										name={FIELDS.BLOCS_CREATION_CAMPAGNE.name}
										component={AutocompleteMulti}
										containerClassName={classes.mediumRow}
										label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.BLOCS_CREATION_CAMPAGNE.name}`} />}
										getOptions={() => getBlocsOptions(idFerme)}
										forceGetOptions
										formName={MODELE_TACHE_EDITION_FORM}
										fromBo
									/>
									: <React.Fragment />
							}
						</Grid>
					</div>
					: <React.Fragment />
			}
		</ExpansionPanel>
	)
}

RecurrenceSubPanel.propType = {
	flagRecurrence: PropTypes.bool,
	flagCreationCampagne: PropTypes.bool,
	periodeType: PropTypes.string,
	typeEntity: PropTypes.string,
	classes: PropTypes.object,
	idFerme: PropTypes.number
}

export default RecurrenceSubPanel