import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import { FIELDS, formattedMessageBase } from '../PopinModeleTache'
import { OUI_NON } from '../../../fo/mobile/utils/constantsSuiviTache'
import Select from '../../../../components/form/Select'
import Input from '../../../../components/form/Input'
import { displayEtape, displaySurface } from '../moTacheUtils'

const InfosPratiquesSubPanel = ({
	typeEntity,
	classes,
	flagAnticipable
}) => {
	return (
		<ExpansionPanel className={classes.expandPanel}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				className={classes.header}
			>
				<FormattedMessage id={`${formattedMessageBase}.informationsPratiques`} />
			</ExpansionPanelSummary>
			<Grid container item justify={'flex-start'}>
				<Field
					name={FIELDS.DESCRIPTION.name}
					component={Input}
					containerClassName={classes.bigRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.DESCRIPTION.name}`} />}
					fromBo
				/>
			</Grid>
			<Grid container item justify={'flex-start'}>
				<Field
					name={FIELDS.URL_DOCU_INSTRUCTIONS.name}
					component={Input}
					containerClassName={classes.bigRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.URL_DOCU_INSTRUCTIONS.name}`} />}
					fromBo
				/>
				<Grid container item justify={'flex-start'}>
					<Field
						name={FIELDS.NB_PERSONNES.name}
						component={Input}
						containerClassName={classes.littleRow}
						type="number"
						label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.NB_PERSONNES.name}`} />}
						fromBo
					/>
					{
						(displaySurface(typeEntity) || displayEtape(typeEntity))
							? <Field
								name={FIELDS.TEMPS_MOY_M2.name}
								component={Input}
								containerClassName={classes.littleRow}
								type="number"
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TEMPS_MOY_M2.name}`} />}
								fromBo
							/>
							: <Field
								name={FIELDS.TEMPS_MOY_FIXE.name}
								component={Input}
								containerClassName={classes.littleRow}
								type="number"
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TEMPS_MOY_FIXE.name}`} />}
								fromBo
							/>
					}
					<Field
						name={FIELDS.FLAG_DIVISIBLE.name}
						component={Select}
						containerClassName={classes.littleRow}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_DIVISIBLE.name}`} />}
						fromBo
					>
						{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
							{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`} />}
						</MenuItem>)}
					</Field>
				</Grid>
			</Grid>

			<Grid container item justify={'flex-start'}>
				<Field
					name={FIELDS.FLAG_ANTICIPABLE.name}
					component={Select}
					containerClassName={classes.littleRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_ANTICIPABLE.name}`} />}
					fromBo
				>
					{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
						{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`} />}
					</MenuItem>)}
				</Field>
				{flagAnticipable === OUI_NON.OUI.value && (
					<Field
						name={FIELDS.JOURS_ANTICIPABLE.name}
						component={Input}
						containerClassName={classes.littleRow}
						type="number"
						adornment={<FormattedMessage id={`${formattedMessageBase}.JOURS`} />}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.JOURS_ANTICIPABLE.name}`} />}
						fromBo
					/>)
				}
			</Grid>
		</ExpansionPanel>
	)
}

InfosPratiquesSubPanel.propType = {
	typeEntity: PropTypes.string,
	classes: PropTypes.object,
	flagAnticipable: PropTypes.bool
}

export default InfosPratiquesSubPanel
