import React, { useEffect, useState } from 'react'
import { Button, Snackbar } from '@material-ui/core'
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration'
import { getOS, isOnBrower } from '../../../utils/platformUtils'

let showInstallOnce = false
const ServiceWorkerWrapper = ({ children }) => {
	const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)
	const [showInstall, setShowInstall] = useState(false)
	const [deferredPrompt, setDeferredPrompt] = useState(undefined)
	const [workerRegistration, setWorkerRegistration] = React.useState(null)

	const onUpdate = (registration) => {
		setIsUpdateAvailable(true)
		setWorkerRegistration(registration)
	}


	useEffect(() => {
		/** Installation du service worker **/
		serviceWorkerRegistration.register({
			onSuccess: () => {
			},
			onUpdate: onUpdate
		})
	}, [])

	useEffect(() => {
		/** Gestion de la bannière d'installation **/
		const handleBeforeInstallPrompt = (e) => {
			if (showInstallOnce) return
			e.preventDefault()
			setDeferredPrompt(e)
			setShowInstall(true)
		}

		// ne fonctionne que sur Chrome & Windows
		if (getOS() === 'Android' && isOnBrower()) {
			/** Affichage de la demande d'installation **/
			window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
		}

		return () => {
			window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
		}
	}, [])

	/**
	 * Reload/Update l'app pour installer le nouveau service worker
	 */
	const updateServiceWorker = () => {
		const registrationWaiting = workerRegistration && workerRegistration.waiting
		if (registrationWaiting) {
			registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
			registrationWaiting.addEventListener('statechange', e => {
				if (e && e.target && e.target.state === 'activated') {
					window.location.reload()
					setIsUpdateAvailable(false)
				}
			})
		}
	}

	/**
	 * Affiche la popup d'installation de l'app
	 */
	const installAppOnHome = () => {
		// Hide the app provided install promotion
		setShowInstall(false)
		showInstallOnce = true
		// Show the install prompt
		if (deferredPrompt !== undefined) {
			deferredPrompt.prompt()
			setDeferredPrompt(undefined)
		}
	}

	return (
		<>
			<Snackbar
				open={isUpdateAvailable}
				message="Une nouvelle version est disponible"
				onClick={updateServiceWorker}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				action={
					<Button
						color="inherit"
						size="small"
						onClick={updateServiceWorker}
					>
						Actualiser
					</Button>
				}
			/>
			<Snackbar
				open={showInstall}
				message="Ajouter l'application Permasoft sur l'écran d'accueil"
				onClick={installAppOnHome}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				action={
					<Button
						color="inherit"
						size="small"
						onClick={installAppOnHome}
					>
						Ajouter
					</Button>
				}
			/>
			{children}
		</>
	)
}

export default ServiceWorkerWrapper
