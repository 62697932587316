import {Grid, Typography, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {change, Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {BIO_AGGRESSOR_ADD_FIELDS, BIO_AGGRESSOR_ADD_FORM_NAME} from '../../tool/suiviParBloc.constants'
import {normalizeDate} from '../../../../../../../utils/utils'
import moment from 'moment'
import {DateFormat} from '../../../../../../../utils/dateConstants'
import BioAggressorsInput from './BioAggressorsInput'
import DateFieldCustom from '../../../../../../../components/form/DateFieldCustom'

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		padding: '30px 40px',
		rowGap: 25,
		maxHeight: 'calc(100vh - 220px)'
	},
	rowContainer: {
		columnGap: 25
	},
	label: {
		fontSize: 16,
		lineHeight: '20px',
		fontWeight: '400',
		color: '#000'
	},
	boardsText: {
		fontSize: 18,
		lineHeight: '22px',
		fontWeight: '600',
		color: '#000'
	},
	dateLabel: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	},
	formBody: {
		maxHeight: 'calc(100vh - 365px)',
		overflowY: 'auto'
	}
})

/**
 * Validate
 * @param values
 * @returns {{}}
 */
const validate = (values) => {
	const errors = {}

	if (!values[BIO_AGGRESSOR_ADD_FIELDS.START]) {
		errors[BIO_AGGRESSOR_ADD_FIELDS.START] = { id: 'global.errors.mandatory' }
	}
	if (!values[BIO_AGGRESSOR_ADD_FIELDS.END]) {
		errors[BIO_AGGRESSOR_ADD_FIELDS.END] = { id: 'global.errors.mandatory' }
	}
	if (!values[BIO_AGGRESSOR_ADD_FIELDS.BIO_AGGRESSORS].length) {
		errors[BIO_AGGRESSOR_ADD_FIELDS.BIO_AGGRESSORS] = { id: 'global.errors.mandatory' }
	}
	return errors
}

/**
 * BioAggressorsAddForm
 * @param handleSubmit
 * @param change
 * @param classes
 * @param bioAggressors
 * @param boards
 * @returns {JSX.Element}
 * @constructor
 */
const BioAggressorsAddForm = ({ handleSubmit, change, classes, bioAggressors, boards }) => {

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.form}>
				<Grid item container alignItems="center" justify="flex-start" className={classes.rowContainer}>
					<Typography className={classes.label}>
						<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.boards" />
					</Typography>
					<Typography className={classes.boardsText}>
						{boards.map(board => board.label).join(', ')}
					</Typography>
				</Grid>
				<Grid item container alignItems="center" justify="space-between">
					<Typography className={classes.dateLabel}>
						<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.date.start" />
					</Typography>
					<Field
						name={BIO_AGGRESSOR_ADD_FIELDS.START}
						type="text"
						component={DateFieldCustom}
						format={normalizeDate}
						style={{ width: '40%'}}
						onChange={(event, value) => {
							change(BIO_AGGRESSOR_ADD_FIELDS.END, moment(value, DateFormat.YYYY_MM_DD).add(1, 'years').format(DateFormat.YYYY_MM_DD))
						}}
					/>
					<Typography className={classes.dateLabel}>
						<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.date.end" />
					</Typography>
					<Field
						name={BIO_AGGRESSOR_ADD_FIELDS.END}
						type="text"
						component={DateFieldCustom}
						format={normalizeDate}
						style={{ width: '40%'}}
					/>
				</Grid>
				<Grid item container  alignItems="center" justify="flex-start" className={classes.formBody}>
					<Field
						name={BIO_AGGRESSOR_ADD_FIELDS.BIO_AGGRESSORS}
						component={BioAggressorsInput}
						bioAggressors={bioAggressors}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

BioAggressorsAddForm.propType = {
	boards: PropTypes.array,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	change: PropTypes.func
}

const mapStateToProps = (state, { boards, ...props }) => {
	return ({
		boards,
		initialValues: {
			[BIO_AGGRESSOR_ADD_FIELDS.BOARDS]: boards.map(board => board.id),
			[BIO_AGGRESSOR_ADD_FIELDS.BIO_AGGRESSORS]: []
		},
		...props
	})
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: BIO_AGGRESSOR_ADD_FORM_NAME,
		destroyOnUnmount: true,
		enableReinitialize: true,
		validate
	}),
	withStyles(styles)
)
(BioAggressorsAddForm)
