import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import { getStyles } from 'isotope-client'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { formValueSelector, isInvalid } from 'redux-form'
import Button from '../../../../../components/Button'
import FormButton from '../../../../../components/form/FormButton'
import CultureOutlinedIcon from '../../../../../components/icon/CultureOutlinedIcon'
import FilterIcon from '../../../../../components/icon/FilterIcon'
import QuestionIcon from '../../../../../components/icon/QuestionIcon'
import { DialogContext } from '../../../../../components/layout/dialog'
import { DetailsPanelContext } from '../../../../../components/layout/rightPanels'
import AccessChecker from '../../../../../components/security/AccessChecker'
import { ASSOLEMENT_ETAT, CHOIX_DATES, ETAPE_TYPE, PROFILS, TYPE_DIALOG } from '../../../../../utils/constants'
import { compareDateSemisWithItk, getAccessCheckerRole } from '../../../../../utils/utils'
import { openCultureDetail } from '../../services/actions/detail/openCultureDetailInjector'
import { injectAssolementFilter } from '../../services/actions/filterAssolementInjector'
import { injectValidatePlanning } from '../../services/actions/validerPlanningInjector'
import { creerAssolement, setCompare } from '../../services/assolement/assolementAction'
import { loadAssolement } from '../../services/assolement/assolementInjector'
import { isCompare } from '../../services/assolement/assolementSelector'
import { getItk, getItkDetail } from '../../services/planningApi'
import { setFilterOpen } from '../../services/toolbar/toolbarAction'
import { getFilterValues } from '../../services/toolbar/toolbarSelector'
import { ACTION_TYPE } from '../../utils/constant'
import { hasCampagneChanged } from '../../utils/utils'
import CampagneSelect from './CampagneSelect'
import Export from './Export'
import NouvelleCulture, { NEW_CULTURE_FORM_NAME } from '../form/NouvelleCulture'
import Legende from './Legende'
import PlanningSizeSelector from './PlanningSizeSelector'
import Sort from './Sort'
import ToolBarButton from './ToolBarButton'
import MenuActions from '../../../../../components/MenuActions'
import NouveauGroupeDeCulture, { NEW_CULTURE_GROUP_FORM_NAME } from '../form/NouveauGroupeDeCulture'
import { useSnackbar } from '../../../../../components/layout/snackbar/SnackbarContext'
import { useSolverStatusContext } from '../../../besoinsProduction/SolverStatusContextProvider'
import { getNotifications } from '../../../../common/notification/notificationActions'

const styles = () => getStyles({
	icon: {
		marginLeft: 12,
		marginRight: 12
	},
	toolbar: {
		paddingLeft: 24,
		flexGrow: 1
	},
	campagne: {
		display: 'flex',
		alignItems: 'center'
	},
	suffix: {
		fontStyle: 'italic',
		fontSize: '0.6em'
	}
})

const itemName = {
	ADD_CULTURE_NAME: 'addCulture',
	ADD_CULTURE_GROUP_NAME: 'addCultureGroup'
}

const itemAction = {
	ADD_CULTURE_ACTION: 'addCultureAction',
	ADD_CULTURE_GROUP_ACTION: 'addCultureGroupAction'
}

const items = [{
	name: itemName.ADD_CULTURE_NAME,
	action: itemAction.ADD_CULTURE_ACTION
}, {
	name: itemName.ADD_CULTURE_GROUP_NAME,
	action: itemAction.ADD_CULTURE_GROUP_ACTION
}]

const ToolBar = ({
	campagne,
	creerAssolement,
	accessAssolement,
	openCultureDetail,
	openValidatePlanning,
	filterValues,
	isCompare,
	setCompare,
	setFilterOpen,
	classes,
	getNotifications
}) => {
	const { snackError, snackSuccess, snackWarning } = useSnackbar()
	const { closePanel } = React.useContext(DetailsPanelContext)
	const { openDialog } = React.useContext(DialogContext)
	const { refreshSolverInfosForCurrentCampagne } = useSolverStatusContext()
	const isRevise = campagne.etat === ASSOLEMENT_ETAT.REVISE

	const checkDatesItk = (itk, campagne, choixDate, date) => {
		getItkDetail(itk.id)
			.then((itkDetails) => {
				const typeEtape = itkDetails.find(detail => detail.typeEtape === ETAPE_TYPE.IMPLANTATION) ? ETAPE_TYPE.IMPLANTATION : ETAPE_TYPE.SEMI_DIRECT
				const etapeRecolte = itkDetails.find(detail => detail.typeEtape === ETAPE_TYPE.RECOLTE)
				const etapeSemis = itkDetails.find(detail => detail.typeEtape === typeEtape)
				let dateDebut
				if (choixDate === CHOIX_DATES.SEMIS) {
					dateDebut = date
				} else {
					dateDebut = moment(date).subtract(etapeRecolte.timeline - etapeSemis.timeline, 'week').format('YYYY-MM-DD')
				}
				const dateFin = moment(dateDebut).add(etapeSemis.duree, 'week').format('YYYY-MM-DD')
				const result = compareDateSemisWithItk(itk, campagne, dateDebut, dateFin)
				if (result) {
					snackWarning({ id: 'snackbar.warning.dateSemis' })
				} else {
					snackSuccess({ id: 'snackbar.update.ajoutCulture' })
				}
			})
	}

	const submitCreerAssolement = values =>
		getItk(values.itk)
			.then((itk) => {
				creerAssolement(campagne.id, { ...values, isRevise }, filterValues, false)
					.then(({ assolement }) => {
						if (!hasCampagneChanged(campagne, assolement, campagne, snackWarning, ACTION_TYPE.CREATE)) {
							checkDatesItk(itk, campagne, values.choixDate, values.date)
						}
						accessAssolement(assolement.id)
						closePanel()
						openCultureDetail(assolement)
						getNotifications()
						refreshSolverInfosForCurrentCampagne()
					})
					.catch(() => snackError({ id: 'snackbar.error.ajoutCulture' }))
			})

	const submitCreerGroupeDeCulture = values => {
		return creerAssolement(campagne.id, { ...values, isRevise }, filterValues, true)
			.then(({ assolement }) => {
				accessAssolement(assolement.id)
				closePanel()
				openCultureDetail(assolement)
				getNotifications()
				refreshSolverInfosForCurrentCampagne()
				snackSuccess({ id: 'snackbar.update.ajoutGroupeCulture' })
			})
			.catch(() => snackError({ id: 'snackbar.error.ajoutGroupeCulture' }))
	}

	return (
		<DetailsPanelContext.Consumer>
			{({ openPanel, updatePanel }) =>
				<Grid container direction="row" justify="space-between" className="c-toolbar">
					<Grid
						item
						container
						xs
						direction="row"
						justify="flex-start"
						alignItems="center"
						className={classes.toolbar}
					>
						<Box p={1}>
							<Typography variant="h2" className={classes.campagne}>
								<CampagneSelect />
								{!campagne.preview ? <span className={classes.suffix}><FormattedMessage id={`planning.titleSuffix${campagne.finished ? 'Cloture' : isRevise ? 'Revise' : 'Initial'}`} /></span> : ''}
							</Typography>
						</Box>
						{!campagne.preview ?
							<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
								<Box p={1}>
									{!isRevise && <Button
										type="secondary"
										startIcon={<PlaylistAddCheckIcon />}
										onClick={() => openValidatePlanning(campagne.id)}
									>
										<FormattedMessage id="planning.buttons.validate" />
									</Button>}
									{/* fixme : cf commentaire de la PRM296 */}
									{/*{isRevise && !isCompare &&*/}
									{/*<Button*/}
									{/*	type="secondary"*/}
									{/*	startIcon={<ComparaisonIcon color={colors.primary} />}*/}
									{/*	onClick={() => setCompare(!isCompare)}*/}
									{/*>*/}
									{/*	<FormattedMessage id="planning.buttons.compare" />*/}
									{/*</Button>*/}
									{/*}*/}
									{/*{isRevise && isCompare &&*/}
									{/*<Button*/}
									{/*	type="secondary"*/}
									{/*	startIcon={<ComparaisonIcon color={colors.primary} />}*/}
									{/*	onClick={() => setCompare(!isCompare)}*/}
									{/*>*/}
									{/*	<FormattedMessage id="planning.buttons.masquer" />*/}
									{/*</Button>*/}
									{/*}*/}
								</Box>
							</AccessChecker> : ''}
					</Grid>
					<Grid
						item
						container
						xs
						direction="row"
						justify="flex-end"
						alignItems="center">
						{!isCompare && !campagne.finished && !campagne.preview && <AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
							<Box p={1}>
								<MenuActions
									items={{ 'buttons.new.items': items }}
									onClick={(event, category, item) => {
										switch (item.action) {
											case itemAction.ADD_CULTURE_ACTION:
												openPanel()
												updatePanel({ id: 'planning.cultureForm.new' }, <NouvelleCulture
													onSubmit={submitCreerAssolement} />, [
													<FormButton
														type="primary"
														formName={NEW_CULTURE_FORM_NAME}
														getDisabledFromState={state => !formValueSelector(NEW_CULTURE_FORM_NAME)(state, 'itk')
															|| (!formValueSelector(NEW_CULTURE_FORM_NAME)(state, 'nbRangs') && !formValueSelector(NEW_CULTURE_FORM_NAME)(state, 'densite'))
														}
													>
														<FormattedMessage id="planning.cultureForm.bouton.add" />
													</FormButton>
												], true)
												break
											case itemAction.ADD_CULTURE_GROUP_ACTION:
												openPanel()
												updatePanel({ id: 'planning.cultureGroup.addGroup.title' }, <NouveauGroupeDeCulture
													onSubmit={submitCreerGroupeDeCulture} />, [
													<FormButton
														type="primary"
														formName={NEW_CULTURE_GROUP_FORM_NAME}
														getDisabledFromState={state => isInvalid(NEW_CULTURE_GROUP_FORM_NAME)(state)}
													>
														<FormattedMessage id="planning.cultureGroup.addGroup.bouton.add" />
													</FormButton>
												], true)
												break
											default:
												break
										}
									}
									}
									formattedMessageId="planning"
									placement="bottom"
								>
									<Button
										type="primary"
										startIcon={<CultureOutlinedIcon />}
									>
										<FormattedMessage id="planning.buttons.new.label" />
									</Button>
								</MenuActions>
							</Box>
						</AccessChecker>}
						<Box p={1}>
							<PlanningSizeSelector />
							{!campagne.preview ?
								<>
									<ToolBarButton type={'filter'} onClick={setFilterOpen}><FilterIcon color="currentColor" /></ToolBarButton>
									<Sort />
									<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
										<Export isRevise={isRevise} />
									</AccessChecker>
								</>
								: ''}
							<IconButton className={classes.icon} onClick={() => openDialog({ id: 'legende.title' }, <Legende isComparing={isCompare} />, [], TYPE_DIALOG.DETAILS)}>
								<QuestionIcon />
							</IconButton>
						</Box>
					</Grid>
				</Grid>}
		</DetailsPanelContext.Consumer>
	)
}

ToolBar.propTypes = {
	campagne: PropTypes.object,
	submitting: PropTypes.bool,
	creerAssolement: PropTypes.func,
	accessAssolement: PropTypes.func,
	openCultureDetail: PropTypes.func,
	submitNewCulture: PropTypes.func,
	classes: PropTypes.object,
	isCompare: PropTypes.bool,
	setCompare: PropTypes.func
}

const mapStateToProps = (state) => ({
	filterValues: getFilterValues(state),
	isCompare: isCompare(state)
})

const actions = {
	creerAssolement,
	setCompare,
	setFilterOpen,
	getNotifications
}

export default compose(
	connect(mapStateToProps, actions),
	loadAssolement,
	injectAssolementFilter,
	openCultureDetail,
	injectValidatePlanning,
	withStyles(styles)
)(ToolBar)
