import { MenuItem } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import FertilisationIcon from '../components/icon/FertilisationIcon'
import FertilisationOutlinedIcon from '../components/icon/FertilisationOutlinedIcon'
import FinRecolteIcon from '../components/icon/FinRecolteIcon'
import FinRecolteOutlinedIcon from '../components/icon/FinRecolteOutlinedIcon'
import ImplantationIcon from '../components/icon/ImplantationIcon'
import ImplantationOutlinedIcon from '../components/icon/ImplantationOutlinedIcon'
import PrepaPlancheIcon from '../components/icon/PrepaPlancheIcon'
import PrepaPlancheOutlinedIcon from '../components/icon/PrepaPlancheOutlinedIcon'
import RecolteIcon from '../components/icon/RecolteIcon'
import RecolteOutlinedIcon from '../components/icon/RecolteOutlinedIcon'
import SemiDirectIcon from '../components/icon/SemiDirectIcon'
import SemiDirectOutlinedIcon from '../components/icon/SemiDirectOutlinedIcon'
import SemiPlancheIcon from '../components/icon/SemiPlancheIcon'
import SemiPlancheOutlinedIcon from '../components/icon/SemiPlancheOutlinedIcon'
import { JOURS } from '../modules/fo/gestionTache/utils/constants'
import { getState } from '../modules/fo/planning/services/planningApi'
import { SP_PLANIFICATEUR_DATATABLE_KEYS, SP_PLANIFICATEUR_DATATABLE_KEYS_PARAMS } from '../modules/fo/tourPlaine/component/suiviPepiniere/tool/suiviPepiniere.constants'
import { ASSOLEMENT_ETAT, DEFAULT_CODE, DEFAULT_LABEL, DEFAULT_LEFT_CLICK, ETAPE_TYPE, NOT_ACTION_POINTER, PROFILS, ROLE_WEIGHT } from './constants'

/**
 * Retourne un composant React (si possible internationalisé) adapté au message passé en paramètre.
 *
 * @param message
 * @returns {*}
 */
export const toI18N = message => {
	if (React.isValidElement(message)) {
		return message
	}
	if (typeof message === 'string') {
		return <span>{message}</span>
	}
	return <FormattedMessage {...message} />
}

export const joinNode = (elements, separator) => {
	let elementsArray = elements
	if (!Array.isArray(elementsArray)) {
		elementsArray = [elements]
	}

	const elemetsArrayFiltered = elementsArray.filter(element => !!element)
	return elemetsArrayFiltered.map((element, index) => <React.Fragment
		key={index}>{element}{index !== elemetsArrayFiltered.length - 1 && separator}</React.Fragment>)
}

export const localCompare = (key, desc) =>
	(element1, element2) => (desc ? -1 : 1) * (element1[key] ?? '').localeCompare((element2[key] ?? ''))

export const localStringAndNumberCompare = (key, desc) => {
	if (key === SP_PLANIFICATEUR_DATATABLE_KEYS.VALUE || key === SP_PLANIFICATEUR_DATATABLE_KEYS_PARAMS.VALUE) {
		return (element1, element2) => (desc ? 1 : -1) * (element1[key] - element2[key])
	} else if (key === undefined) {
		return undefined
	}
	return (element1, element2) => (desc ? -1 : 1) * element1[key].localeCompare(element2[key])
}

export const compareNullableNumbers = (key, desc) =>
	(element1, element2) => (desc ? -1 : 1) * (!element1[key] ? -1 : !element2[key] ? 1 : element1[key] - element2[key])

export const getAccessCheckerRole = (allowedProfile, strict = false) => {
	let profile = allowedProfile
	if (typeof allowedProfile === 'string') {
		profile = Object.values(PROFILS).find(profile => profile.nom === allowedProfile)
	}

	let access = profile
	if (!Array.isArray(access)) {
		access = [profile]
	}

	if (!strict) {
		const higherRole = Object.keys(ROLE_WEIGHT)
			.filter(role => ROLE_WEIGHT[allowedProfile.role] < ROLE_WEIGHT[role])
		access = access.concat(Object.values(PROFILS).filter(profile => higherRole.indexOf(profile.role) !== -1))
	}

	return access
}

export const getEtapeIconByType = (type, isAssolementValide, props = {}) => {
	switch (type) {
		case ETAPE_TYPE.SEMI_DIRECT:
			return isAssolementValide ? <SemiDirectOutlinedIcon {...props} color="black" /> : <SemiDirectIcon {...props} />
		case ETAPE_TYPE.SEMI_EN_CONTENANT:
			return isAssolementValide ? <SemiPlancheOutlinedIcon {...props} color="black" /> : <SemiPlancheIcon {...props} />
		case ETAPE_TYPE.PREP_PLANCHE:
			return isAssolementValide ? <PrepaPlancheOutlinedIcon {...props} color="black" /> : <PrepaPlancheIcon {...props} />
		case ETAPE_TYPE.IMPLANTATION:
			return isAssolementValide ? <ImplantationOutlinedIcon {...props} color="black" /> : <ImplantationIcon {...props} />
		case ETAPE_TYPE.RECOLTE:
			return isAssolementValide ? <RecolteOutlinedIcon {...props} color="black" /> : <RecolteIcon {...props} />
		case ETAPE_TYPE.FIN_RECOLTE:
			return isAssolementValide ? <FinRecolteOutlinedIcon {...props} color="black" /> : <FinRecolteIcon {...props} />
		case ETAPE_TYPE.FERTILISATION:
			return isAssolementValide ? <FertilisationOutlinedIcon {...props} color="black" /> :
				<FertilisationIcon {...props} />
		default:
			return <></>
	}
}

/** Fonctions utilitaires pour les champs **/
export const normalizeDate = (value) => {
	// Gestion de la date à 10 caractères
	if (value && value.length > 10) {
		return value.substring(value.length - 10)
	}
	return value || ''
}

export const normalizeNumber = (value) => {
	if (!value) {
		return ''
	}
	const onlyNums = `${value}`.replace(/,/g, '.').replace(/[^\d.]/g, '')
	const unitPart = onlyNums.split('.')
	let firstPart = unitPart[0]
	let lastPart = unitPart[1]

	if (firstPart.length > 3) {
		firstPart = firstPart.replace(/ /g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	}

	let result
	if (!!lastPart && lastPart.length > 2) {
		// Arrondi
		result = `${firstPart},${Number.parseFloat(onlyNums).toFixed(2).split('.')[1]}`
	} else if (unitPart.length > 1) {
		result = `${firstPart},${lastPart}`
	} else {
		result = firstPart
	}

	return result.replace(/\./g, ',')
}

export const formatInteger = (value) => {
	if (value === undefined || value === null) {
		return ''
	}
	return `${value}`.replace(/,/g, '.').replace(/\./g, '')
}

export const formatPositiveInteger = (value, min) => {
	if (value === undefined || value === null || (min && !Number.isNaN(value) && +value < min)) {
		return ''
	}
	return `${value}`.replace(/\D+/g, '')
}

export const formatDecimal = (value) => {
	if (!value) {
		return ''
	}
	return `${value}`.replace(/\./g, ',')
}

/**
 * Vérifie que les dates fournies coincident avec la date de semis de l'itk
 * @param itk
 * @param campagne
 * @param dateDebut
 * @param dateFin
 * @returns {boolean}
 */
export const compareDateSemisWithItk = (itk, campagne, dateDebut, dateFin) => {
	const janDay = getJanuaryInCampain(campagne)
	const debutSemis = janDay.clone().add(itk.debutSemis, 'weeks').startOf('isoWeek')
	const finSemis = janDay.clone().add(itk.debutSemis + itk.dureeSemis, 'weeks').startOf('isoWeek')

	return moment(dateDebut).isAfter(moment(finSemis)) || moment(dateFin).isBefore(moment(debutSemis))
}

export const compareDateSemis = (assolement, dateDebut, dateFin) => {
	return moment(dateDebut).isAfter(moment(assolement.semisFin)) || moment(dateFin).isBefore(moment(assolement.semisDebut))
}

/**
 * Renvoie le 1er janvier compris dans la campagne
 * @param campagne
 * @returns {moment.Moment}
 */
export const getJanuaryInCampain = (campagne) => {
	const yearStart = moment(campagne.dateDebut).get('year')
	const yearEnd = moment(campagne.dateFin).get('year')

	const firstJanStart = moment([yearStart, 0, 1])
	const firstJanEnd = moment([yearEnd, 0, 1])

	if (firstJanStart.isBefore(campagne.dateFin) && firstJanStart.isAfter(campagne.dateDebut)) {
		return firstJanStart.clone()
	}

	return firstJanEnd.clone()

}

/** Si nous ne sommes pas en comparaison et que l'assolement est deleted alors il n'est pas à afficher**/
export const displayAssolement = (isCompare, assolement, campagneId) => {
	return ((isCompare && assolement.campagne.id === campagneId) || (assolement && assolement.etat !== ASSOLEMENT_ETAT.VALIDE))
}

export const checkState = (id, onSuccess, onFailure, refreshDelay = 5000, additionalFunctions = []) => {
	return new Promise((resolve, reject) => {
		let intervalId

		const handleResult = (callback, promiseFct) => res => {
			// il faut retourner explicitement false pour continuer de verifier l'etat
			if (!callback || callback(res) !== false) {
				promiseFct(res)
				clearInterval(intervalId)
				if (additionalFunctions.length > 0) {
					additionalFunctions.forEach(f => f())
				}
			}
		}

		intervalId = setInterval(() => {
			getState(id)
				.then(response => {
					if (response.hasErreur) {
						handleResult(onFailure, resolve)(response)
					}
					handleResult(onSuccess, resolve)(response)
				})
				.catch(handleResult(onFailure, reject))
		}, refreshDelay)
	})

}

export const sliceText = (text, maxSize = 3, end = '.') => {
	if (text.length <= maxSize + 1) {
		return text
	}
	return text.slice(0, maxSize) + end
}

/**
 * Méthode utilitaire permettant d'obtenir la date en fonction d'une semaine, d'une année et d'un offset
 * @param week le numéro de semaine concernée
 * @param year l'année concernée
 * @param sunday true si on veut le dimanche, sinon on prend le lundi
 * @returns {Date} la date
 */
export const getDateOfWeek = (week, year, sunday = false) => {
	// si on souhaite récupérer le dimanche il faut ajouter une semaine car moment se passe sur une semaine qui va du dimanche au lundi
	return sunday
		? moment().year(year).week(week).day('Sunday').add(1, 'weeks').toDate()
		: moment().year(year).week(week).day('Monday').toDate()
}

/**
 * Principalement utilisée dans l'écran vue liste des assolements
 *
 * → Permet d'itérer et de rendre sous forme de <li> le contenu du paramètre 'selectedAssolements'
 * → A mettre dans un <ul> ou autre
 **/
export const listeCulturesConcernees = (selectedAssolements) => {
	return selectedAssolements.map((assol) => <li style={{ paddingBottom: '5px' }}>{assol.culture.nom} ({assol.planche.nom} - {assol.planche.surface}m²)</li>)
}

export const listeCulturesConcerneesDetailsRecolte = (selectedAssolements) => {
	return selectedAssolements.map((assol) =>
		<li style={{ paddingBottom: '5px' }}>
			{assol.culture.nom} ({assol.planche.nom} - {assol.planche.surface}m2)
			<br/>
			<FormattedMessage id={'listAssolement.forms.content.fourchettePrevisionRecolte'}
			                  values={{
				                  dateDebut: moment(assol.assolementEtapeMap.R.dateDebut).format('DD/MM/YYYY'),
				                  dateFin: moment(assol.assolementEtapeMap.R.dateFin).format('DD/MM/YYYY')
			                  }}
			/>
		</li>)
}

export const listeCulturesConcerneesDetailsImplantation = (selectedAssolements) => {
	return selectedAssolements.map((assol) =>
		<li style={{ paddingBottom: '5px' }}>
			{assol.culture.nom} ({assol.planche.nom} - {assol.planche.surface}m2)
			<br/>
			<FormattedMessage id={'listAssolement.forms.content.previsionImplantation'}
			                  values={{
				                  dateDebut: moment(assol.assolementEtapeMap.I.dateDebut).format('DD/MM/YYYY')
			                  }}
			/>
		</li>)
}

/**
 * Pour les dialogs
 * @param selectedAssolements
 * @returns {*}
 */
export const formatListeCulturesConcerneesDetails = (selectedAssolements) => {
	return selectedAssolements
		.map((assol, index) => {
			const dates = Object.values(assol.assolementEtapeMap)
				.filter(assolement => !assolement.deleted)
				.map(assolement => assolement.dateDebut)
				.sort()
			return (
				<li style={{ paddingBottom: '5px' }} key={index}>
					<FormattedMessage
						id="listAssolement.popup.content.liste"
						values={{
							culture: assol.culture.nom ? <b>{assol.culture.nom}</b> : ' - ',
							surface: assol.planche.nom ? <b>{assol.planche.nom}</b> : ' - ',
							dateDebPremiereEtape: (dates.length > 0) ? <b>{moment(dates[0]).format('DD/MM')}</b> : '--/--',
							dateDebDerniereEtape: (dates.length > 0) ? <b>{moment(dates[dates.length - 1]).format('DD/MM')}</b> : ' --/--'
						}}
					/>
				</li>
			)
		})
}

/** Méthode de permettant de :
 * → Passer d'un format date en convertissant les DATES en minutes
 **/
export const revertFormatTime = (value) => {
	const heures = value.getHours()
	const minutes = value.getMinutes()
	return 60 * heures + minutes
}

/** Méthode de permettant de :
 * → Passer d'un format date en convertissant les HH:MM en minutes
 **/
export const revertFormatTimev2 = (value) => {
	const heures = parseInt(value.split(':')[0])
	const minutes = parseInt(value.split(':')[1])
	return (60 * heures) + minutes
}

/** Méthode de permettant de :
 * → Rajouter un '0' si les H ou M sont <10
 * ex : 9 → 09
 **/
export const padZero = (number) => {
	if (number < 10) {
		return `0${number}`
	}
	return `${number}`
}

/**
 * Reformate une durée de façon lisible
 */
export const formatDuration = (duration, format = 'h[h]mm', options = {}) => {
	return moment.duration(duration).format(format, { ...options, trim: false })
}

/**
 * Capitalize
 * @param firstChar
 * @param rest
 * @returns {`${string}${*}`}
 */
export const capitalize = ([firstChar, ...rest]) => `${firstChar.toUpperCase()}${rest.join('')}`

/**
 * Capitalize All First letter
 * @returns string
 */
export const titleCase = (stringToTitleCase) => stringToTitleCase
	.toLowerCase()
	.split(' ')
	.map(word => word.charAt(0).toUpperCase() + word.slice(1))
	.join(' ')

/**
 * Principalement utilisée dans l'écran - gestion des tâches - Affectation - Panel tacheDetail
 *
 * → Permet d'itérer et de rendre sous forme de <li> le contenu du paramètre 'materielIndispensable'
 * → A mettre dans un <ul> ou autre
 **/
export const listeMaterielFormatLi = (materielIndispensable) => {
	return materielIndispensable.map(outil => <li>{outil.nom}</li>)
}

/**
 * Principalement utilisée dans l'écran - gestion des tâches - Affectation - Panel tacheDetail
 *
 * → Permet d'itérer et de rendre sous forme de <li> le contenu du paramètre 'listePersonnes'
 * → A mettre dans un <ul> ou autre
 **/
export const listePersonneFormatLi = (listePersonnes) => {
	return listePersonnes.map((personne) =>
		<li>
			{personne.flagAffectationActive
				? <FormattedMessage id={'gestionTaches.ecranAffectations.panels.content.itemListePersonne'}
				                    values={{
					                    nom: personne.user.preferences.ALIAS,
					                    tempsPasse: <b>{convertMinToHours(personne.tempsPasse)}</b>
				                    }}

				/>
				: <FormattedMessage id={'gestionTaches.ecranAffectations.panels.content.itemListePersonneDesaffecte'}
				                    values={{
					                    nom: personne.user.preferences.ALIAS,
					                    tempsPasse: <b>{convertMinToHours(personne.tempsPasse)}</b>
				                    }}
				/>
			}
		</li>
	)
}

/**
 * Principalement utilisée dans l'écran - gestion des tâches - Affectation - Panel tacheDetail
 *
 * → Permet d'itérer et de rendre sous forme de String concaténée le contenu du paramètre 'joursDeReccurrence'
 **/
export const listeJoursDeRecurrenceFormatString = (joursDeReccurrence) => {
	return 'les'.concat(
		joursDeReccurrence.map((jour, index) => {
				if (index < joursDeReccurrence.length - 1) {
					return ` ${jour}`
				}
				return ` ${jour} `
			}
		)
	)
}

/** Méthode de permettant de :
 * → Passer d'un format minutes à un format HhM
 **/
export const convertMinToHours = (value, separator = 'h') => {
	return parseInt((value / 60)) + separator + padZero((value % 60))
}

/** Méthode de permettant de :
 * → Créer un tableau de jours via l'objet 'tacheRecurrence'
 **/
export const getJoursRecurrenceTache = (tacheRecurrence) => {
	const jours = []
	Object.values(JOURS)
		.forEach(jour => {
			if (tacheRecurrence[jour])
				jours.push(jour)
		})
	return jours
}

/** Méthode de permettant de :
 * → Passer d'un format minutes à un format H:M
 * → Utile dans le composant : TempsPasseUserLi
 **/
export const convertMinToHoursFormatTime = (value) => {
	return padZero(parseInt((value / 60))) + ':' + padZero((value % 60))
}

export const getLibelleJourFromNumero = (numero) => {
	switch (numero) {
		case 0:
			return 'Lundi'
		case 1:
			return 'Mardi'
		case 2:
			return 'Mercredi'
		case 3:
			return 'Jeudi'
		case 4:
			return 'Vendredi'
		case 5:
			return 'Samedi'
		case 6:
			return 'Dimanche'
		default:
			return ''
	}
}

export const sortObjectByLabel = (array) => array.sort((a, b) => a.label.localeCompare(b.label))

export const checkMailFormat = (email) => {
	const regex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return regex.test(email)
}

export const parsePositiveFloat = (value) => {
	const matches = value.match(/(\d+(\.\d{1,2})?)/)
	return matches ? parseFloat(matches[0]) : 0
}

/**
 *
 * @param valueParent
 * @param list
 * @returns {*} la liste de valeur filtrée si on avait une valeur parent et complète sinon
 */
export const splitSubListByValue = (valueParent, list) => {
	if (!valueParent || valueParent === '' || !list) {
		// Comme on n'a pas de valeur parent, on retourne la liste complète
		return list
	} else {
		// On split la liste ici
		return Object.keys(list)
			.map(itemId => list[itemId])
			.filter(item => {
				if (!item.code) {
					return false
				}
				// Gestion multiselect
				if (Array.isArray(valueParent)) {
					return valueParent.some(valueParentItem => item.code.startsWith(`${valueParentItem}$_`)
					)
				}

				return item.code.startsWith(`${valueParent}$_`)
			})
			.sort((item1, item2) => item1.label.localeCompare(item2.label))
	}
}

/**
 * @param valueListe la liste de valeurs où il faut chercher le label
 * @param label le label a rechercher dans la liste
 * @returns les MenuItem matchants
 */
export const getMenuItemFromLabel = (valueListe, label) => {
	const index = valueListe.findIndex(value => value.code === label)
	if (index >= 0) {
		const entity = valueListe[index]
		return (
			<MenuItem key={entity.code} value={entity.code}>
				{entity.label}
			</MenuItem>
		)
	}
	return (
		<MenuItem key={DEFAULT_CODE} value={DEFAULT_CODE}>
			{DEFAULT_LABEL}
		</MenuItem>
	)
}

/**
 * Le type de retour permet à typescript de restreindre davantage le type de cette valeur
 * (voir <a href="https://www.typescriptlang.org/docs/handbook/2/narrowing.html#control-flow-analysis">ce lien</a>) <br/>
 * Par exemple: <br/>
 * <pre>
 * const exemple = <T>(a: Nullable<T>) => {
 *   if (assertNotNullOrUndefined(a)) {
 *     // Dans ce bloc le compilateur sait que A est de type T
 *   } else {
 *     // Dans ce bloc le compilateur sait que A est null ou undefined
 *   }
 *   </pre>
 }

 * @param value une valeur de type T pouvant être null ou undefined
 * @returns La fonction retourne false si le paramètre vaut null ou undefined.
 */
export const assertNotNullOrUndefined = (value) => {
	return typeof value !== 'undefined' && value !== null
}

export const onClickAway = (close, open, isClickAwayEnabled = true) => event => {
	const isLeftClick = event.button === DEFAULT_LEFT_CLICK
	const isNonActionPointer = NOT_ACTION_POINTER.has(window.getComputedStyle(event.target).cursor)
	if (isClickAwayEnabled && open && event.type === 'mousedown' && (isLeftClick || !isNonActionPointer)) {
		close()
	}
}
/**
 * Format List to OptionsList
 * @param valueList
 * @returns {*}
 */
export const valueListToOptionsList = (valueList) => valueList && valueList.map(element => ({ label: element.label, code: element.code }))

/**
 * Permet de determiner combien de semaine ISO l'année compte
 * @param year l'année en chiffre (par exemple : 2020)
 * @returns {number} le nombre de semaines ISO dans l'année (52 ou 53)
 */
export const getNbWeeksISOInYear = (year) => {
	/* Source Wikipedia de l'explication des semaines ISO 53 :
	 * An ISO week-numbering year (also called ISO year informally) has 52 or 53 full weeks.
	 * That is 364 or 371 days instead of the usual 365 or 366 days.
	 * These 53-week years occur on all years that have Thursday as 1 January and on leap years that start on Wednesday.
	 * The extra week is sometimes referred to as a leap week, although ISO 8601 does not use this term.
	 *
	 * Note : quand on a une année bissextile qui commence un mercredi, ca veut dire que l'année fini par un jeudi
	 */
	const firstDayOfYear = new Date(year, 0, 1); // Mois 0 = janvier
	const lastDayOfYear = new Date(year, 11, 31); // Mois 11 = décembre

	// Une année a 53 semaines si elle commence un jeudi ou si elle finit un jeudi
	const has53Weeks = firstDayOfYear.getDay() === 4 || lastDayOfYear.getDay() === 4;

	// Retourner le nombre de semaines
	return has53Weeks ? 53 : 52;
}