import { Grid, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import CloudUpload from '@material-ui/icons/CloudUpload'
import ShowChart from '@material-ui/icons/ShowChart'
import TableChart from '@material-ui/icons/TableChart'
import Update from '@material-ui/icons/Update'
import { colors, NOM_ONGLET_GESTION_TACHES, ONGLET_GESTION_TACHE, STEP_GESTION_TACHES } from '../../../../../utils/constants'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import Button from '../../../../../components/Button'
import { useSnackbar } from '../../../../../components/layout/snackbar/SnackbarContext'
import { useGestionTacheContext } from '../../GestionTacheProvider'
import { injectPlanificationFilter } from '../../injectors/filterInjector'
import { ActionPanelContext, DetailsPanelContext, FilterPanelContext } from '../../../../../components/layout/rightPanels'
import { resetFilter, setFilterOpen } from '../../../planning/services/toolbar/toolbarAction'
import { StepIcon, StyledConnector, StyledLabel } from '../../../../common/stepper/Stepper'
import StyledTabs from '../../../../../components/layout/tab/StyledTabs'
import StyledTab from '../../../../../components/layout/tab/StyledTab'
import { injectExportSuiviAvancement } from '../../../suiviAvancement/injectors/exportPopin'
import { injectPopinPlanification } from '../../injectors/planificationPopin'
import IconButton from '../../../../../components/IconButton'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { LocalDate } from '../../../../../utils/date/local-date'
import { DateFormat as LocaleDateFormat, DateUnit } from '../../../../../utils/date/model/date'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import { validerPlanification } from '../../services/api'
import { getSemaine } from '../../gestionTachesApi'
import { DialogContext } from '../../../../../components/layout/dialog'
import { getTachesOrdonnancement } from '../../services/actions'
import { ETAT_SEMAINE } from '../../utils/constants'
import { DateFormat } from '../../../../../utils/dateConstants'
import CheckIcon from '@material-ui/icons/Check'

const styles = () => getStyles({
	selectorWeekContainer: {
		gap: 20,
		paddingBottom: 5
	},
	selectorIconContainer: {
		height: 35,
		width: 35
	},
	descriptionText: {
		fontSize: 12,
		lineHeight: '15px',
		color: colors.text
	},
	selectorText: {
		fontStyle: 'normal',
		lineHeight: '18px',
		fontSize: 14,
		fontWeight: '600',
		color: '#000000',
		marginBottom: 4,
		textAlign: 'center'
	},
	bouton: {
		padding: '5px 26px'
	},
	tabBar: {
		borderBottom: '1px solid lightgray'
	}
})

const getOngletTaches = () => {
	return [
		NOM_ONGLET_GESTION_TACHES.SYNTHESE,
		NOM_ONGLET_GESTION_TACHES.RESSOURCES,
		NOM_ONGLET_GESTION_TACHES.PLANIFICATION,
		NOM_ONGLET_GESTION_TACHES.AFFECTATION,
		NOM_ONGLET_GESTION_TACHES.ORDONNANCEMENT
	]
}

const getStepsHeader = () => {
	return [
		STEP_GESTION_TACHES.PLANNING,
		STEP_GESTION_TACHES.RESULTATS
	]
}

export const ToolbarStepIcon = (props) => {
	const icons = {
		1: <TableChart />,
		2: <ShowChart />
	}

	return (
		<StepIcon icons={icons} {...props} />
	)
}

const ToolBarTaches = ({
	classes,
	openPopinExportSuiviAvancement,
	openPopinPlanification,
	resetFilter,
	getTachesOrdonnancement
}) => {

	const { onglet, setOnglet, semaineSelection, setSemaineSelection, semaineCourante, jourSelection, isResultat } = useGestionTacheContext()
	const { snackSuccess, snackError } = useSnackbar()
	const steps = getOngletTaches()
	const headerSteps = getStepsHeader()
	const { formatMessage } = useIntl()
	const { closePanel: closeActionPanel } = React.useContext(ActionPanelContext)
	const { closePanel: closeDetailsPanel } = React.useContext(DetailsPanelContext)
	const { closePanel: closeFilterPanel } = React.useContext(FilterPanelContext)
	const { openDialog } = React.useContext(DialogContext)
	const { refreshHeaderData, setSemaineCourante } = useGestionTacheContext()

	const [currentWeekId, setCurrentWeekId] = useState(semaineCourante ? LocalDate.fromString(semaineCourante.debutSemaine, DateFormat.yyyy_MM_dd).format(LocaleDateFormat.SHORT_DATE_WITH_DASH) : undefined)

	useEffect(() => {
		if (semaineCourante) {
			setCurrentWeekId(
				LocalDate.fromString(semaineCourante.debutSemaine, DateFormat.yyyy_MM_dd).format(LocaleDateFormat.SHORT_DATE_WITH_DASH)
			)
		}
	}, [semaineCourante])

	const handleStep = (step) => () => {
		if (semaineCourante) {
			closeActionPanel()
			closeDetailsPanel()
			closeFilterPanel()
			resetFilter()

			if (step === ONGLET_GESTION_TACHE.RESSOURCES || step === ONGLET_GESTION_TACHE.PLANIFICATION) {
				setSemaineSelection(semaineSelection)
			}
			setOnglet(step, jourSelection)
		} else {
			snackError({ id: 'gestionTaches.ecranRessources.snackbar.errors.initializeBefore' })
		}
	}

	const handleValiderPlanification = React.useCallback(
		() => validerPlanification(LocalDate.fromString(currentWeekId, DateFormat.yyyy_MM_dd).format(LocaleDateFormat.SHORT_DATE_WITH_DASH))
			.then(() => getSemaine(LocalDate.fromString(currentWeekId, DateFormat.yyyy_MM_dd).format(LocaleDateFormat.SHORT_DATE_WITH_DASH)))
			.then((semaine) => {
				snackSuccess({ id: 'gestionTaches.ecranOrdonnancement.validation.snackSuccess' })
				getTachesOrdonnancement(LocalDate.fromString(jourSelection).format(LocaleDateFormat.SHORT_DATE_WITH_DASH))
				refreshHeaderData()
				setSemaineCourante(semaine)
			})
			.catch((e) => {
				if (e && e.response && e.response.status === 400) {
					openDialog(
						{ id: 'gestionTaches.ecranOrdonnancement.validation.tacheNonAffecteesPopin.title' },
						<span style={{ padding: 20 }}>
							<FormattedMessage id="gestionTaches.ecranOrdonnancement.validation.tacheNonAffecteesPopin.message">
								{text => <div className={classes.validationErrorPopupContent}>{text}</div>}
							</FormattedMessage>
						</span>,
						[]
					)
				} else {
					snackError()
				}
			}),
		[semaineSelection, snackSuccess, getTachesOrdonnancement, jourSelection, refreshHeaderData, setSemaineCourante, openDialog, classes, snackError]
	)


	const formatWeekTitle = (weekId) => {
		const week = LocalDate.fromString(weekId, DateFormat.yyyy_MM_dd)
		return formatMessage({ id: 'gestionTaches.toolbar.selecteur.week' }, {
			weekNumber: week.weekNumber,
			start: week.startOf().format(LocaleDateFormat.SHORT_DATE_2_DIGITS_2_MONTH),
			end: week.endOf(DateUnit.week).format(LocaleDateFormat.SHORT_DATE_2_DIGITS_2_MONTH),
			b: (chunks) => <Typography className={classes.selectorText}>{chunks}</Typography>
		})
	}

	// Go to the next week
	const goNextWeek = () => {
		const nextWeek = LocalDate.fromString(currentWeekId, DateFormat.yyyy_MM_dd).plus(1, DateUnit.week)
		setSemaineSelection(nextWeek.format(LocaleDateFormat.REVERSE_SHORT_DATE))
		setCurrentWeekId(nextWeek.format(LocaleDateFormat.SHORT_DATE_WITH_DASH))
	}

	// Go to the previous week
	const goPreviousWeek = () => {
		const previousWeek = LocalDate.fromString(currentWeekId, DateFormat.yyyy_MM_dd).minus(1, DateUnit.week)
		setSemaineSelection(previousWeek.format(LocaleDateFormat.REVERSE_SHORT_DATE))
		setCurrentWeekId(previousWeek.format(LocaleDateFormat.SHORT_DATE_WITH_DASH))
	}

	return (
		<Grid container direction="column" justify="space-between">
			<Grid container item direction="row" style={{ height: 175 }}>
				<Grid container item direction="column" xs={3} justify="space-between" alignItems="flex-start" style={{ padding: 20 }}>
					<Typography variant="h2" className={classes.head}>
						<FormattedMessage id="gestionTaches.toolbar.text.planificationDesTaches" />
					</Typography>
					{!isResultat && <Button
						type="secondary"
						startIcon={<CloudUpload />}
						onClick={openPopinExportSuiviAvancement}
						className={classes.bouton}
					>
						<FormattedMessage id="gestionTaches.toolbar.buttons.exportPlanning" />
					</Button>}
				</Grid>
				<Grid item container direction="column" xs={6} justify="space-evenly" alignItems="center">
					<Stepper alternativeLabel connector={<StyledConnector />} activeStep={headerSteps.find(s => s.isResultat === isResultat).order}>
						{headerSteps.map(step => (
							<Step key={step.isResultat} /*onClick = {() => setIsResultat(step.isResultat)}*/>
								<StyledLabel classes={classes.label} StepIconComponent={ToolbarStepIcon} /*StepIconProps = {{clickable: true}}*/ large>{step.nom}</StyledLabel>
							</Step>
						))}
					</Stepper>
					{onglet !== ONGLET_GESTION_TACHE.SYNTHESE && <Grid item container xs direction="row" justify="center" alignItems="center" className={classes.selectorWeekContainer}>
						<IconButton className={classes.selectorIconContainer} onClick={goPreviousWeek}>
							<ArrowBackIosIcon />
						</IconButton>
						<Typography className={classes.descriptionText}>
							{formatWeekTitle(currentWeekId)}
						</Typography>
						<IconButton className={classes.selectorIconContainer} onClick={goNextWeek}>
							<ArrowForwardIosIcon />
						</IconButton>
					</Grid>}
				</Grid>
				<Grid container item direction="column" xs={3} justify="space-between" alignItems="flex-end" style={{ padding: 20 }}>
					<Button
						type="primary"
						startIcon={isResultat ? <CheckIcon /> : <Update />}
						onClick={() => {
							if (isResultat) {
								alert('Fonctionnalité non disponible pour le moment')
							} else {
								openPopinPlanification(semaineCourante)
							}
						}}
						className={classes.bouton}
					>
						<FormattedMessage id={isResultat ? 'gestionTaches.toolbar.buttons.approuver' : 'gestionTaches.toolbar.buttons.planification'} />
					</Button>
					{(!isResultat && (onglet === ONGLET_GESTION_TACHE.ORDONNANCEMENT && semaineCourante && semaineCourante.etat === ETAT_SEMAINE.BROUILLON)) &&
						<Button
							type="secondary"
							startIcon={<PlaylistAddCheckIcon />}
							onClick={handleValiderPlanification}
							className={classes.bouton}
						>
							<FormattedMessage id="gestionTaches.toolbar.buttons.validerPlanification" />
						</Button>
					}
				</Grid>
			</Grid>
			<Grid item container justify="center" alignContent="flex-end" direction="row" className={classes.tabBar}>
				<StyledTabs value={onglet}>
					{steps.map((label, index) => (
						<StyledTab onClick={handleStep(index)} key={index} label={label} withoutBorder />
					))}
				</StyledTabs>
			</Grid>
		</Grid>
	)
}

ToolBarTaches.propTypes = {
	classes: PropTypes.object,
	resetFilter: PropTypes.func
}

const actions = {
	setFilterOpen,
	resetFilter,
	getTachesOrdonnancement
}

export default compose(
	connect(null, actions),
	injectPopinPlanification,
	injectExportSuiviAvancement,
	injectPlanificationFilter,
	withStyles(styles)
)(ToolBarTaches)
