import { IconButton } from '@material-ui/core'
import { ControlPointDuplicate, DeleteForever, Edit, LowPriority, MoreVert, Room } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import CommentaireIcon from '../../../../../components/icon/CommentaireIcon'
import CommentaireOutlinedIcon from '../../../../../components/icon/CommentaireOutlinedIcon'
import CultureUniIcon from '../../../../../components/icon/CultureUniIcon'
import MenuActions from '../../../../../components/MenuActions'
import { ETAPE_ETAT, PROFILS } from '../../../../../utils/constants'
import { getAccessCheckerRole } from '../../../../../utils/utils'
import { openCultureDetail } from '../../services/actions/detail/openCultureDetailInjector'
import { openSurfaceDetail } from '../../services/actions/detail/openSurfaceDetailInjector'
import { injectCommentairePlanche, injectCommentaireSuivi } from '../../services/actions/form/commentInjectors'
import { injectDeleteCulture } from '../../services/actions/form/deleteCultureInjector'
import { injectCultureDuplicator } from '../../services/actions/form/duplicateCultureInjector'
import { injectEditCulturePlanche } from '../../services/actions/form/editCulturePlancheInjector'
import { injectEditItineraire } from '../../services/actions/form/editItineraireInjector'
import { injectFertilizationForm } from '../../services/actions/form/fertilizationFormInjector'
import { getAssolementById, getAssolements } from '../../services/assolement/assolementSelector'
import { EN_TERRE, OPEN_PANEL } from '../../utils/constant'
import { checkFertilization } from '../../utils/utils'
import CultureGroupIcon from '../../../../../components/icon/CultureGroupIcon'
import { openCreateCultureGroup } from '../../services/actions/form/openCreateCultureGroupInjector'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { deverrouilleAssolement, verrouilleAssolement } from '../../services/planningApi'
import { loadAssolement } from '../../services/assolement/assolementInjector'
import { useSnackbar } from '../../../../../components/layout/snackbar/SnackbarContext'
import { getNotifications } from '../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../besoinsProduction/SolverStatusContextProvider'

const MESSAGE_MENU_BASE = 'planning.leftPanel.menu'
const CULTURE = 'culture'
const ACCESS_MARAICHER = getAccessCheckerRole(PROFILS.MARAICHER)
const ACCESS_CHEF = getAccessCheckerRole(PROFILS.CHEF)

const styles = () => getStyles({
	names: {
		overflowX: 'hidden',
		textOverflow: 'ellipsis',
		margin: '0 1.5rem 0 0.5rem',
		lineHeight: 'initial'
	},
	cellContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		padding: '8px',
		width: '100%'
	},
	lockIcon: {
		height: 20,
		width: 20
	}
})

const ITEMS = {
	[CULTURE]: [
		{
			name: 'detail',
			icon: <CultureUniIcon color="currentColor" />,
			action: 'detailCulture',
			access: ACCESS_MARAICHER,
			isCompare: true,
			displayWhenCampagneFinished: true
		},
		{
			name: 'suivi',
			icon: <CommentaireIcon color="currentColor" />,
			action: 'commentSuivi',
			access: ACCESS_MARAICHER
		},
		{
			name: 'move',
			icon: <LowPriority />,
			action: 'detail',
			access: ACCESS_CHEF,
			type_not_done: EN_TERRE
		},
		{
			name: 'duplicate',
			icon: <ControlPointDuplicate />,
			action: 'duplicate',
			access: ACCESS_CHEF
		},
		{
			name: 'editItinerary',
			icon: <Edit />,
			action: 'edit',
			access: ACCESS_CHEF,
			type_not_done: EN_TERRE
		},
		{
			name: 'delete',
			icon: <DeleteForever />,
			action: 'delete',
			access: ACCESS_CHEF,
			type_not_done: EN_TERRE
		},
		{
			name: 'addGroup',
			icon: <CultureGroupIcon color="currentColor" />,
			action: 'addGroup',
			access: ACCESS_CHEF,
			displayWhenCampagneFinished: true
		},
		{
			name: 'lockCulture',
			icon: <LockIcon color="currentColor" />,
			action: 'lockCulture',
			access: ACCESS_CHEF,
			displayWhenUnlocked: true
		},
		{
			name: 'unlockCulture',
			icon: <LockOpenIcon color="currentColor" />,
			action: 'unlockCulture',
			access: ACCESS_CHEF,
			displayWhenLocked: true
		}
	],
	surface: [
		{
			name: 'detail',
			icon: <Room />,
			action: 'detailSurface',
			access: ACCESS_MARAICHER,
			isDisable: false,
			isCompare: true,
			displayWhenCampagneFinished: true
		},
		{
			name: 'addComment',
			icon: <CommentaireOutlinedIcon color="currentColor" />,
			action: 'commentPlanche',
			access: ACCESS_MARAICHER
		}
		// todo vu le 15/07. On retire la possibilité de faire des fertilisations pour le moment. Le sujet sera à supprimer ou
		// reprendre plus tard
		// {
		// 	name: 'createFertilization',
		// 	icon: <FertilisationIcon color="currentColor" height="1.2rem"/>,
		// 	action: 'fertilization',
		// 	access: ACCESS_CHEF,
		// 	fertilisationExist: false
		// },
		// {
		// 	name: 'addFertilization',
		// 	icon: <FertilisationIcon color="currentColor" height="1.2rem"/>,
		// 	action: 'fertilization',
		// 	access: ACCESS_CHEF,
		// 	fertilisationExist: true
		// }
	]
}

const MenuLeftPanelItem = ({
	assolement,
	openCultureDetail,
	openSurfaceDetail,
	openEditItineraire,
	openFertilizationForm,
	openEditCulturePlanche,
	openPanelCommentaireSuivi,
	openCommentairePlanche,
	openCultureDelete,
	assolements,
	refreshAssolements,
	openCultureDuplicator,
	openCreateCultureGroup,
	classes,
	isCompare,
	campagne,
	getNotifications
}) => {
	const { snackError, snackSuccess } = useSnackbar()
	const { refreshSolverInfosForCurrentCampagne } = useSolverStatusContext()

	if (!assolement) {
		return null
	}

	const onActionClick = (event, category, item) => {
		switch (item.action) {
			case 'detailCulture':
				openCultureDetail(assolement)
				break
			case 'detailSurface':
				openSurfaceDetail(assolement)
				break
			case 'edit':
				openEditItineraire(assolement, openCultureDetail)
				break
			case 'detail':
				openEditCulturePlanche(assolement)
				break
			case 'fertilization':
				openFertilizationForm(assolement, item.fertilisationExist, () => openSurfaceDetail(assolement))
				break
			case 'commentSuivi':
				openPanelCommentaireSuivi(assolement, OPEN_PANEL)
				break
			case 'commentPlanche':
				openCommentairePlanche(assolement, assolement.planche, assolement.campagne, OPEN_PANEL)
				break
			case 'delete':
				openCultureDelete(assolement)
				break
			case 'duplicate':
				openCultureDuplicator(assolement)
				break
			case 'addGroup':
				openCreateCultureGroup(assolement)
				break
			case 'unlockCulture':
				deverrouilleAssolement(assolement.id)
					.then(() => {
						snackSuccess({ id: 'snackbar.update.deverrouillage' })
						refreshAssolements()
						getNotifications()
						refreshSolverInfosForCurrentCampagne()
					})
					.catch(() => {
						snackError({ id: 'snackbar.error.deverrouillage' })
					})
				break
			case 'lockCulture':
				verrouilleAssolement(assolement.id)
					.then(() => {
						snackSuccess({ id: 'snackbar.update.verrouillage' })
						refreshAssolements()
						getNotifications()
						refreshSolverInfosForCurrentCampagne()
					})
					.catch(() => {
						snackError({ id: 'snackbar.error.verrouillage' })
					})
				break
			default:
				break
		}
	}

	const filterItems = () => {
		const etapesDone = assolement.assolementEtapeList
			.filter(etape => etape.etat === ETAPE_ETAT.TERMINE)
			.map(etape => etape.type)
		const newItems = {}
		Object.keys(ITEMS).forEach(category => {
			if (category === CULTURE && !assolement.culture) {
				return
			}
			newItems[category] = ITEMS[category]
				.filter(item => checkEtape(etapesDone, item.type_not_done, false))
				.filter(item => checkEtape(etapesDone, item.type_done, true))
				.filter(item => checkFertile(item.fertilisationExist))
				.filter(item => checkCompare(item.isCompare, item.action, isCompare, assolement.deleted))
				.filter(item => checkLockStatus(item, assolement))
				.filter(item => !campagne.finished || (campagne.finished && item.displayWhenCampagneFinished))
		})
		return newItems
	}

	const checkEtape = (etapesDone, types, shouldBeDone) => {
		if (!types) {
			return true
		}
		const numberOfTypeDone = types.filter(type => etapesDone.indexOf(type) !== -1).length
		return (numberOfTypeDone > 0) === shouldBeDone
	}

	const checkFertile = (fertilizationExist) => {
		// pas specifié donc pas filtrant
		if (fertilizationExist === undefined) {
			return true
		}
		return checkFertilization(assolement.planche.id, assolements, fertilizationExist)
	}

	const checkLockStatus = (menuItem, assolement) => {
		if (menuItem.displayWhenLocked) {
			return assolement.isVerrouille
		}
		if (menuItem.displayWhenUnlocked) {
			return !assolement.isVerrouille
		}
		return true
	}

	const checkCompare = (isItemCompare, action, isCompare, isDeleted) => {
		// pas en train de comparer les planning donc non filtrant
		if (!isCompare) {
			return true
		}
		return isItemCompare && (!isDeleted || action === 'detailSurface')
	}

	const getCultureOrFertilization = (culture) => {
		if (culture) {
			return <span className={`text-compare ${classes.names}`}>{culture.nom}</span>
		}
		return <span className="text-compare">{'-'}</span>
	}

	return <div className={classes.cellContainer}>
		<span style={{ visibility: assolement.isVerrouille ? 'visible' : 'hidden' }}>
			<LockIcon className={classes.lockIcon} />
		</span>
		{getCultureOrFertilization(assolement.culture)}
		{!campagne.preview ?
			<MenuActions
				items={filterItems()}
				formattedMessageId={MESSAGE_MENU_BASE}
				onClick={(...props) => onActionClick(...props)}
				baseElement={assolement}
			>
				<IconButton>
					<MoreVert />
				</IconButton>
			</MenuActions>
			: ''}
	</div>
}

const mapStateToProps = (state, { assolementId }) => ({
	assolement: getAssolementById(state, assolementId),
	assolements: getAssolements(state)
})

const actions = {
	getNotifications
}

MenuLeftPanelItem.propTypes = {
	assolementId: PropTypes.string,
	assolement: PropTypes.object,
	openCultureDetail: PropTypes.func,
	openSurfaceDetail: PropTypes.func,
	openEditItineraire: PropTypes.func,
	openEditCulturePlanche: PropTypes.func,
	openCultureDelete: PropTypes.func,
	openCreateCultureGroup: PropTypes.func,
	refreshAssolements: PropTypes.func,
	isCompare: PropTypes.bool,
	campagne: PropTypes.object
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	loadAssolement,
	openCultureDetail,
	openSurfaceDetail,
	injectEditCulturePlanche,
	injectEditItineraire,
	injectFertilizationForm,
	injectCommentaireSuivi,
	injectCommentairePlanche,
	injectDeleteCulture,
	injectCultureDuplicator,
	openCreateCultureGroup
)(MenuLeftPanelItem)
