import React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {Field, formValueSelector} from 'redux-form'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import {useIntl} from 'react-intl'
import {injectCommentTypeValueList, injectVariationTypeValueList} from '../../../../../common/valueLists/valueListInjectors'
import Autocomplete from '../../../../../../components/form/Autocomplete'
import Input from '../../../../../../components/form/Input'
import {TYPE_COMMENTAIRE_SUIVI, TYPE_VARIATION_IMPACT_PROD} from '../../../../../../utils/constants'
import {COMMENT_FIELDS} from '../../../utils/constant'
import DateFieldCustom from "../../../../../../components/form/DateFieldCustom";
import {normalizeDate} from "../../../../../../utils/utils";

const getAutocompleteOptions = values => values.map(value => ({ label: value.label, code: value.code }))

const CommentForm = ({ typeCommentaire, typeVariation, commentTypeList, variationTypeList, clearFields }) => {
	const intl = useIntl()
	return (
		<Grid container direction="row" wrap="wrap">
			<Grid item xs={12}>
				<Field
					name={COMMENT_FIELDS.TYPE_COMMENTAIRE_SUIVI}
					component={Autocomplete}
					label={intl.formatMessage({ id: 'commentForm.fields.typeCommentaire' })}
					options={getAutocompleteOptions(commentTypeList)}
					onChange={(event, value) => {
						const fieldsToClear = [COMMENT_FIELDS.TYPE_VARIATION_IMPACT_PROD, COMMENT_FIELDS.VARIATION]
						if (!value) {
							fieldsToClear.push(COMMENT_FIELDS.COMMENTAIRE)
						}
						clearFields(false, false, ...fieldsToClear)
					}}
				/>
			</Grid>
			{typeCommentaire === TYPE_COMMENTAIRE_SUIVI.IMPACT_PRODUCTION && <>
				<Grid item xs={6}>
					<Field
						name={COMMENT_FIELDS.TYPE_VARIATION_IMPACT_PROD}
						component={Autocomplete}
						label={intl.formatMessage({ id: 'commentForm.fields.variation' })}
						options={getAutocompleteOptions(variationTypeList)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name={COMMENT_FIELDS.VARIATION}
						component={Input}
						adornment="%"
						type="number"
						label={intl.formatMessage({ id: 'commentForm.fields.pourcentageVariation' })}
						disabled={typeVariation === TYPE_VARIATION_IMPACT_PROD.NULLE}
					/>
				</Grid>
				<Field
					name={COMMENT_FIELDS.DATE_APPLICATION}
					component={DateFieldCustom}
					type="text"
					placeholder={intl.formatMessage({ id: 'commentForm.fields.dateApplication' })}
					format={normalizeDate}
					textFieldStyle={{width: '100%'}}
					style={{width: '100%', paddingLeft: 10, paddingRight: 10}}
					sliderCalendar
				/>
			</>}
			{!!typeCommentaire && <Grid item xs={12}>
				<Field
					name={COMMENT_FIELDS.COMMENTAIRE}
					component={Input}
					multiline
					label={intl.formatMessage({ id: 'commentForm.fields.commentaire' })}
				/>
			</Grid>}
		</Grid>
	)
}

CommentForm.propTypes = {
	typeCommentaire: PropTypes.string,
	typeVariation: PropTypes.string,
	commentTypeList: PropTypes.array,
	variationTypeList: PropTypes.array,
	clearFields: PropTypes.func
}

const mapStateToProps = (state, { formName }) => {
	const valueSelector = formValueSelector(formName)
	return {
		typeCommentaire: valueSelector(state, COMMENT_FIELDS.TYPE_COMMENTAIRE_SUIVI),
		typeVariation: valueSelector(state, COMMENT_FIELDS.TYPE_VARIATION_IMPACT_PROD)
	}
}

export default compose(
	injectCommentTypeValueList,
	injectVariationTypeValueList,
	connect(mapStateToProps)
)(CommentForm)
