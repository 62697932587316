import {Divider, Grid, Typography, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {Field, FieldArray, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {BOARD_UNAVAILABLE_FIELDS, BOARD_UNAVAILABLE_FORM_NAME} from '../../tool/suiviParBloc.constants'
import {normalizeDate} from '../../../../../../../utils/utils'
import Input from '../../../../../../../components/form/Input'
import {groupBoardsUnavailableByPeriod} from '../../tool/suiviParBloc.utils'
import DateFieldCustom from '../../../../../../../components/form/DateFieldCustom'

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		padding: '30px 40px',
		rowGap: 15,
		maxHeight: 'calc(100vh - 220px)'
	},
	rowContainer: {
		columnGap: 25
	},
	fieldContainer: {
		gap: 10
	},
	label: {
		fontSize: 14,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	},
	boardsText: {
		fontSize: 16,
		lineHeight: '20px',
		fontWeight: '400',
		color: '#000'
	},
	divider: {
		borderBottom: '1px #D6D6D6',
		width: '100%'
	},
	commentLabel: {
		fontSize: 14,
		lineHeight: '15px',
		fontWeight: '600',
		color: '#000',
		marginBottom: 5
	},
	valueLabel: {
		fontSize: 14,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	},
	container: {
		width: '100%'
	},
	formBody: {
		maxHeight: 'calc(100vh - 300px)',
		overflowY: 'auto',
		gap: 10
	},
	ulContainer: {
		marginTop: 0,
		marginLeft: '-20px',
		marginBottom: 10
	},
})

/**
 * Validate
 * @param values
 * @returns {{}}
 */
const validate = (values) => {
	const errors = {}

	const fieldsError = []
	values[BOARD_UNAVAILABLE_FIELDS.BOARDS].forEach((row, index) => {
		const rowError = {}
		if (!row[BOARD_UNAVAILABLE_FIELDS.START]) {
			rowError[BOARD_UNAVAILABLE_FIELDS.START] = { id: 'global.errors.mandatory' }
			fieldsError[index] = rowError
		}

		if (!row[BOARD_UNAVAILABLE_FIELDS.END]) {
			rowError[BOARD_UNAVAILABLE_FIELDS.END] = { id: 'global.errors.mandatory' }
			fieldsError[index] = rowError
		}

	})

	if (fieldsError.length) {
		errors[BOARD_UNAVAILABLE_FIELDS.BOARDS] = fieldsError
	}
	return errors
}

/**
 * BoardUnavailableUpdateForm
 * @param handleSubmit
 * @param classes
 * @param boards
 * @returns {JSX.Element}
 * @constructor
 */
const BoardUnavailableUpdateForm = ({ handleSubmit, classes, boards }) => {
	const renderFieldArray = ({ fields }) => {
		return (
			fields.map((fieldName, index, fields) => {
				const concernedBoards = fields.get(index).boards.map(concernedBoard => boards.find(board => board.id === concernedBoard.id))
				return (
						<Grid key={index} container className={classes.fieldContainer}>
							<Grid item container alignItems="center" justify="space-between">
								<Typography className={classes.valueLabel}>
									<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.date.start" />
								</Typography>
								<Field
									name={`${fieldName}${BOARD_UNAVAILABLE_FIELDS.START}`}
									type="text"
									component={DateFieldCustom}
									format={normalizeDate}
									style={{ width: '40%'}}
								/>
								<Typography className={classes.valueLabel}>
									<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.date.end" />
								</Typography>
								<Field
									name={`${fieldName}${BOARD_UNAVAILABLE_FIELDS.END}`}
									type="text"
									component={DateFieldCustom}
									format={normalizeDate}
									style={{ width: '40%'}}
								/>
							</Grid>
							{concernedBoards.filter(board => board.comment).length && (
								<Grid item container direction="column">
									<Typography className={classes.commentLabel}>
										<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.commentLabel" />
									</Typography>
									<ul className={classes.ulContainer}>
										{concernedBoards.filter(board => board.comment).map(board => (
												<Typography className={classes.valueLabel}>
													<li>
														<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.commentByBoard" values={{ board: board.label, comment: board.comment }} />
													</li>
												</Typography>
											)
										)}
									</ul>
								</Grid>
							)}
							<Field
								name={`${fieldName}${BOARD_UNAVAILABLE_FIELDS.COMMENT}`}
								component={Input}
								label={<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.comment" />}
								multiline
								containerClassName={classes.container}
							/>
							<Divider className={classes.divider} style={{ marginTop: 10 }} />
						</Grid>
					)
				}
			)
		)
	}

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.form}>
				<Grid item container alignItems="center" justify="center" className={classes.rowContainer}>
					<Typography className={classes.label}>
						<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.selectedBoards" />
					</Typography>
					<Typography className={classes.boardsText}>
						{boards.map(board => board.label).join(', ')}
					</Typography>
				</Grid>
				<Divider className={classes.divider} />
				<Grid container className={classes.formBody}>
					<FieldArray
						name={BOARD_UNAVAILABLE_FIELDS.BOARDS}
						component={renderFieldArray}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

BoardUnavailableUpdateForm.propType = {
	boards: PropTypes.array,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state, { boards, ...props }) => {
	const groupedByBioAggressorsInitialValues = groupBoardsUnavailableByPeriod(boards)
	return ({
		boards,
		initialValues: {
			[BOARD_UNAVAILABLE_FIELDS.BOARDS]: groupedByBioAggressorsInitialValues
		},
		...props
	})
}


export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: BOARD_UNAVAILABLE_FORM_NAME.BOARD_UNAVAILABLE_UPDATE_FORM,
		destroyOnUnmount: true,
		enableReinitialize: true,
		validate
	}),
	withStyles(styles)
)
(BoardUnavailableUpdateForm)
