import { DateTime } from 'luxon'
import { Comparable } from '../comparable'
import { DateFormat, LocalizedDateFormat, TimeFormat } from './model/date'

export class Temporal extends Comparable {
	constructor(date) {
		super()
		this._date = date
	}

	static DISPLAY_TIMEZONE = 'Europe/Paris'
	static API_TIMEZONE = 'UTC'
	_date

	static _splitTime = (time) => {
		const [hour, minute, second, millisecond] = time.split(':').map(element => element ? Number(element) : 0)
		return {
			hour: hour ?? 0,
			minute: minute ?? 0,
			second: second ?? 0,
			millisecond: millisecond ?? 0
		}
	}

	format = () => {
	}

	toDisplayDate(format, locale) {
		locale = locale ?? 'fr'
		const date = this._date.setLocale(locale).setZone(Temporal.DISPLAY_TIMEZONE)
		switch (format) {
			case DateFormat.SHORT_DATE_2_DIGITS_2_MONTH:
				return date.toLocaleString({ day: '2-digit', month: '2-digit' })
			case DateFormat.SHORT_DATE_2_DIGITS:
				return date.toLocaleString({ day: '2-digit', month: '2-digit', year: '2-digit' })
			case DateFormat.SHORT_DATE:
				return date.toLocaleString(DateTime.DATE_SHORT)
			case DateFormat.REVERSE_SHORT_DATE:
				return date.toFormat('yyyy/MM/dd')
			case DateFormat.SHORT_DATE_WITH_DASH:
				return date.toFormat('yyyy-MM-dd')
			case LocalizedDateFormat.LONG_DATE_WITH_WEEKDAY:
				return date.toFormat('cccc dd LLLL yyyy')
			case LocalizedDateFormat.LONG_DATE:
				return date.toFormat('cccc dd/LL/yyyy')
			case LocalizedDateFormat.LONG_DATE_WITHOUT_YEAR:
				return date.toFormat('cccc dd LLLL')
			case LocalizedDateFormat.LONG_DATE_WITHOUT_YEAR_STRING:
				return date.toFormat('cccc dd/LL')
			case DateFormat.SHORT_DATETIME:
				return date.toLocaleString(DateTime.DATETIME_SHORT)
			case DateFormat.DATETIME_SHORT_WITH_SECONDS:
				return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
			case TimeFormat.TIME_24_WITH_SECONDS:
				return date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)
			case TimeFormat.TIME_ONLY:
				return date.toLocaleString(DateTime.TIME_SIMPLE).replace(':', 'h')
			case LocalizedDateFormat.WEEKDAY_ONLY:
				return date.toLocaleString({ weekday: 'long' })
			case LocalizedDateFormat.DAY_AND_MONTH:
				return date.toLocaleString({ day: '2-digit', month: 'long' })
			default:
				return date.toFormat('yyyy/MM/dd')

		}
	}

	_compareTo = (to) => {
		if (+this._date === +to._date) {
			return 0
		}
		return this._date < to._date ? -1 : 1
	}

	_diff = (to, unit) => {
		return this._date.diff(to._date, unit).values[unit]
	}

	_safe = (dateTime) => {
		if (dateTime.invalidReason) {
			console.warn('Invalid datetime provided. Explanation:', dateTime.invalidExplanation)
			throw Error(dateTime.invalidReason)
		}
		return dateTime
	}

	toString = () => {
		return this.format()
	}

	toJSON = () => {
	}
}
