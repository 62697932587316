import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FIELDS, formattedMessageBase } from '../PopinModeleTache'
import { OUI_NON } from '../../../fo/mobile/utils/constantsSuiviTache'
import Autocomplete from '../../../../components/form/Autocomplete'
import Select from '../../../../components/form/Select'
import { injectFamilleTacheValueList, injectTypeTacheValueList } from '../../../common/valueLists/valueListInjectors'
import Input from '../../../../components/form/Input'
import { displayEtape, displayItk } from '../moTacheUtils'
import { TYPE_DATE, TYPE_ENTITE_TACHE } from '../../../../utils/constants'
import AutocompleteMulti from '../../../../components/form/AutocompleteMulti'
import { MODELE_TACHE_EDITION_FORM } from '../ModeleTachePage'
import { sortObjectByLabel } from '../../../../utils/utils'
import { getMateriels } from '../../reglagesMateriels/services/reglagesMaterielsApi'

const CaracteristiquesGeneralesSubPanel = ({
	familleTacheList,
	idFerme,
	intl,
	typeEntity,
	classes,
	itk,
	typeTacheList,
	itkOptions,
	etapeAssocieeOptions,
	flagCreation,
	categorieTechnique,
	categorie
}) => {
	const getTypeEntityOptions = () => sortObjectByLabel(Object.values(TYPE_ENTITE_TACHE)
		.map(val => ({ label: intl.formatMessage({ id: `enums.typeEntity.${val}` }), value: val })))
		.map((val, idx) => <MenuItem key={idx} value={val.value}>{val.label}</MenuItem>)

	const getReglages = () => {
		return getMateriels({ idFerme, categorieTechniqueId: categorie })
			.then(materiels => {
				if (!materiels || !Array.isArray(materiels)) return [];
				return materiels.flatMap(materiel =>
					(materiel?.reglages || []).map(reglage => ({
						code: reglage?.id,
						label: `${materiel?.nom} - ${reglage?.nom}`
					}))
				);
			});
	};

	return (
		<ExpansionPanel className={classes.expandPanel} defaultExpanded={true}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				className={classes.header}

			>
				<FormattedMessage id={`${formattedMessageBase}.caracteristiquesGenerales`} />
			</ExpansionPanelSummary>
			<Grid container item justify={'flex-start'}>
				<Field
					name={FIELDS.FAMILLE.name}
					component={Autocomplete}
					containerClassName={classes.littleRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FAMILLE.name}`} />}
					fromBo
					options={
						familleTacheList.map(famille => ({
							code: famille.id,
							label: famille.label
						}))
					}
				/>
				<Field
					name={FIELDS.TYPE.name}
					component={Autocomplete}
					containerClassName={classes.littleRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TYPE.name}`} />}
					fromBo
					options={
						typeTacheList.map(typeTache => ({
							code: typeTache.id,
							label: typeTache.label
						}))
					}
				/>
				<Field
					name={FIELDS.PRIORITE.name}
					component={Input}
					containerClassName={classes.littleRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.PRIORITE.name}`} />}
					value={34}
					fromBo
				/>
			</Grid>
			<Grid container item justify={'flex-start'}>
				<Field
					name={FIELDS.TYPE_ENTITY.name}
					component={Select}
					containerClassName={classes.littleRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TYPE_ENTITY.name}`} />}
					fromBo
				>
					{getTypeEntityOptions()}
				</Field>
				{displayItk(typeEntity) && itkOptions.length > 0 ?
					<Field
						name={FIELDS.ITK.name}
						component={Autocomplete}
						containerClassName={classes.littleRow}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.ITK.name}`} />}
						options={itkOptions}
						defaultValue={itk && { label: itkOptions.find(value => value.code === itk), code: itk }}
						fromBo
					/>
					:
					<React.Fragment />
				}
				{(itk && displayEtape(typeEntity)) ?
					<Field
						name={FIELDS.ETAPE_ASSOCIEE.name}
						component={Select}
						containerClassName={classes.littleRow}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.ETAPE_ASSOCIEE.name}`} />}
						fromBo
					>
						{etapeAssocieeOptions.map((etape, index) => (<MenuItem key={index} value={etape.value}>
							{<FormattedMessage id={`enums.etape.${etape.label}`} />}
						</MenuItem>))}
					</Field>
					:
					<React.Fragment />}
			</Grid>
			<Grid container item justify={'flex-start'}>

				{
					(itk && displayEtape(typeEntity)) ?
						<Field
							name={FIELDS.FLAG_CREATION.name}
							component={Select}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_CREATION.name}`} />}
							fromBo
						>
							{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
								{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`} />}
							</MenuItem>)}
						</Field>
						: <React.Fragment />
				}

			</Grid>
			{
				flagCreation === OUI_NON.OUI.value
					? <Grid container item justify={'flex-start'}>
						<Field
							name={FIELDS.TIMELINE.name}
							component={Input}
							type="number"
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TIMELINE.name}`} />}
							fromBo
						/>
						<Field
							name={FIELDS.DEBUT_FIN.name}
							component={Select}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.DEBUT_FIN.name}`} />}
							fromBo
						>
							<MenuItem key={1} value={TYPE_DATE.DEBUT}>
								{<FormattedMessage id={`${formattedMessageBase}.debutEtape`} />}
							</MenuItem>
							<MenuItem key={2} value={TYPE_DATE.FIN}>
								{<FormattedMessage id={`${formattedMessageBase}.finEtape`} />}
							</MenuItem>
						</Field>
						<Field
							name={FIELDS.FLAG_ETAPE.name}
							component={Select}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_ETAPE.name}`} />}
							fromBo
						>
							{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
								{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`} />}
							</MenuItem>)}
						</Field>
					</Grid>
					: <React.Fragment />
			}
			<Grid container item justify={'flex-start'}>
				{categorieTechnique?.length > 0 && <Field
					name={FIELDS.CATEGORIE_TECHNIQUE.name}
					component={Autocomplete}
					containerClassName={classes.littleRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.CATEGORIE_TECHNIQUE.name}`} />}
					fromBo
					options={
						categorieTechnique?.map(categorie => ({
							code: categorie.id,
							label: `${categorie.nom} (${categorie.code})`
						}))
					}
				/>}
				{categorie && <Field
					name={FIELDS.REGLAGES.name}
					component={AutocompleteMulti}
					containerClassName={classes.mediumRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.REGLAGES.name}`} />}
					fromBo
					forceGetOptions
					formName={MODELE_TACHE_EDITION_FORM}
					getOptions={() => getReglages()}
				/>}
			</Grid>
		</ExpansionPanel>
	)
}

CaracteristiquesGeneralesSubPanel.propType = {
	typeEntity: PropTypes.string,
	classes: PropTypes.object,
	typeTacheList: PropTypes.object,
	itk: PropTypes.number,
	idFerme: PropTypes.number,
	itkOptions: PropTypes.array,
	etapeAssocieeOptions: PropTypes.array,
	flagCreation: PropTypes.bool,
	categorieTechnique: PropTypes.array,
	categorie: PropTypes.string
}

export default compose(
	injectIntl,
	injectFamilleTacheValueList,
	injectTypeTacheValueList
)(CaracteristiquesGeneralesSubPanel)
