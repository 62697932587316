import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import WarningRounded from '@material-ui/icons/WarningRounded'
import { LocalDateTime } from '../../../utils/date/local-date-time'
import { useSolverStatusContext } from '../../../modules/fo/besoinsProduction/SolverStatusContextProvider'
import { PAGE, usePageContext } from '../PageContext'
import { ONGLETS_BESOINS_PRODUCTION, PROFILS, ROLE_WEIGHT, SOLVER_STATUS } from '../../../utils/constants'
import AlerteBarContent from './AlerteBarContent'
import { hasRole } from '../../security/RoleChecker'
import * as userSelectors from '../../../modules/common/user/services/userSelectors'
import { useBesoinsProductionContext } from '../../../modules/fo/besoinsProduction/BesoinsProductionProvider'
import { hasAccess } from '../../security/AccessChecker'

const AlerteBar = ({ userAuthorities }) => {
	const { solverInfos } = useSolverStatusContext()
	const { page } = usePageContext()
	const { onglet } = useBesoinsProductionContext()

	const loading = !solverInfos
	const isTDP = page === PAGE.TOUR_PLAINE
	const isBesoinProduction = page === PAGE.BESOINS_PRODUCTION
	const isSolution = onglet === ONGLETS_BESOINS_PRODUCTION.RESULTATS
	const isVueListe = page === PAGE.LISTE
	const isPlanning = page === PAGE.PLANNING
	const isPlanningOrVueListeOrTDB = isPlanning || isVueListe || (page === PAGE.TDB)
	const isBo = page === PAGE.BO

	const hasSolveurEnCours = (solverInfos?.status === SOLVER_STATUS.PREPARING) || (solverInfos?.status === SOLVER_STATUS.RUNNING)

	const isSolveurOutdated = solverInfos?.status === SOLVER_STATUS.OUTDATED

	const dateApprobationSolutionCulture = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateApprobationSolutionCulture)
	const dateEnregistrementBesoinsDeProduction = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateEnregistrementBesoinsDeProduction)
	const dateLancementSolverCulture = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateLancementSolverCulture)
	const dateValidationTourDePlaine = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateValidationTourDePlaine)
	const dateFinSauvegarde = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateFinSauvegarde)
	const dateValidationPlanning = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateValidationPlanning)

	// Les besoins de production ont été modifiées, une nouvelle solution a été générée mais elle n'a pas encore été approuvée
	const solutionNonApprouveeApresMajBdp = useMemo(() => {
		return dateLancementSolverCulture?.gt(dateEnregistrementBesoinsDeProduction) && dateEnregistrementBesoinsDeProduction?.gt(dateApprobationSolutionCulture)
	}, [dateLancementSolverCulture, dateEnregistrementBesoinsDeProduction, dateApprobationSolutionCulture])

	// La dernière solution générée et approuvée est caduque car les besoins de production ont été mis à jour
	const solutionCaduqueCarBdpModifiees = useMemo(() => {
		return (dateEnregistrementBesoinsDeProduction?.gt(dateApprobationSolutionCulture) && dateApprobationSolutionCulture?.gte(dateLancementSolverCulture))
			|| (dateEnregistrementBesoinsDeProduction?.gt(dateApprobationSolutionCulture) && dateEnregistrementBesoinsDeProduction?.gte(dateLancementSolverCulture))
	}, [dateLancementSolverCulture, dateEnregistrementBesoinsDeProduction, dateApprobationSolutionCulture])

	// Le tour de plaine a été validée, une nouvelle solution a été générée mais elle n'a pas encore été approuvée
	const solutionNonApprouveApresMajTdp = useMemo(() => {
		return dateLancementSolverCulture?.gt(dateValidationTourDePlaine) && dateValidationTourDePlaine?.gt(dateApprobationSolutionCulture)
	}, [dateLancementSolverCulture, dateValidationTourDePlaine, dateApprobationSolutionCulture])

	// La dernière solution générée et approuvée est caduque car le tour de plaine a été mis à jour
	const solutionCaduqueCarTdpMaj = useMemo(() => {
		return (dateValidationTourDePlaine?.gt(dateApprobationSolutionCulture) && dateApprobationSolutionCulture?.gte(dateLancementSolverCulture))
			|| (dateValidationTourDePlaine?.gt(dateApprobationSolutionCulture) && dateValidationTourDePlaine?.gte(dateLancementSolverCulture))
	}, [dateLancementSolverCulture, dateValidationTourDePlaine, dateApprobationSolutionCulture])

	// La dernière solution générée est caduque car le planning des cultures a été mis à jour manuellement
	const solutionCaduqueCarPlanningCultureMaj = useMemo(() => {
		return dateValidationPlanning.gt(dateLancementSolverCulture) && dateValidationPlanning.gt(dateFinSauvegarde)
	}, [dateValidationPlanning, dateFinSauvegarde, dateLancementSolverCulture])

	// Les besoins de production ET le tour de plaine ont été mis à jour après le lancement d'un solveur
	const bdpETTdpMajApresLancementSolverCulture = useMemo(() => {
		return dateEnregistrementBesoinsDeProduction?.gt(dateLancementSolverCulture) && dateValidationTourDePlaine.gt(dateLancementSolverCulture)
	}, [dateValidationTourDePlaine, dateEnregistrementBesoinsDeProduction, dateApprobationSolutionCulture])

	// datePC > dateS     et    datePC > dateFinSauvegarde
	const isSauvegardePlanningCultureEnCours = useMemo(() => {
		return dateApprobationSolutionCulture.gt(dateLancementSolverCulture) && dateApprobationSolutionCulture.gt(dateFinSauvegarde)
	}, [dateApprobationSolutionCulture, dateLancementSolverCulture, dateFinSauvegarde])

	if (!loading) {
		let alertes = []

		// les barres d'alerte ne sont affichés qu'aux chef de culture et aux superviseurs car ce sont les seuls à avoir accès aux besoins de production
		if (hasAccess(PROFILS.CHEF, userAuthorities) || hasAccess(PROFILS.SUPERVISEUR, userAuthorities)) {
			/**
			 * écrans concernés : besoin de production (onglet recueil), planning (vue liste ou planning), tableau de bord/accueil, tour de plaine
			 * apparaît quand : une solution a été générée mais pas approuvée, solution n'étant pas caduque et le solveur n'est pas en cours de génération de solution
			 */
			if (((isBesoinProduction && !isSolution) || isPlanningOrVueListeOrTDB || isTDP)
				&& (solutionNonApprouveApresMajTdp || solutionNonApprouveeApresMajBdp)
				&& !solutionCaduqueCarBdpModifiees
				&& !hasSolveurEnCours) {
				alertes.push(
					<AlerteBarContent
						messageId="solutionNonApprouve"
						buttonMessageId="buttonSolution"
						link={'/besoins-production?onglet=resultats'}
					/>
				)
			}

			/**
			 * écrans concernées : tour de plaine, planning (vue liste ou planning), tableau de bord/accueil
			 * apparaît quand : une solution est caduque car les besoins de production ont été modifiées et le solveur n'est pas en cours de génération de solution
			 */
			if ((isTDP || isPlanningOrVueListeOrTDB) && solutionCaduqueCarBdpModifiees && !hasSolveurEnCours) {
				alertes.push(
					<AlerteBarContent
						messageId="productionModifie"
						buttonMessageId="buttonModifie"
						link="/besoins-production?onglet=recueil"
					/>
				)

			}

			/**
			 * écrans concernées : besoin de production (onglet solution), planning (vue liste ou planning), tableau de bord/accueil
			 * apparaît quand : une solution est caduque car le tour de plaine a été mis à jour et le solveur n'est pas en cours de génération de solution
			 */
			if (((isBesoinProduction && isSolution) || isPlanningOrVueListeOrTDB) && solutionCaduqueCarTdpMaj && !hasSolveurEnCours) {
				alertes.push(
					<AlerteBarContent
						messageId="tourDePlaineTermine"
						buttonMessageId="buttonModifie"
						link="/besoins-production?onglet=recueil"
					/>
				)
			}

			/**
			 * écrans concernées : besoin de production (onglet solution)
			 * apparaît quand : une solution est caduque car le planning des cultures a été mis à jour manuellement et
			 * 			le solveur n'est pas en cours de génération de solution
			 */
			if ((isBesoinProduction && isSolution) && solutionCaduqueCarPlanningCultureMaj && !hasSolveurEnCours) {
				alertes.push(
					<AlerteBarContent
						messageId="planningRecent"
						buttonMessageId="buttonModifie"
						link="/besoins-production?onglet=recueil"
					/>
				)
			}

			/**
			 * écrans concernées : planning (vue liste ou planning), tableau de bord/accueil
			 * apparaît quand : les besoins de production et le tour de plaine ont été mis à jour après le lancement d'un solveur et
			 * 		le solveur n'est pas en cours de génération de solution
			 */
			if (isPlanningOrVueListeOrTDB && bdpETTdpMajApresLancementSolverCulture && !hasSolveurEnCours) {
				alertes = [] // Clear les alertes pour afficher que celle-ci
				alertes.push(
					<AlerteBarContent
						messageId="productionModifie"
						buttonMessageId="buttonModifie"
						link="/besoins-production?onglet=recueil"
					/>
				)
			}
		}

		/**
		 * écrans concernées : tous
		 * apparaît quand : le solveur est en cours d'enregistrement de la solution
		 */
		if (isSauvegardePlanningCultureEnCours && !isSolveurOutdated) {
			alertes.push(
				<AlerteBarContent
					Icon={WarningRounded}
					messageId="enregistrementEnCours"
				/>
			)
		}

		/**
		 * écrans concernées : bo, tour de plaine, besoin de production (onglets recueil et solution), planning, vue liste
		 * apparaît quand : le solveur est en cours de génération de solution
		 */
		if ((isBo || isTDP || isBesoinProduction || isPlanning || isVueListe) && hasSolveurEnCours) {
			// si l'utilisateur est un superviseur alors on affiche la ferme concernée, sinon pas
			if (hasRole(ROLE_WEIGHT.ROLE_SUPERVISEUR, userAuthorities)) {
				solverInfos.fermeEnCours.forEach(f => {
					alertes.push(
						<AlerteBarContent
							Icon={WarningRounded}
							messageId={'solveurEnCoursSuperviseur'}
							messageValues={{ ferme: f }}
						/>
					)
				})
			} else {
				alertes.push(
					<AlerteBarContent
						Icon={WarningRounded}
						messageId={'solveurEnCours'}
					/>
				)
			}
		}

		return <>{alertes.map(alerte => alerte)}</>
	}
	return null
}

const mapStateToProps = (state) => ({
	userAuthorities: userSelectors.getAuthorities(state)
})

export default connect(mapStateToProps)(AlerteBar)
