import React from 'react'
import PropTypes from 'prop-types'
import { getStyles } from 'isotope-client'
import { useGestionTacheContext } from '../../GestionTacheProvider'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { ReactComponent as Gauge } from '../../../../../icons/gauge.svg'
import { formatDuration } from '../../../../../utils/utils'
import classNames from 'classnames'
import Selected from '@material-ui/icons/LibraryAddCheck'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import { FormattedMessage, useIntl } from 'react-intl'
import * as selectors from '../../services/selectors'
import { connect } from 'react-redux'
import SelectJour from '../ToolBar/SelectJour'
import ToolBarButton from '../../../planning/component/ToolBar/ToolBarButton'
import FilterIcon from '../../../../../components/icon/FilterIcon'
import { injectPlanificationFilter } from '../../injectors/filterInjector'
import { compose } from 'redux'
import LegendeTaches from '../LegendeTaches'
import { TYPE_DIALOG } from '../../../../../utils/constants'
import QuestionIcon from '../../../../../components/icon/QuestionIcon'
import { DialogContext } from '../../../../../components/layout/dialog'

const useStyles = makeStyles(theme => getStyles({
	root: {
		height: 105,
		fontFamily: 'Lato'
	},
	cell: {
		backgroundColor: theme.palette.primary.light
	},
	totauxContainer: {
		width: 960,
		height: 48
	},
	selectedTaches: {
		width: 317
	},
	capaciteTotale: {
		width: 317
	},
	chargeTotale: {
		width: 292
	},
	iconContainer: {
		width: 60
	},
	icon: {
		fill: theme.palette.primary.main
	},
	iconBig: {
		width: 36,
		height: 36
	},
	kpiContainer: {
		width: 'unset',
		paddingLeft: 5
	},
	tachesSelectionneesDuree: {
		fontWeight: 'bold',
		fontSize: 16
	},
	total: {
		fontWeight: 'bold',
		fontSize: 20,
		color: theme.palette.primary.main
	},
	error: {
		color: theme.palette.error.main
	},
	label: {
		fontSize: 13
	},
	title: {
		fontStyle: 'italic',
		fontSize: 14,
		alignContent: 'center'
	}
}))

const HeaderOrdonnancement = ({ selectedTaches, setFilterOpen }) => {
	const classes = useStyles()
	const { headerData, jourSelection, semaineSelection } = useGestionTacheContext()
	const { openDialog } = React.useContext(DialogContext)
	const intl = useIntl()

	const chargeTachesSelectionnees = React.useMemo(
		() => {
			return selectedTaches.reduce((acc, tache) => acc.add(moment.duration(tache.chargeTravail)), moment.duration(0))
		},
		[selectedTaches]
	)
	const isError = React.useMemo(() => moment.duration(headerData.capaciteTotale).asMinutes() < moment.duration(headerData.chargeTravailTotale).asMinutes(), [headerData])

	return <Grid container direction="column" className={classes.root}>
		<Grid container item justify="space-around" direction="row">
			<Grid container item xs={6}>
				<Typography variant={'caption'} className={classes.title}>
					<FormattedMessage id="gestionTaches.ecranOrdonnancement.header.title" />
				</Typography>
			</Grid>
			<Grid container item xs={6} justify="flex-end" direction="row">
				<SelectJour semaineSelection={semaineSelection} jourSelection={jourSelection} />
				<ToolBarButton type="filter" onClick={setFilterOpen}>
					<FilterIcon color="currentColor" />
				</ToolBarButton>
				<IconButton className={classes.icon} onClick={() => openDialog({ id: 'legende.title' }, <LegendeTaches />, [], TYPE_DIALOG.DETAILS, 'actions.close')}>
					<QuestionIcon />
				</IconButton>
			</Grid>
		</Grid>
		<Grid container item>
			<Grid item container className={classes.totauxContainer} wrap="nowrap" justify="space-around">
				<Grid item container className={classNames(classes.cell, classes.selectedTaches)} alignItems="center"
					  wrap="nowrap">
					<Grid item container className={classes.iconContainer} alignContent="center" justify="center">
						<Selected className={classes.icon} />
					</Grid>
					<Grid item container direction="column" className={classes.kpiContainer}>
					<span
						className={classes.tachesSelectionneesDuree}>{chargeTachesSelectionnees.format('h[h]mm', { trim: false })}</span>
						<FormattedMessage id="gestionTaches.ecranAffectations.header.tachesSelectionnees">
							{text => <span className={classes.label}>{text}</span>}
						</FormattedMessage>
					</Grid>
				</Grid>
				<Grid item container className={classNames(classes.cell, classes.capaciteTotale)} alignItems="center"
					  wrap="nowrap">
					<Grid item container className={classes.iconContainer} alignContent="center" justify="center">
						<Gauge className={classNames(classes.icon, classes.iconBig)} />
					</Grid>
					<Grid item container direction="column" className={classes.kpiContainer}>
						<span className={classes.total}>{formatDuration(headerData.capaciteTotale)}</span>
						<FormattedMessage
							id="gestionTaches.ecranAffectations.header.capaciteTotale"
							values={{
								jour: intl.formatDate(jourSelection, { weekday: 'long' })
							}}
						>
							{text => <span className={classes.label}>{text}</span>}
						</FormattedMessage>
					</Grid>
				</Grid>
				<Grid item container className={classNames(classes.cell, classes.chargeTotale)} alignItems="center"
					  wrap="nowrap">
					<Grid item container className={classes.iconContainer} alignContent="center" justify="center">
						<TimelapseIcon className={classNames(classes.icon, classes.iconBig)} />
					</Grid>
					<Grid item container direction="column" className={classes.kpiContainer}>
					<span
						className={classNames(classes.total, { [classes.error]: isError })}>{formatDuration(headerData.chargeTravailTotale)}</span>
						<FormattedMessage
							id="gestionTaches.ecranAffectations.header.chargeTotale"
							values={{
								jour: intl.formatDate(jourSelection, { weekday: 'long' })
							}}
						>
							{text => <span className={classes.label}>{text}</span>}
						</FormattedMessage>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
}

HeaderOrdonnancement.propTypes = {
	selected: PropTypes.array
}

const mapStateToProps = (state, { selected }) => ({
	selectedTaches: selectors.extractSelectedTaches(selected)(state)
})

export default compose(
	connect(mapStateToProps),
	injectPlanificationFilter
)(HeaderOrdonnancement)
