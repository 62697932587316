import {Grid, MenuItem, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {ACTIONS_KEY, EMPTY_PLACEHOLDER, MOVE_TASK_FIELD, PANEL_FORM} from '../../tool/tourPlaine.constants'
import Input from '../../../../../components/form/Input'
import {capitalize, normalizeDate} from '../../../../../utils/utils'
import {CHOIX_DEPLACEMENT} from '../../../../../utils/constants'
import Radio from '../../../../../components/form/Radio'
import Detail from '../../../planning/component/detail/Detail'
import moment from 'moment'
import PlantOneIcon from '../../../../../components/icon/PlantOneIcon'
import PickingIcon from '../../../../../components/icon/PickingIcon'
import {injectTypeTacheValueList} from '../../../../common/valueLists/valueListInjectors'
import {connect} from 'react-redux'
import PlantIcon from '../../../../../components/icon/PlantIcon'
import ImplantationIcon from '../../../../../components/icon/ImplantationIcon'
import RecolteIcon from '../../../../../components/icon/RecolteIcon'
import DateFieldCustom from '../../../../../components/form/DateFieldCustom'
import {LocalDate} from "../../../../../utils/date/local-date";
import {DateFormat, DateUnit, LocalizedDateFormat} from "../../../../../utils/date/model/date";
import ScheduleIcon from "../../../../../components/icon/ScheduleIcon";
import Typography from "@material-ui/core/Typography/index";
import Select from "../../../../../components/form/Select";

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		width: '100%',
		padding: 25,
		rowGap: 25
	},
	radioText: {
		marginBottom: 10,
		marginLeft: 10
	},
	radio: {
		width: '100%',
		'& > div': {
			'& > label': {
				width: 170
			}
		}
	},
	details: {
		width: 'initial !important'
	},
	planifInput: {
		'& .MuiSelect-selectMenu': {
			paddingTop: 15,
			width: 150
		}
	}
})

const validate = (values) => {

	const errors = {}
	const requiredFields = [
		MOVE_TASK_FIELD.DATE_CHOICE,
		MOVE_TASK_FIELD.DATE,
		MOVE_TASK_FIELD.NB_WEEK
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = {id: 'global.errors.mandatory'}
		}
	})

	if (values[MOVE_TASK_FIELD.NB_WEEK] && ((values[MOVE_TASK_FIELD.NB_WEEK] === 0) || (!Number.isInteger(parseFloat(values[MOVE_TASK_FIELD.NB_WEEK]))))) {
		errors[MOVE_TASK_FIELD.NB_WEEK] = {id: 'listAssolement.forms.contentError.nbSemaineInvalide'}
	}

	return errors
}

/**
 * MoveTaskForm
 * @param handleSubmit
 * @param classes
 * @param inputLabel
 * @returns {JSX.Element}
 * @constructor
 */
const MoveForm = ({handleSubmit, classes, type, items, typeTacheList, dateChoice, isFromPlanification}) => {

	const taskList = () => {
		return <ul>
			{
				items.map((item) => {
					const task = typeTacheList.find(typeTache => typeTache.code === (item.task ?? item.type))
					return (
						<li style={{paddingBottom: '5px'}} key={item.id}>
							{isFromPlanification ? <FormattedMessage id={'tourPlaine.form.moveTask.sectionOne.body.taskPlanned'}
																	 values={{
																		 task: task.label,
																		 date: LocalDate.fromAPI(item.date).toDisplayDate(DateFormat.SHORT_DATE),
																		 crop: item.culture,
																		 board: item.lieu,
																		 size: item.surface
																	 }}
							/> : <FormattedMessage id={'tourPlaine.form.moveTask.sectionOne.body.taskPlanned'}
												   values={{
													   task: task.label,
													   date: LocalDate.fromAPI(item.plannedDate).toDisplayDate(DateFormat.SHORT_DATE),
													   crop: item.crop.name,
													   board: item.board.name,
													   size: item.board.surface
												   }}
							/>}
						</li>
					)
				})
			}
		</ul>
	}
	const cropList = () => {
		return <ul>
			{
				items.map((item) => {
					return (
						<li style={{paddingBottom: '5px'}} key={item.id}>
							{item.crop.name} ({item.board.name} - {item.board.surface}m²)
							{
								item.end !== EMPTY_PLACEHOLDER && (
									<>
										<br/>
										<FormattedMessage id={'tourPlaine.form.moveTask.sectionOne.body.harvestBetween'}
														  values={{
															  start: item.startHarvestDate ? moment(item.startHarvestDate, DateFormat.YYYY_MM_DD).startOf('isoWeek').format('DD/MM/YYYY') : EMPTY_PLACEHOLDER,
															  end: item.endHarvestDate ? moment(item.endHarvestDate, DateFormat.YYYY_MM_DD).endOf('isoWeek').format('DD/MM/YYYY') : EMPTY_PLACEHOLDER
														  }}
										/>
									</>
								)
							}
						</li>
					)
				})
			}
		</ul>
	}

	const firstSection = () => {
		if (type === ACTIONS_KEY.MOVE_TASK) {
			return ({
				icon: <PickingIcon width={25} height={25}/>,
				title: <FormattedMessage id="tourPlaine.form.moveTask.sectionOne.title.task"/>,
				content: taskList()
			})
		}
		return ({
			icon: <PlantOneIcon width={25} height={25}/>,
			title: <FormattedMessage id="tourPlaine.form.moveTask.sectionOne.title.step"/>,
			content: cropList()
		})
	}

	const secondSection = () => {
		if (isFromPlanification) {
			const now = LocalDate.now()
			const days = []
			for (let i = -7; i <= 13; i++) {
				days.push(now.startOf(DateUnit.week).plus(i))
			}
			return ({
				content: <Grid container alignItems="center" style={{gap: 20}}>
					<Typography>
						<FormattedMessage id="gestionTaches.ecranPlanification.move.field"/>
					</Typography>
					<Field name={MOVE_TASK_FIELD.DATE}
						   component={Select}
						   containerClassName={classes.planifInput}>
						{
							days.map(day =>
								<MenuItem key={day.toString()} value={day.format(DateFormat.SHORT_DATE_WITH_DASH)}>
									{capitalize(day.toDisplayDate(LocalizedDateFormat.LONG_DATE))}
								</MenuItem>
							)
						}
					</Field>
				</Grid>,
				icon: <ScheduleIcon width={25} height={25}/>,
				title: <FormattedMessage id="tourPlaine.form.moveTask.sectionTwo.title.moveTaskPlanif"/>
			})
		}
		const section = {
			content:
				<>
					<Grid>
						<Field name={MOVE_TASK_FIELD.DATE_CHOICE} component={Radio}
							   choices={[{
								   value: CHOIX_DEPLACEMENT.DEPLACER,
								   label: <FormattedMessage id={'listAssolement.forms.checkBoxes.deplacerLe'}/>
							   }, {
								   value: CHOIX_DEPLACEMENT.DECALER,
								   label: <FormattedMessage id={'listAssolement.forms.checkBoxes.decalerDe'}/>
							   }]}
							   row
							   style={{justifyContent: 'space-between'}}
							   containerClass={classes.radio}
						/>
					</Grid>
					<Grid>
						{
							dateChoice === CHOIX_DEPLACEMENT.DEPLACER && <Field
								name={MOVE_TASK_FIELD.DATE}
								component={DateFieldCustom}
								format={normalizeDate}
								type="text"
								style={{width: '48%'}}
								showFooterInfo
							/>
						}
						{
							dateChoice === CHOIX_DEPLACEMENT.DECALER && <Field
								required
								name={MOVE_TASK_FIELD.NB_WEEK}
								component={Input}
								type="number"
								adornment={<FormattedMessage id={'listAssolement.forms.content.adornment'}/>}
								style={{width: '48%', marginLeft: '52%'}}
							/>
						}
					</Grid>
				</>
		}

		if (type !== ACTIONS_KEY.MOVE_TASK) {
			const getStepIcon = () => {
				if (type === ACTIONS_KEY.MOVE_TO_IMPLANT_STEP) {
					return <ImplantationIcon/>
				}
				if (type === ACTIONS_KEY.MOVE_TO_HARVEST_STEP) {
					return <RecolteIcon/>
				}

				return <PlantIcon/>
			}

			return ({
				...section,
				icon: getStepIcon(),
				title: <FormattedMessage id={`tourPlaine.form.moveTask.sectionTwo.title.${type}`}/>
			})
		}
		return section
	}


	const sections = [
		firstSection(),
		secondSection()
	]

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Detail sections={sections} containerClassName={classes.details}/>
		</form>
	)

}

MoveForm.propType = {
	items: PropTypes.object,
	type: PropTypes.string,
	step: PropTypes.string,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	typeTacheList: PropTypes.object,
	dateChoice: PropTypes.string
}

const selector = formValueSelector(PANEL_FORM.MOVE_TASK)
const mapStateToProps = (state, {items, step, isFromPlanification, ...props}) => ({
	dateChoice: selector(state, MOVE_TASK_FIELD.DATE_CHOICE),
	initialValues: !isFromPlanification ? {
		[MOVE_TASK_FIELD.IDS_LIST]: items.map((item) => item.id),
		[MOVE_TASK_FIELD.DATE_CHOICE]: CHOIX_DEPLACEMENT.DEPLACER,
		[MOVE_TASK_FIELD.STEP]: step
	} : {
		[MOVE_TASK_FIELD.DATE]: LocalDate.now().format(DateFormat.SHORT_DATE_WITH_DASH)
	},
	items,
	...props
})

export default compose(
	connect(mapStateToProps),
	injectTypeTacheValueList,
	reduxForm({
		form: PANEL_FORM.MOVE_TASK,
		destroyOnUnmount: true,
		validate
	}),
	withStyles(styles)
)
(MoveForm)
