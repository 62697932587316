import Grid from '@material-ui/core/Grid'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import moment from 'moment'
import DateFieldCustom from '../../../../../components/form/DateFieldCustom'
import { normalizeDate } from '../../../../../utils/utils'


const styles = () => getStyles({
	form: {
		alignItems: 'center',
		justifyContent: 'center'
	},
	row: {
		alignItems: 'center',
		justifyContent: 'space-evenly'
	},
	label: {
		fontWeight: 'bold'
	}
})

export const EXPORT_SUIVI_AVANCEMENT_FORM = 'EXPORT_SUIVI_AVANCEMENT_FORM'

const validate = values => {
	const errors = {}
	if (!values.debut) {
		errors.debut = <FormattedMessage id="suiviAvancement.toolbar.export.erreurDebut" />
	}
	if (!values.fin) {
		errors.fin = <FormattedMessage id="suiviAvancement.toolbar.export.erreurFin" />
	}
	if (values.debut && values.fin) {
		if (moment(values.debut) > moment(values.fin)) {
			errors.debut = <FormattedMessage id="suiviAvancement.toolbar.export.erreurDebutApresFin" />
		}
	}
	return errors
}

const PopinExport = ({ classes, handleSubmit }) => {

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid container>
				<Grid container className={classes.row}>
					<Grid item>
						<label className={classes.label}><FormattedMessage id="suiviAvancement.toolbar.export.debut" /></label>
					</Grid>
					<Grid item>
						<Field
							name="debut"
							component={DateFieldCustom}
							format={normalizeDate}
							type="text"
							style={{ width: 150 }}
						/>
					</Grid>
				</Grid>
				<Grid container className={classes.row}>
					<Grid item>
						<label className={classes.label}><FormattedMessage id="suiviAvancement.toolbar.export.fin" /></label>
					</Grid>
					<Grid item>
						<Field
							name="fin"
							component={DateFieldCustom}
							format={normalizeDate}
							type="text"
							style={{ width: 150 }}
						/>
					</Grid>
				</Grid>
			</Grid>
		</form>

	)
}

export default compose(
	withStyles(styles),
	reduxForm({
		form: EXPORT_SUIVI_AVANCEMENT_FORM,
		validate
	})
)(PopinExport)
