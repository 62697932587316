/**
 * Enum référençant les différents formats d'affichage de la date indépendamment de la locale.
 */
export const DateFormat = {
	/**
	 * 31/12/23
	 */
	SHORT_DATE_2_DIGITS: 'SHORT_DATE_2_DIGITS',
	/**
	 * 31/12/2023
	 */
	SHORT_DATE: 'SHORT_DATE',
	/**
	 * 2023/12/31
	 */
	REVERSE_SHORT_DATE: 'REVERSE_SHORT_DATE',
	/**
	 * 2023-12-31
	 */
	SHORT_DATE_WITH_DASH: 'SHORT_DATE_WITH_DASH',
	/**
	 * 31/12/23 23:59
	 */
	SHORT_DATETIME: 'SHORT_DATETIME',
	/**
	 * 31/12/2023 23:59:59
	 */
	DATETIME_SHORT_WITH_SECONDS: 'DATETIME_SHORT_WITH_SECONDS',
	/**
	 * 31/12
	 */
	SHORT_DATE_2_DIGITS_2_MONTH: 'SHORT_DATE_2_DIGITS_2_MONTH'
}

/**
 * Enum référençant les différents formats d'affichage de la date en fonction d'une locale. <br>
 * Par défaut, celle du navigateur
 */
export const LocalizedDateFormat = {
	/**
	 * Sunday 31 December 2023
	 * @default Locale du navigateur
	 */
	LONG_DATE_WITH_WEEKDAY: 'LONG_DATE_WITH_WEEKDAY',
	/**
	 * Sunday 31/12/2023
	 * @default Locale du navigateur
	 */
	LONG_DATE: 'LONG_DATE',
	/**
	 * 31 décembre
	 * @default Locale du navigateur
	 */
	DAY_AND_MONTH: 'DAY_AND_MONTH',
	/**
	 * dimanche
	 * @default Locale du navigateur
	 */
	WEEKDAY_ONLY: 'WEEKDAY_ONLY',
	/**
	 * Sunday 31 December
	 * @default Locale du navigateur
	 */
	LONG_DATE_WITHOUT_YEAR: 'LONG_DATE_WITHOUT_YEAR',
	/**
	 * Sunday 31/12
	 * @default Locale du navigateur
	 */
	LONG_DATE_WITHOUT_YEAR_STRING: 'LONG_DATE_WITHOUT_YEAR_STRING'
}

/**
 * Enum référençant les différents formats d'affichage du temps.
 */
export const TimeFormat = {
	TIME_ONLY: 'TIME_ONLY',
	TIME_24_WITH_SECONDS: 'TIME_24_WITH_SECONDS'
}

/**
 * Enum référençant les types de {@link DateTimeUnit} impactant la date.
 */
export const DateUnit = {
	year: 'year',
	quarter: 'quarter',
	month: 'month',
	week: 'week',
	day: 'day'
}

/**
 * Enum référençant les types de {@link DateTimeUnit} impactant le temps.
 */
export const TimeUnit = {
	hour: 'hour',
	minute: 'minute',
	second: 'seconde'
}
