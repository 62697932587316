import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import Detail from '../detail/Detail'
import Grid from '@material-ui/core/Grid'
import CultureIcon from '../../../../../components/icon/CultureIcon'
import { listeCulturesConcerneesDetailsImplantation, listeCulturesConcerneesDetailsRecolte, normalizeDate } from '../../../../../utils/utils'
import { FormattedMessage } from 'react-intl'
import Radio from '../../../../../components/form/Radio'
import { CHOIX_DEPLACEMENT, ETAPE_TYPE } from '../../../../../utils/constants'
import { compose } from 'redux'
import Input from '../../../../../components/form/Input'
import { formValueSelector, reduxForm, Field } from 'redux-form'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import { connect } from 'react-redux'
import ImplantationIcon from '../../../../../components/icon/ImplantationIcon'
import moment from 'moment'
import RecolteIcon from '../../../../../components/icon/RecolteIcon'
import DateFieldCustom from '../../../../../components/form/DateFieldCustom'

export const DEPLACEMENT_ETAPE_FORM = 'DEPLACEMENT_ETAPE_FORM'

const styles = () => getStyles({
	container: {
		margin: '1.5em'
	},
	radioText: {
		marginBottom: 10,
		marginLeft: 10
	},
	radio: {
		width: '100%',
		'& > div': {
			'& > label': {
				width: 170
			}
		}
	},
	form: {
		width: '100%',
		margin: 15
	},
	details: {
		width: 'initial !important'
	}
})

const formattedMessageMenuBase = 'planning.cultureForm'

const validate = (values, { campagne }) => {

	const monthsExterCampagne = 6
	const errors = {}
	const requiredFields = [
		'choixDate',
		'date',
		'nbSemaines'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = { id: `${formattedMessageMenuBase}.errors.mandatory` }
		}
	})

	if ((values.nbSemaines === 0) || (!Number.isInteger(parseFloat(values.nbSemaines)))) {
		errors['nbSemaines'] = { id: 'listAssolement.forms.contentError.nbSemaineInvalide' }
	}

	if (!moment(values.date).isBetween(moment(campagne.dateDebut).subtract(monthsExterCampagne, 'months'), moment(campagne.dateFin).add(monthsExterCampagne, 'months'))) {
		errors['date'] = { id: 'listAssolement.forms.contentError.deplacementCampagneSuivante' }
	}

	return errors
}

const DeplacerEtape = ({ selectedAssolements, handleSubmit, classes, choixDate, etapeType }) => {

	const sections = [
		{
			icon: <CultureIcon />,
			title: <FormattedMessage id="listAssolement.forms.h2.deplacementEtape" />,
			content: etapeType === ETAPE_TYPE.IMPLANTATION
				? <ul>{listeCulturesConcerneesDetailsImplantation(selectedAssolements)}</ul>
				: <ul>{listeCulturesConcerneesDetailsRecolte(selectedAssolements)}</ul>
		}, {
			icon: etapeType === ETAPE_TYPE.IMPLANTATION
				? <ImplantationIcon />
				: <RecolteIcon />,
			title: etapeType === ETAPE_TYPE.IMPLANTATION
				? <FormattedMessage id="listAssolement.forms.h2.etapeImplantation" />
				: <FormattedMessage id="listAssolement.forms.h2.etapeRecolte" />,
			content:
				<React.Fragment>
					<Grid>
						<Field name='choixDate' component={Radio}
						       choices={[{
							       value: CHOIX_DEPLACEMENT.DEPLACER,
							       label: <FormattedMessage id={'listAssolement.forms.checkBoxes.deplacerLe'} />
						       }, {
							       value: CHOIX_DEPLACEMENT.DECALER,
							       label: <FormattedMessage id={'listAssolement.forms.checkBoxes.decalerDe'} />
						       }]}
						       row
						       style={{ justifyContent: 'space-between' }}
						       containerClass={classes.radio}
						/>
					</Grid>

					<Grid>
						{choixDate === CHOIX_DEPLACEMENT.DEPLACER && <Field
							name="date"
							component={DateFieldCustom}
							format={normalizeDate}
							type="text"
							style={{ width: '48%', marginRight: '50%' }}
						/>}

						{choixDate === CHOIX_DEPLACEMENT.DECALER && <Field
							required
							name="nbSemaines"
							component={Input}
							type="number"
							adornment={<FormattedMessage id={'listAssolement.forms.content.adornment'} />}
							style={{ width: '48%', marginLeft: '52%' }}
						/>}
					</Grid>
				</React.Fragment>
		}
	]

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Detail sections={sections} containerClassName={classes.details} />
		</form>
	)

}
//Récupération des valeurs du formulaire redux (values de mui)
const selector = formValueSelector(DEPLACEMENT_ETAPE_FORM)

DeplacerEtape.propType = {
	selectedAssolements: PropTypes.array,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state) => ({
	choixDate: selector(state, 'choixDate'),
	campagne: getCurrentCampagne(state)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
	reduxForm({
		form: DEPLACEMENT_ETAPE_FORM,
		initialValues: {
			choixDate: CHOIX_DEPLACEMENT.DEPLACER
		},
		validate
	})
)(DeplacerEtape)