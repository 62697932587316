import React from 'react'
import PropTypes from 'prop-types'
import { getStyles } from 'isotope-client'
import { useGestionTacheContext } from '../../GestionTacheProvider'
import { makeStyles } from '@material-ui/styles'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { sortObjectByLabel } from '../../../../../utils/utils'
import { FormattedMessage } from 'react-intl'
import SelectJour from '../ToolBar/SelectJour'
import FilterIcon from '../../../../../components/icon/FilterIcon'
import ToolBarButton from '../../../planning/component/ToolBar/ToolBarButton'
import { injectPlanificationFilter } from '../../injectors/filterInjector'
import { compose } from 'redux'
import Button from '../../../../../components/Button'
import EditAttributesIcon from '@material-ui/icons/EditAttributes'
import { injectAffectation } from '../../injectors/affectationInjector'
import { getAllUsers } from '../../gestionTachesApi'
import { LocalDate } from '../../../../../utils/date/local-date'
import { DateFormat } from '../../../../../utils/date/model/date'
import { connect } from 'react-redux'
import * as selectors from '../../services/selectors'
import LegendeTaches from '../LegendeTaches'
import { TYPE_DIALOG } from '../../../../../utils/constants'
import QuestionIcon from '../../../../../components/icon/QuestionIcon'
import { DialogContext } from '../../../../../components/layout/dialog'

const useStyles = makeStyles(theme => getStyles({
	root: {
		height: 60,
		fontFamily: 'Lato',
		minWidth: 1150,
		flexWrap: 'nowrap'
	},
	cell: {
		backgroundColor: theme.palette.primary.light
	},
	capaciteTotale: {
		width: 300
	},
	tableScrollable: {
		maxWidth: 'calc(100% - 960px)'
	},
	ressource: {
		width: 92,
		margin: '0 4px 0 4px',
		flex: '0 0 auto',
		height: 48
	},
	icon: {
		fill: theme.palette.primary.main
	},
	tachesSelectionneesDuree: {
		fontWeight: 'bold',
		fontSize: 16
	},
	total: {
		fontWeight: 'bold',
		fontSize: 20,
		color: theme.palette.primary.main
	},
	valid: {
		color: theme.palette.valid.main
	},
	error: {
		color: theme.palette.error.main
	},
	label: {
		fontSize: 13
	},
	title: {
		fontStyle: 'italic',
		fontSize: 14,
		alignContent: 'center'
	},
	bouton: {
		padding: '5px 26px'
	}
}))

const HeaderAffectation = ({ selected, setFilterOpen, affecterDesaffecterTache }) => {
	const classes = useStyles()
	const { jourSelection, semaineSelection, isResultat } = useGestionTacheContext()
	const { openDialog } = React.useContext(DialogContext)
	const [users, setUsers] = React.useState([])

	React.useEffect(() => {
		getAllUsers(LocalDate.fromString(jourSelection).format(DateFormat.SHORT_DATE_WITH_DASH))
			.then(users => setUsers(sortObjectByLabel(users.map((ressource) => ({
				label: ressource.alias,
				code: ressource.user.id,
				value: ressource.user.id
			})))))
	}, [])

	return <Grid container direction="column" className={classes.root}>
		<Grid container item justify="space-around" direction="row">
			<Grid container item xs={6}>
				<Typography variant={'caption'} className={classes.title}>
					<FormattedMessage id="gestionTaches.ecranAffectations.header.title" />
				</Typography>
			</Grid>
			<Grid container item xs={6} justify="flex-end" alignItems="center" direction="row">
				<SelectJour semaineSelection={semaineSelection} jourSelection={jourSelection} />
				{!isResultat && <Button
					type="primary"
					startIcon={<EditAttributesIcon />}
					onClick={() => affecterDesaffecterTache(jourSelection, selected, users)}
					disabled={!selected || selected.length === 0}
					className={classes.bouton}
				>
					<FormattedMessage id="gestionTaches.ecranAffectations.button.affectation" />
				</Button>}
				<ToolBarButton type="filter" onClick={setFilterOpen}>
					<FilterIcon color="currentColor" />
				</ToolBarButton>
				<IconButton className={classes.icon} onClick={() => openDialog({ id: 'legende.title' }, <LegendeTaches />, [], TYPE_DIALOG.DETAILS, 'actions.close')}>
					<QuestionIcon />
				</IconButton>
			</Grid>
		</Grid>
	</Grid>
}

HeaderAffectation.propTypes = {
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	selected: selectors.getAffectationsSelectedRows(state)
})

export default compose(
	connect(mapStateToProps),
	injectPlanificationFilter,
	injectAffectation
)(HeaderAffectation)
