import React from 'react'
import {dataTableActions, getStyles} from 'isotope-client'
import {getCurrentCampagne} from '../../common/campagne/campagneSelector'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core'
import {colors, ONGLET_GESTION_TACHE, STATUTS_TACHE, TYPE_DIALOG} from '../../../utils/constants'
import moment from 'moment'
import RessourcesOnglet from './component/RessourcesOnglet'
import PlanificationOnglet from './component/PlanificationOnglet'
import {useGestionTacheContext} from './GestionTacheProvider'
import {DialogContext} from '../../../components/layout/dialog'
import {useSnackbar} from '../../../components/layout/snackbar/SnackbarContext'
import {getSemaine, initialiserSemaine, moveTasksDay as moveTasksDayApi} from './gestionTachesApi'
import PopinSemaineNonDefinie from './form/PopinSemaineNonDefinie'
import Button from '../../../components/Button'
import {FormattedMessage} from 'react-intl'
import AffectationOnglet from './component/AffectationOnglet'
import OrdonnancementOnglet from './component/OrdonnancementOnglet'
import {PLANIFICATION_TABLE_NAME} from './utils/constants'
import ContenuPopinDeplacementTache from './component/planification/ContenuPopinDeplacementTache'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import {refreshAffectationsTable} from './services/actions'
import ToolBarTaches from "./component/ToolBar/ToolBarTaches";
import SyntheseOnglet from './component/SyntheseOnglet'
import {theme} from '../../../index'
import {ThemeProvider} from '@material-ui/core/styles'
import ActionPanelProvider from '../../../components/layout/contextProvider/ActionPanelProvider'
import {FilterPanelContext} from '../../../components/layout/rightPanels'

const styles = () => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		height: '-webkit-fill-available'
	},
	liste: {
		marginLeft: '1%',
		marginRight: '1%',
		marginTop: 8,
		top: 173,
		left: 0,
		right: 0,
		bottom: 0,
		width: '98%',
		overflow: 'hidden'
	},
	spanTitlePopin: {
		display: 'flex',
		alignItems: 'center'
	},
	error: {
		marginBottom: '0px',
		height: '100%',
		width: '28.58px',
		color: colors.error,
		paddingRight: '20px'
	}
})

export const canTaskMove = (task) => {
	return !(task.statut === STATUTS_TACHE.EN_COURS || task.statut === STATUTS_TACHE.TERMINEE)
}

const GestionTacheComponents = ({campagne, classes, resetSelectedRows, getSelectedRows}) => {
	const {onglet, semaineSelection, semaineCourante, setSemaineCourante, refreshHeaderData, isResultat} = useGestionTacheContext()
	const {openDialog, closeDialog} = React.useContext(DialogContext)
	const {snackError, snackSuccess} = useSnackbar()

	const openPopinErreurDeplacement = (tachesEnErreur, weekBefore, semaineSelection) => {
		openDialog(
			<span className={classes.spanTitlePopin}>
				<WarningRoundedIcon className={classes.error}/>
				<FormattedMessage id="gestionTaches.toolbar.actions.move.popin.title"/>
			</span>,
			<ContenuPopinDeplacementTache tachesEnErreur={tachesEnErreur} weekBefore={weekBefore} semaineSelection={semaineSelection}/>,
			[], TYPE_DIALOG.ACTION, 'actions.close'
		)
	}

	const moveTasksDay = (day) => {
		getSelectedRows()
			.then(selectedRows => {
				if (selectedRows && selectedRows.length > 0) {
					if (selectedRows.some(task => !canTaskMove(task))) {
						openPopinErreurDeplacement(selectedRows.filter(tache => !canTaskMove(tache)))
					} else {
						moveTasksDayApi(selectedRows.map(task => task.id), day).then(() => {
							refreshHeaderData()
							resetSelectedRows()
							snackSuccess({id: 'gestionTaches.toolbar.actions.move.success'})
						}).catch(() => snackError({id: 'gestionTaches.toolbar.actions.move.error'}))
					}
				}
			})
	}

	React.useEffect(() => {
		getSemaine(moment(semaineSelection).format('YYYY-MM-DD'))
			.then((semaine) => {
				refreshHeaderData()
				setSemaineCourante(semaine)
			})
			.catch(() => {
				setSemaineCourante(null)
				openDialog(
					<FormattedMessage id="gestionTaches.ecranRessources.popin.title.initialisationPlanification"/>,
					<PopinSemaineNonDefinie/>,
					[
						<Button
							type="primary"
							onClick={() => {
								return initialiserSemaine(moment(semaineSelection).format('YYYY-MM-DD'))
									.then((semaine) => {
										snackSuccess({id: 'gestionTaches.ecranRessources.snackbar.success.successInit'})
										refreshHeaderData()
										setSemaineCourante(semaine)
										closeDialog()
									})
									.catch(() => {
										snackError({id: 'gestionTaches.ecranRessources.snackbar.errors.echecInit'})
										closeDialog()
									})
							}}
						>
							<FormattedMessage id="gestionTaches.ecranRessources.popin.boutons.initialize"/>
						</Button>
					], TYPE_DIALOG.ACTION
				)
			})
	}, [semaineSelection])

	const renderTable = React.useMemo(() => {
		if (semaineCourante) {
			return <div className={classes.liste}>
				{onglet === ONGLET_GESTION_TACHE.SYNTHESE && <SyntheseOnglet/>}
				{onglet === ONGLET_GESTION_TACHE.RESSOURCES && <RessourcesOnglet/>}
				{onglet === ONGLET_GESTION_TACHE.PLANIFICATION && <PlanificationOnglet moveTasksDay={moveTasksDay}/>}
				{onglet === ONGLET_GESTION_TACHE.AFFECTATION && <AffectationOnglet/>}
				{onglet === ONGLET_GESTION_TACHE.ORDONNANCEMENT && <OrdonnancementOnglet/>}
			</div>
		}
		return <></>
	}, [semaineCourante, onglet, moveTasksDay])

	return <div className={classes.root}>
		<ThemeProvider theme={theme(isResultat)}>
			<ActionPanelProvider Context={FilterPanelContext} level={1}>
				<ToolBarTaches
					campagne={campagne}
					openPopinErreurDeplacement={openPopinErreurDeplacement}
					moveTasksDay={moveTasksDay}
				/>
				{renderTable}
			</ActionPanelProvider>
		</ThemeProvider>
	</div>
}

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})

const actions = {
	resetSelectedRows: () => dataTableActions.selectRows(PLANIFICATION_TABLE_NAME, []),
	getSelectedRows: () => (dispatch, state) => dispatch(() => new Promise((resolve) => resolve(state().dataTable[PLANIFICATION_TABLE_NAME].selectedRows))),
	refreshAffectationsTable
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(GestionTacheComponents)
