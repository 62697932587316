import { flatten } from 'flat'
import { messages } from 'isotope-client'

export default flatten({
	...messages,
	menu: {
		fo: {
			accueil: 'Accueil',
			tableauBord: 'Tableau de bord',
			assolement: 'ASSOLEMENT',
			taches: 'TÂCHES',
			administration: 'ADMINISTRATION',
			planning: 'Planning',
			planification: 'Planification',
			vueList: 'Vue liste',
			tourPlaine: 'Tour de plaine',
			suiviAvancement: 'Suivi avancement',
			connexions: 'Suivi des connexions',
			mobile: 'MOBILE',
			goToBo: 'Paramétrage',
			besoinsProduction: 'Besoins de production',
			gestionRessource: 'Gestion des ressources'
		},
		bo: {
			accueil: 'Accueil',
			supervision: 'SUPERVISION',
			ferme: 'Gestion des fermes',
			valueList: 'Liste de valeurs',
			parametrage: {
				global: 'PARAMÉTRAGE GLOBAL',
				assolement: 'PARAMÉTRAGE CULTURE ASSOLÉE'
			},
			utilisateur: 'Gestion des utilisateurs',
			bloc: 'Bloc et surface',
			culture: 'Cultures et modèles d\'ITKs',
			groupeCulture: 'Groupes de culture',
			modeleTache: 'Modèles de tâches',
			categoriesTechniques: 'Catégories techniques',
			campagne: 'Campagnes',
			returnFo: 'Retour au front office',
			reglagesMateriels: 'Gestion des matériels',
			familleEspece: 'Familles et espèces',
			planificateur: 'Planificateur'
		}

	},
	alerte: {
		productionModifie: 'Les besoins de production ont été modifiés et le planning des cultures doit être mis à jour.',
		buttonModifie: 'Lancer une planification',
		solutionNonApprouve: 'Une solution a été proposée mais n’a pas encore été approuvée.',
		buttonSolution: 'Voir la solution',
		tourDePlaineTermine: 'Un tour de plaine a été réalisé et terminé et le planning des cultures n’a pas été mis à jour après ce tour de plaine.',
		planningRecent: 'Le planning des cultures a été mis à jour manuellement. La solution n’est donc plus à jour.',
		solveurEnCoursSuperviseur: 'Le planificateur des cultures est en cours de traitement sur la {ferme}. Aucune donnée de cette ferme ne doit être mise à jour, sous peine d\'invalider la solution',
		solveurEnCours: 'Le planificateur des cultures est en cours de traitement. Aucune donnée de la ferme ne doit être mise à jour, sous peine d\'invalider la solution',
		enregistrementEnCours: 'La solution du planning de cultures est en cours de sauvegarde. Aucune donnée du planning ne doit être modifiée, sous peine d\'invalider la solution'
	},
	pwa: {
		newVersionAvailable: 'Une nouvelle version est disponible',
		addApp: 'Ajouter l\'application Permasoft sur l\'écran d\'accueil',
		refresh: 'Actualiser'
	},
	emptyPeriodDate: '__/__/__',
	appBar: {
		menus: {
			logout: 'Déconnecter'
		},
		solveur: {
			taches: 'Génération du planning de tâches en cours',
			cultures: 'Génération du planning des cultures en cours',
			cancelCultures: 'ANNULER',
			approving: 'Sauvegarde du planning de culture en cours'
		}
	},
	cookies: {
		message: 'En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de cookies permettant son bon fonctionnement',
		dialog: {
			title: 'Utilisation des cookies',
			message1: 'En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de traceurs (cookies) afin de sécuriser votre connexion au site et de réaliser des statistiques anonymes de visites ou vous proposer des contenus et services adaptés à vos centres d\'intérêts.',
			message2: 'Pour en savoir plus et paramétrer les traceurs, cliquez sur ce lien ',
			title1: 'Qu’est-ce qu’un cookie ?',
			message3: 'Les cookies sont des fichiers texte qui sont envoyés à votre outil informatique lorsque vous naviguez sur un site puis stockés sur un fichier de votre outil informatique par votre navigateur. Lors de chaque visite, les cookies sont alors renvoyés au site visité, ou à tout autre site qui reconnait ce cookie. Les cookies se comportent comme une « mémoire » pour le site internet, lui permettant de reconnaître votre outil informatique lorsque vous revenez sur ce site.',
			title2: 'Cookies utilisés par cette application',
			message4: 'L’application que vous visitez utilise des traceurs (cookies). Ainsi, l’application est susceptible d\'accéder à des informations déjà stockées dans votre équipement terminal de communications électroniques et d\'y inscrire des informations.',
			message5: 'Les traceurs utilisés par le site relèvent de la catégorie des traceurs dits strictement nécessaires, qui ne nécessitent pas votre consentement préalable.',
			message6: 'Nous utilisons ces traceurs pour permettre et faciliter la navigation sur le site notamment en mémorisant vos préférences de navigation définies au cours de votre session.',
			message7: 'Ces traceurs ne peuvent pas, techniquement, être désactivés depuis le site.',
			message8: 'L\'utilisation des traceurs est régie par l\'article 32 II de la loi n° 78-17 du 6 janvier 1978, transposant l\'article 5.3 de la directive 2002/58/CE du parlement européen et du conseil du 12 juillet 2002 modifiée par la directive 2009/136/CE.',
			message9: 'Pour en savoir plus sur les cookies et traceurs, nous vous invitons à consulter le site de la CNIL : '
		}
	},
	notification: {
		empty: 'Aucune notification',
		clear: 'Tout marquer comme lu'
	},
	enums: {
		profil: {
			ROLE_ADMIN: 'Administrateur',
			ROLE_SUPERVISEUR: 'Superviseur',
			ROLE_CHEF_CULTURE: 'Chef de culture',
			ROLE_MARAICHER: 'Maraîcher',
			ROLE_MANAGER: 'Manager'
		},
		roles: {
			ADMIN: '(Administrateur)',
			SUPERVISEUR: '(Superviseur)',
			CHEF_CULTURE: '(Chef de culture)',
			MARAICHER: '(Maraîcher)',
			MANAGER: '(Manager)'
		},
		month: {
			1: 'Janvier',
			2: 'Février',
			3: 'Mars',
			4: 'Avril',
			5: 'Mai',
			6: 'Juin',
			7: 'Juillet',
			8: 'Août',
			9: 'Septembre',
			10: 'Octobre',
			11: 'Novembre',
			12: 'Décembre'
		},
		etatEtape: {
			A_PLANIFIER: 'A planifier',
			A_REALISER: 'A réaliser',
			EN_COURS: 'En cours',
			TERMINE: 'Terminé',
			EN_ATTENTE: 'En attente'
		},
		typeSurface: {
			BUT: 'Butte',
			SER: 'Serre',
			EXT: 'Extérieur'
		},
		typePlanche: {
			PER: 'permanente',
			DIV: 'hétérogène'
		},
		codeErreur: {
			ZONE: 'Zone incompatible',
			TYPE: 'Type de surface incompatible'
		},
		etape: {
			P: 'Préparation',
			SD: 'Semis direct',
			SC: 'Semis en contenant',
			R: 'Récolte',
			FR: 'Fin de récolte',
			I: 'Implantation',
			F: 'Fertilisation',
			C: 'croissance'
		},
		typeEntity: {
			SURFACE: 'Surface',
			ASSOLEMENT: 'Culture assolée',
			ETAPE: 'Étape liée à l\'ITK',
			BLOC: 'Bloc',
			FERME: 'Toute la ferme'
		},
		typeEntityAndValue: {
			SURFACE: 'Surface {planche}',
			BLOC: 'Bloc {bloc}',
			FERME: 'Toute la ferme'
		},
		typeItkBo: {
			SEMID: 'Semis direct',
			SEMIC: 'Semis en contenant'
		},
		jours: {
			LUNDI: 'Lundi',
			MARDI: 'Mardi',
			MERCREDI: 'Mercredi',
			JEUDI: 'Jeudi',
			VENDREDI: 'Vendredi',
			SAMEDI: 'Samedi',
			DIMANCHE: 'Dimanche'
		},
		zoneType: {
			serre: 'Serre',
			exterieur: 'Exterieur',
			butte: 'Butte',
			fruit: 'Fruit'
		},
		descriptionEtatTache: {
			A_PLANIFIER: 'La tâche peut être planifiée cette semaine.  Elle doit être planifiée sur un jour de la semaine, être affectée à une  ou plusieurs personnes et enfin être validée via la validation de la  planification de la semaine.',
			A_REALISER: 'La tâche est planifiée sur un jour et est affectée. Elle peut être réalisée et est donc visible sur l’application du/des maraîcher/s concerné/s.',
			EN_COURS: 'La tâche est en cours de réalisation, c’est à dire qu’elle a été démarrée par le maraîcher depuis l’application ou depuis le suivi avancement.',
			TERMINEE: 'La tâche a été notée comme terminée par le maraîcher depuis l’application ou depuis le suivi avancement. Le temps passé va également être mis à jour.',
			EN_ATTENTE: 'Statut spécifique aux tâches liées à l’étape Récolte et Fin de récolte qui apparaît tant que la culture n’a pas été marquée comme prête à être récoltée. Ces tâches peuvent être affectées mais ne seront pas visibles sur la liste des tâches des utilisateurs affectés.',
			EN_PAUSE: 'Le maraîcher a mis la tâche en pause, le temps passé sur cette tâche est également mis en pause.',
			SUPPRIMEE: 'La tâche a été supprimée directement ou via la suppression d’une étape ou d’une culture. Elle n’est plus visible sur l’interface.',
			EN_RETARD: 'Si la date de la tâche est antérieure à la  date du jour et que celle-ci est encore à planifier ou à réaliser, la  tâche est en retard. Elle peut toujours être affectée, planifiée puis  réalisée.'
		}
	},
	planning: {
		titleSuffixInitial: 'Initial',
		titleSuffixRevise: 'Révisé',
		titleSuffixCloture: 'Cloturé',
		preview: 'Visualisation de la solution',
		buttons: {
			validate: 'Valider le planning',
			compare: 'Comparer au planning initial',
			masquer: 'Masquer le planning initial',
			new: {
				label: 'Nouvel ajout',
				items: {
					addCulture: 'Ajouter une culture',
					addCultureGroup: 'Ajouter un groupe de cultures'
				}
			},
			seeMore: 'Voir plus',
			seeLess: 'Voir moins'
		},
		leftPanel: {
			header: {
				surface: 'Surface',
				culture: 'Culture'
			},
			menu: {
				culture: {
					detail: 'Voir le détail de la culture',
					move: 'Déplacer la culture',
					editItinerary: 'Modifier l\'itinéraire de la culture',
					delete: 'Supprimer la culture',
					suivi: 'Ajouter un suivi de production',
					suiviShort: 'Ajouter un suivi',
					duplicate: 'Dupliquer la culture',
					addGroup: 'Créer un groupe de cultures',
					lockCulture: 'Verrouiller la culture assolée',
					unlockCulture: 'Déverrouiller la culture assolée'
				},
				surface: {
					detail: 'Voir le détail de la surface',
					addComment: 'Ajouter un commentaire sur la surface',
					createFertilization: 'Ajouter la fertilisation de la surface',
					addFertilization: 'Nouvelle fertilisation',
					short: {
						detail: 'Détail sur la surface',
						addComment: 'Ajouter commentaire',
						createFertilization: 'Ajouter fertilisation'
					}
				}
			}
		},
		suivi: {
			detail: {
				name: 'Commentaires',
				empty: 'Aucun suivi de production enregistré pour cette culture',
				FIN_TACHE: 'Fin de tâche',
				DEMARRAGE_IMPOSSIBLE: 'Démarrage impossible',
				SUIVI_TACHE: 'Suivi de tâche'
			}
		},
		planche: {
			detail: {
				fertilization: {
					none: 'Aucune tâche de fertilisation enregistrée pour cette surface',
					title: 'Fertilisation',
					planned: '{quantity, plural, one {# fertilisation planifiée} other {# fertilisations planifiées}} :'
				},
				comments: {
					title: 'Commentaires de suivi',
					none: 'Aucun commentaire de suivi enregistré pour cette surface'
				},
				bloc: 'Bloc {bloc}'
			}
		},
		culture: {
			previousCampaign: 'Cultures de la campagne précédente',
			previousCampaignCollapsed: 'Voir les cultures de la campagne précédente'
		},
		itineraireTechnique: {
			title: 'Itinéraire technique',
			detail: {
				designation: 'Modèle d’itinéraire : <b>{designation}</b> ',
				periodeSemis: 'Période de semis : <b>{start}</b> au <b>{end}</b>',
				nbGraine: 'Nombre de graines par trou : <b>{nbGraine}</b>',
				densite: 'Espacement de <b>{espacement} cm - {value, plural, one {# plant} other {# plants}}/m²</b>',
				nbRangs: 'Espacement de <b>{espacement} cm - {value, plural, one {# rang} other {# rangs}}</b>'
			}
		},
		calendar: {
			shortLabelWeek: 'S'
		},
		cultureForm: {
			new: 'Ajout d\'une culture',
			duplicate: 'Dupliquer la culture',
			editItk: {
				title: 'Modifier l\'itinéraire technique',
				success: 'L\'itinéraire technique a été modifié avec succès'
			},
			moveCulture: 'Déplacer la culture',
			deleteCulture: {
				title: 'Supression de la culture',
				text: 'Vous êtes sur le point de supprimer la culture {culture} de la surface {surface}',
				warning: 'Êtes vous sûr de vouloir la supprimer ?'
			},
			localisation: {
				title: 'Localisation',
				editTitle: 'Nouvelle localisation',
				labelField: 'Nom de la surface'
			},
			culture: {
				title: 'Culture',
				labelField: 'Nom de la culture'
			},
			itineraireTechnique: {
				title: 'Itinéraire technique',
				labelField: 'Modèle d\'itinéraire',
				emptyState: 'Aucun modèle d\'itinéraire technique n\'existe pour la culture et pour la zone',
				periodeSemis: 'Période de semis : ',
				nbGraines: 'Nombre de graines par trou : ',
				options: {
					semis: 'Semis : {debut} - {fin}',
					recolte: 'Début de récolte : {debut} - {fin}'
				}
			},
			dates: {
				prevoir: 'Prévoir',
				semis: 'un semis au ',
				recolte: 'un début de récolte au ',
				semaine: 'Semaine du {start} au {end}'
			},
			espacement: {
				labelField: 'Espacement',
				unite: 'cm'
			},
			densite: {
				labelField: 'Densité',
				unite: 'plants/m²'
			},
			nbRangs: {
				labelField: 'Nombre de rangs'
			},
			bouton: {
				add: 'Ajouter au planning'
			},
			errors: {
				mandatory: 'Ce champ est obligatoire',
				negative: 'Doit être supérieur à 0',
				invalidDate: 'Date non valide',
				date: 'L\'ajout de nouvelle culture est uniquement possible pour la campagne en cours'
			},
			fertilization: {
				new: 'Fertilisation de la surface',
				update: 'Nouvelle tâche de fertilisation',
				comment: 'Commentaire sur la fertilisation',
				localisation: 'Localisation',
				addFertilization: 'Ajouter une date de fertilisation',
				label: {
					date: '{value, plural, one {#ère} two {#nde} other {#ème}} fertilisation :',
					add: 'Date de fertilisation',
					comment: 'Commentaire sur la fertilisation',
					planche: 'Nom de la surface',
					allPlanches: 'Sélectionner toutes les surfaces sans tâches de fertilisation'
				},
				error: {
					required: 'Cette date doit être renseignée',
					outsideCampaign: 'L\'ajout de fertilisation n\'est possible que sur la campagne en cours',
					fertilizationAlreadyExist: 'Une tâche de fertilisation est déjà planifiée pour cette semaine',
					wrongWeek: 'La {secondValue, plural, one {#ère} two {#nde} other {#ème}} fertilisation ne peut avoir lieu la même semaine que la {firstValue, plural, one {#ère} two {#nde} other {#ème}} fertilisation'
				}
			}
		},
		cultureGroup: {
			addGroup: {
				title: 'Ajout d\'un groupe de cultures',
				bouton: {
					add: 'Ajouter au planning'
				},
				cultures: {
					title: 'Groupe de cultures',
					labelField: 'Nom du groupe ou de culture la composant',
					espacement: {
						label: 'Espacement',
						unite: 'cm'
					},
					libelleSurface: {
						permanente: 'Planche permanente',
						heterogene: 'Planche hétérogène, Forêt / jardin'
					},
					nbRangs: 'Nombre de rangs',
					densite: {
						label: 'Densité',
						unite: 'plants/m²'
					},
					itineraire: '{nom} - Itinéraire du <b>{debut}</b> au <b>{fin}</b>',
					periode: 'Période de semis conseillée : du <b>{debut}</b> au <b>{fin}</b>'
				},
				dates: {
					text: 'Ajouter le groupe de cultures pour',
					debut: 'Un début au',
					fin: 'Une fin au'
				},
				errors: {
					mandatory: 'Ce champ est obligatoire',
					chooseOne: 'Choisissez au moins une culture',
					tooLong: 'Le champ dépasse le nombre de {sizeMax} caractères autorisés'
				}
			},
			createGroup: {
				title: 'Création d\'un groupe de cultures',
				text: 'Sélectionnez les cultures parmi celles présentes sur la surface pour la campagne sélectionnée :',
				fields: {
					assolement: '{nom} - Itinéraire du {debut} au {fin}',
					assolementWithBold: '<b>{nom} - Itinéraire du {debut} au {fin}</b>',
					dateDebut: 'Début',
					dateFin: 'Fin',
					nom: 'Nom du groupe de cultures',
					description: 'Description',
					zones: 'Zone(s) compatible(s) avec le groupe'
				},
				errors: {
					mandatory: 'Ce champ est obligatoire',
					chooseOne: 'Choisissez au moins une culture',
					tooLong: 'Le champ dépasse le nombre de {sizeMax} caractères autorisés'
				},
				dialog: {
					title: 'Création d\'un groupe de cultures',
					message: 'Veuillez vérifier les informations du groupe de cultures que vous êtes sur le point de point de créer :',
					cancel: 'Modifier',
					cultures: {
						title: 'Cultures composant le groupe',
						text: '{nom} - Itinéraire du <b>{debut}</b> au <b>{fin}</b>'
					},
					other: {
						title: 'Autres caractéristiques',
						dates: 'Groupe de cultures débutant le <b>{debut}</b> et se terminant le <b>{fin}</b>',
						zones: 'Zone(s) compatible(s) : <b>{zones}</b>',
						surface: 'Type(s) de surface compatible(s) : <b>{surface}</b>'
					},
					success: 'Groupe de cultures enregistré avec succès',
					error: 'Erreur lors de la sauvegarde du groupe de cultures'
				}
			}
		},
		contextMenu: {
			primary: {
				itineraireTechnique: 'Déplacer l’itinéraire technique'
			},
			secondary: {
				delete: 'Supprimer l\'étape {etape}',
				detail: 'Voir le détail de l\'étape {etape}',
				suivi: 'Ajouter un suivi sur l\'étape {etape}',
				adjust: 'Allonger/Réduire l\'étape {etape}',
				move: 'Déplacer l\'étape {etape}'
			}
		},
		etapesAssolement: {
			title: 'Étape : ',
			realisation: 'Réalisation prévue entre le : <b>{start}</b> et le <b>{end}</b>',
			campagne: 'Étape affectée à la <b>{campagne} {etat}</b>',
			buttons: {
				supprimer: 'Supprimer l\'étape',
				suivi: 'Ajouter un suivi'
			},
			suiviTitle: 'Nouveau suivi de l\'étape',
			editSuivi: 'Modification du suivi de l\'étape',
			commentaire: 'Commentaire sur l\'étape',
			deleteEtape: {
				title: 'Suppression de l\'étape',
				text: 'Vous êtes sur le point de supprimer l\'étape {etape}. Cette action est irréversible.'
			}
		},
		validerPlanning: {
			title: 'Valider le planning initial',
			text1: 'Vous êtes sur le point de valider le planning initial pour la campagne en cours.',
			text2: 'Cette action va figer votre planning initial, et créer une nouvelle version du planning que vous pourrez modifier et comparer au planning initial.',
			text3: 'Confirmez vous la validation du planning initial ?'
		},
		filter: {
			title: 'Filtrer le planning',
			form: {
				culture: 'Culture',
				surface: 'Surface',
				espece: 'Espèce',
				famille: 'Famille',
				bloc: 'Bloc',
				zone: 'Zone',
				submit: 'filtrer',
				reset: 'Réinitialiser les filtres',
				empty: {
					title: 'Aucun résultat',
					content: ' Il semblerait que les filtres appliqués ne donnent aucun résultat.\n' +
						'Réessayez avec de nouveaux filtres.',
					EN_COURS: 'Il n\'y a pas de tâche à réaliser',
					A_REALISER: 'Il n\'y a pas de tâche restante',
					TERMINEE: 'Aucune tâche terminée'
				}
			}
		},
		sort: {
			SURFACE_ET_SEMIS: 'Par surface et date de semis',
			SEMIS: 'Par date de semis',
			DEFAULT: 'Par défaut..'
		},
		export: {
			PRODUCTION_CIBLE: 'Production cible',
			PRODUCTION_ATTENDUE: 'Production attendue',
			PRODUCTION_REVISEE: 'Production révisée',
			PRODUCTION_REELLE: 'Production réelle',
			CHARGES_TRAVAIL: 'Charges de travail',
			BROUILLON: 'Brouillon du planning',
			REVISE: 'Planning révisé',
			VALIDE: 'Planning validé',
			popin: {
				title: 'Export de la charge nécessaire prévisionnelle',
				text: 'Sélectionnez la campagne pour laquelle vous souhaitez exporter la charge de travail prévisionnelle nécessaire.',
				field: 'Sélectionnez la campagne à exporter',
				button: 'Exporter',
				error: 'Veuillez sélectionner une campagne'
			}
		},
		planningSize: {
			month: '{value} mois'
		},

		toolbar: {
			button: {
				filter: 'Filtrer'
			}
		}
	},
	commentForm: {
		titles: {
			suivi: {
				new: 'Nouveau suivi de production',
				edit: 'Modification du suivi de production'
			},
			planche: {
				new: 'Nouveau commentaire',
				edit: 'Modification du  commentaire'
			}
		},
		fields: {
			typeCommentaire: 'Type de commentaire',
			variation: 'Variation',
			pourcentageVariation: 'Pourcentage de variation',
			commentaire: 'Commentaire sur le développement de la culture',
			commentairePlanche: 'Commentaire sur la surface',
			dateApplication: 'Date d’application de l’impact sur production'
		},
		errors: {
			mandatory: 'Dans le cas d’un impact sur la production, ce champ doit être renseigné.',
			commentaireMandatory: 'Ce champ est obligatoire.',
			negativeProduction: 'La perte ne peut excéder 100% de la récolte',
			dateApplicationMandatory: 'Dans le cas d’un impact sur la production, ce champ doit être renseigné.',
			dateApplication: 'La date renseignée doit être compris dans l’interval de la campagne en cours'
		},
		deleteComment: {
			etape: {
				title: 'Supression du commentaire de suivi de l\'étape',
				label: 'Vous êtes sur le point de supprimer le commentaire effectué pour le suivi de l\'étape.'
			},
			planche: {
				title: 'Suppression du commentaire',
				label: 'Confirmez-vous la suppression de votre commentaire saisi le {date} ?'
			},
			culture: {
				title: 'Suppression du commentaire',
				label: 'Confirmez-vous la suppression de votre commentaire saisi le {date} ?'
			},
			label: 'Êtes vous sûr de vouloir le supprimer ?'
		},
		impactProduction: 'Impact sur la production'
	},
	snackbar: {
		update: {
			ajoutCulture: 'Ajout de la nouvelle culture enregistré',
			ajoutGroupeCulture: 'Ajout des nouvelles cultures enregistré',
			verrouillage: 'Culture assolée verrouillée',
			deverrouillage: 'Culture assolée deverrouillée',
			suppressionCulture: 'Suppression de la culture enregistrée',
			deplacerCulture: 'Déplacement de la culture enregistré',
			deplacerItk: 'Déplacement de l’itinéraire technique enregistré',
			deplacerEtape: 'Déplacement enregistré',
			resizeEtape: 'Modifications enregistrées',
			suppression: 'Suppression enregistrée',
			validerPlanning: 'Planning initial validé avec succès',
			suiviEtape: 'Modifications enregistrées',
			fertilisation: 'Ajout de la tâche de fertilisation enregistré',
			validateTask: 'Étape terminée',
			exportRequest: 'Le document est en cours d’export. Il sera accessible depuis votre Drive dans quelques instants',
			exportProduction: {
				message: 'L’export du document est terminé',
				button: 'Ouvrir'
			},
			duplicatedCulture: 'Duplication de la culture enregistrée',
			ajoutUtilisateur: 'L\'utilisateur a bien été créé',
			updateUtilisateur: 'L\'utilisateur a bien été mis à jour',
			ajoutModeleTache: 'Le modèle de tâche n°{moTacheId} a bien été créé',
			updateModeleTache: 'Le modèle de tâche n°{moTacheId} a bien été mis à jour',
			suppressionUtilisateur: 'Suppression de l\'utilisateur effectuée',
			tourPlaine: {
				saveTP: 'Tour de plaine confirmé',
				saveWeather: 'Le contexte pédoclimatique a bien été mis à jour',
				reminder: 'La note a bien été enregistrée',
				addDataOnBoards: 'La modification {count, plural, one {de la planche} other {des planches}} a bien été enregistrée',
				generic: 'La modification a bien été enregistrée',
				deleteIrrigation: 'Le créneau d\'aspersion a bien été supprimé',
				deleteBoardsUnavailable: 'Les périodes d\'indisponibilités ont bien été supprimées',
				deleteBoardUnavailable: 'La période d\'indisponibilité a bien été supprimée'
			}
		},
		error: {
			ajoutCulture: 'Une erreur est survenue, la culture n\'a pas pu être ajoutée',
			ajoutGroupeCulture: 'Une erreur est survenue, les cultures n\'ont pas pu être ajoutées',
			verrouillage: 'Une erreur est survenue, le verrouillage n\'as pas été pris en compte',
			deverrouillage: 'Une erreur est survenue, le déverrouillage n\'as pas été pris en compte',
			suppression: 'Une erreur est survenue, la suppression n\'a pas pu être enregistrée',
			deplacerCulture: 'Déplacement de la culture impossible : la zone de la surface de destination ne convient pas à l’itinéraire technique de la culture',
			deplacerCulture2: 'Une erreur est survenue, la culture n\'a pas pu être déplacée',
			deplacerItk: 'Une erreur est survenue, le déplacement de l’itinéraire technique n’a pas pu être  enregistré',
			deplacerEtape: 'Une erreur est survenue, le déplacement de l\'étape n’a pas pu être  enregistré',
			resizeEtape: 'Une erreur est survenue, la modification n’a pas pu être  enregistré',
			suppressionEtape: 'Une erreur est survenue, l\'étape n’a pas pu être supprimée',
			validerPlanning: 'Une erreur est survenue, le planning initial n’a pas pu être validé',
			suiviEtape: 'Une erreur est survenue, les modifications n’ont pas pu être enregistrées',
			fertilisation: 'Une erreur est survenue, la fertilisation n\'a pas pu être ajoutée',
			validateTask: 'Une erreur est survenue, l\'étape n’a pas pu être terminée',
			exportProduction: 'Une erreur est survenue, le document n’a pas pu être exporté',
			exportEcartProduction: 'Une erreur est survenue pendant l\'export de l\'écart de production',
			duplicatedCulture: 'Une erreur est survenue, la duplication de la culture n’a pas pu être enregistrée',
			ajoutUtilisateur: 'Une erreur est survenue, l\'ajout de l\'utilisateur n\'a pas pu être enregistré',
			ajoutModeleTache: 'Une erreur est survenue, l\'ajout du modèle de tâche n\'a pas pu être enregistré',
			updateUtilisateur: 'Une erreur est survenue, la modification de l\'utilisateur n\'a pas pu être enregistré',
			updateModeleTache: 'Une erreur est survenue, la modification du modèle de tâche n\'a pas pu être enregistré',
			suppressionUtilisateur: 'Suppression de l\'utilisateur impossible',
			pdf: 'Erreur lors du téléchargement du pdf',
			tourPlaine: {
				saveTP: 'Veuillez renseigner les besoins de production de la campagne {campagne} avant de lancer la planification automatique des cultures',
				saveWeather: 'Une erreur est survenue, la modification du contexte pédoclimatique n\'a pas pu être enregistré',
				reminder: 'Une erreur est survenue, la note n\'a pas pu être enregistrée',
				addDataOnBoards: 'Une erreur est survenue, la modification {count, plural, one {de la planche} other {des planches}} n\'a pas pu être enregistré',
				generic: 'Une erreur est survenue, la modification n\'a pas pu être enregistrée',
				deleteIrrigation: 'Une erreur est survenue, le créneau d\'aspersion n\'a pas pu être supprimé',
				deleteBoardsUnavailable: 'Une erreur est survenue, les périodes d\'indisponibilités n\'ont pas pu être supprimées',
				deleteBoardUnavailable: 'Une erreur est survenue, la période d\'indisponibilité n\'a pas pu être supprimée'
			}
		},
		warning: {
			dateSemis: 'Déplacement enregistré. Attention, l\'étape de semis se trouve en dehors de la période de semis conseillée',
			resizeDateSemis: 'Modifications enregistrées. Attention, l\'étape de semis se trouve en dehors de la période de semis conseillée',
			fertilisation: 'Modification enregistrée avec succès. Attention, une tâche de fertilisation intervient la même semaine que l\'étape {etape} pour la culture {culture}',
			move: {
				previousCampagne: 'Déplacement enregistré. La culture a été affectée à la campagne précédente',
				nextCampagne: 'Déplacement enregistré. La culture a été affectée à la campagne suivante',
				currentCampagne: 'Déplacement enregistré. La culture a été affectée à la campagne actuelle'
			},
			create: {
				previousCampagne: 'Ajout de la nouvelle culture enregistrée. Cette culture est affectée à la campagne précédente',
				nextCampagne: 'Ajout de la nouvelle culture enregistrée. Cette culture est affectée à la campagne suivante'
			},
			delete: {
				previousCampagne: 'Suppression enregistrée. La culture a été affectée à la campagne précédente',
				nextCampagne: 'Suppression enregistrée. La culture a été affectée à la campagne suivante',
				currentCampagne: 'Suppression enregistrée. La culture a été affectée à la campagne actuelle'
			},
			resize: {
				previousCampagne: 'Modification enregistrée. La culture a été affectée à la campagne précédente',
				nextCampagne: 'Modification enregistrée. La culture a été affectée à la campagne suivante',
				currentCampagne: 'Modification enregistrée. La culture a été affectée à la campagne actuelle'
			}
		},
		cancel: 'Modification annulée'
	},
	actions: {
		validate: 'Valider',
		cancel: 'Annuler',
		close: 'Fermer',
		delete: 'Supprimer',
		confirm: 'Confirmer',
		accept: 'J\'accepte',
		create: 'Créer',
		add: 'Ajouter',
		more: 'En savoir plus',
		other: 'Autres actions',
		save: 'Enregistrer',
		back: 'Retour',
		continue: 'Continuer'
	},
	legende: {
		title: 'Légende',
		semisConseilles: 'Période de semis conseillée',
		actuelle: 'Planification actuelle',
		initiale: 'Planification initiale'
	},
	field: {
		noInput: 'Veuillez entrer une valeur',
		noOptions: 'Aucun résultat',
		noValue: 'Aucune valeur correspondante'
	},
	global: {
		userNotAuthorized: 'Vous n\'êtes pas autorisé à vous connecter à cette application.',
		other: 'Autres actions',
		filter: 'Filtrer',
		errors: {
			mandatory: 'Ce champ est obligatoire',
			sizeExceeded: 'Ce champ dépasse la taille maximale autorisée',
			positive: 'Ce champ doit être supérieur à 0',
			positive1: 'Ce champ doit être supérieur à 1',
			min: 'Ce champ doit être supérieur à {min}',
			form: 'Votre formulaire comporte des erreurs',
			wrongFormat: 'Le champ n\'est pas au bon format',
			zeroOrPositive: 'Ce champ doit être supérieur ou égal à 0',
			zeroOrNegative: 'Ce champ doit être inférieur ou égal à 0',
			isBefore: 'L\'heure de fin ne peut être avant l\'heure de début',
			isDateBefore: 'La date de fin ne peut être avant la date de début'
		},
		buttons: {
			loginGoogle: 'SIGN IN WITH GOOGLE'
		}
	},
	accueil: {
		title: 'Accueil',
		content: {
			welcome: 'Bienvenue sur Permasoft,',
			title: 'votre outil d’aide au pilotage de fermes en micro-maraîchage bio-intensif.',
			content: 'Grâce à cette application, vous pouvez suivre l\'avancement du plan d\'assolement, visualiser les étapes à effectuer et accéder rapidement au dernier fichier que vous avez exporté depuis l\'application.'
		},
		button: {
			planning: 'Accéder au planning',
			etape: 'Accéder à la liste des étapes',
			lastExport: 'Ouvrir le dernier export du {date}'
		}
	},
	tdb: {
		title: 'Bonjour {name} !',
		detail: 'Tour de plaine',
		blocs: {
			planning: {
				title: 'Planning',
				description: 'Vous pouvez consulter et modifier le planning des cultures ainsi que le planning des tâches.',
				blocs: {
					culture: 'Cultures',
					taches: 'Tâches'
				}
			},
			production: {
				title: 'Besoins de production',
				description: 'Vous pouvez consulter et modifier les besoins de production de la campagne précédente et de la campagne actuelle.',
				maj: 'Date de la dernière mise à jour :',
				vide: 'Aucune campagne en cours',
				campagnes: {
					cloture: 'campagne clôturée',
					cours: 'campagne en cours'
				}
			},
			commentaires: {
				title: 'Mes notes',
				description: 'Vous pouvez consulter et archiver les notes qui vous sont adressées.',
				historique: 'Afficher les notes lues',
				label: 'Tableau de bord'
			},
			pedoclimatique: {
				title: 'Conditions pédoclimatiques de la semaine',
				description: 'Les conditions pédoclimatiques peuvent être paramétrées manuellement. Elles permettent de donner une indication quant à la réalisation ou non des tâches et à l’utilisation de certains outils.'
			},
			materiels: {
				title: 'Stock matériels',
				description: 'Ci-dessous, vous trouverez le stock en temps réel des différents outils de la ferme.'
			}
		}
	},
	calcul: {
		button: 'Recalculer les cumuls de la campagne actuelle',
		success: 'Le calcul a été éffectué',
		failure: 'Le calcul a échoué'
	},
	listAssolement: {
		column: {
			culture: 'Culture',
			surface: 'Surface',
			nbRangsDensite: 'Rangs/densité',
			dateSemisEnPlaque: 'Semis en plaque',
			datePreparationPlanche: 'Préparation de planche',
			dateImplantation: 'Implantation',
			dateSemisDirect: 'Semis direct',
			datesRecolte: 'Récolte',
			dateFinRecolte: 'Fin de récolte'
		},
		content: {
			nbRangs: '{nbRangs, plural, one {# rang} other {# rangs}}',
			densite: '{densite, plural, one {# plant} other {# plants}}/m²'
		},
		buttons: {
			action: 'Actions'
		},
		actionButtonMenu: {
			deplacerCultures: 'Déplacer les cultures',
			dupliquerCultures: 'Dupliquer les cultures',
			supprimerCultures: 'Supprimer les cultures',
			ajoutSuiviProd: 'Ajouter un suivi de production',
			deplacerEtapeImplantation: 'Déplacer les étapes Implantations',
			deplacerEtapeRecolte: 'Déplacer les étapes Récolte',
			modifEtapeRecolte: 'Allonger/Réduire les étapes Récolte'
		},
		forms: {
			headers: {
				deplacerCultures: 'Déplacer les cultures',
				dupliquerCultures: 'Dupliquer les cultures',
				ajoutSuiviProd: 'Nouveau suivi de production',
				deplacerEtapeImplantation: 'Déplacement de l’étape',
				deplacerEtapeRecolte: 'Déplacement de l’étape',
				modifEtapeRecolte: 'Allonger / réduire la récolte'
			},
			h2: {
				deplacerCultures: 'Cultures à déplacer',
				dupliquerCultures: 'Cultures à dupliquer',
				nouvelleLocalisation: 'Nouvelle localisation',
				pasDeLocalisation: 'Duplication impossible : Aucune surface ne remplit les critères de zone et/ou de type de surface',
				etapeRecolte: 'Récolte',
				deplacementEtape: 'Cultures',
				etapeImplantation: 'Implantation'
			},
			h3: {
				culturesPrecedentes: 'Cultures de la campagne précédente'
			},
			checkBox: 'Marquer la culture comme prête à être récoltée',
			content: {
				fourchettePrevisionRecolte: '(Récolte prévue du {dateDebut} au {dateFin})',
				previsionImplantation: '(Implantation prévue le {dateDebut})',
				adornment: 'semaine(s)',
				enPartant: 'En partant',
				allongementReductionTache: 'Allongement/réduction de la tâche de récolte',
				duDebutDeLaRecolte: 'du début de la récolte',
				deLaFinDeLaRecolte: 'de la fin de la récolte',
				allonger: 'Allonger de',
				reduire: 'Réduire de'
			},
			contentError: {
				targetBeforePreviousState: 'La date choisie précède une autre étape',
				deplacementCampagneSuivante: 'Le déplacement n\'est possible que sur la campagne en cours',
				dateDebutApresDateFin: 'La date de début se trouve après la date de fin',
				dateFinAvantDateDebut: 'La date de fin se trouve avant la date de début',
				nbSemaineInvalide: 'Nombre de semaine(s) invalide',
				dateApplicationHorsCampagne: 'La date doit être comprise dans la campagne'
			},
			checkBoxes: {
				deplacerLe: 'À déplacer le',
				decalerDe: 'À décaler de'
			}
		},
		snackbar: {
			ok: {
				dupliquerCultures: 'Duplications enregistrées',
				suppression: 'Suppressions enregistrées',
				suppressionSingle: 'Suppression enregistrée',
				deplacerCultures: 'Déplacement enregistré avec succès',
				deplacerEtapes: 'Déplacement enregistré avec succès',
				modifierEtapeRecolte: 'Modification des étapes récolte enregistrée',
				annulation: 'Annulation enregistrée'
			},
			error: {
				dupliquerCultures: 'Une erreur est survenue, la duplication de cultures n’a pas pu être enregistrée',
				deplacerCultures: 'Une erreur est survenue, les cultures n\'ont pas pu être déplacées',
				deplacerEtapes: 'Déplacement des étapes impossible',
				modifierEtapeRecolte: 'Allongement / Réduction de la récolte impossible du fait de conflits'
			}
		},
		popup: {
			headers: {
				supprimerCultures: 'Suppression de cultures'
			},
			content: {
				supprimerContent: 'Attention, vous êtes sur le point de supprimer définitivement une ou plusieurs cultures :',
				liste: '{culture} de la surface {surface} (Itinéraire du {dateDebPremiereEtape} au {dateDebDerniereEtape})'
			}
		},
		popinAlerte: {
			warningsTitle: 'Déplacement sur une surface différente',
			errorTitle: 'Déplacement impossible',
			sections: {
				errorIntro: 'Attention, la surface de destination est incompatible avec une ou plusieurs cultures que vous souhaitez déplacer. Veuillez modifier votre sélection puis réessayer.',
				warningsIntro: 'Attention, vous êtes sur le point de déplacer une ou plusieurs cultures dont la surface d\'origine possède des caractéristiques différentes de celle de destination.',
				surfaceDestination: 'Surface de destination :',
				culturesIncompatibles: 'Cultures incompatibles avec la surface de destination :',
				culturesAvecAttention: 'Cultures nécessitant votre attention :'
			},
			content: {
				surfaceDestination1: '{zone} > Bloc {typeBloc} > {surface}',
				surfaceDestination1BUT: '{zone} > {surface}',
				surfaceDestination2: 'Planche {typePlanche} - {aireSurface} m²',
				culturesIncompatibles: '{nomculture} : Surface d\'origine {surfaceOrigine} de {aireSurface}m2 ({codeErreur})',
				culturesNecessitantAttention: '{nomculture} : Surface d\'origine {surfaceOrigine} de {aireSurface}m2'
			}
		},
		dateFilter: {
			title: 'Filtre par période',
			start: 'Début',
			end: 'Fin'
		}
	},
	besoinsProduction: {
		header: {
			title: 'Besoins de production',
			steps: {
				RECUEIL: 'Recueil des besoins de production',
				RESULTATS: 'Résultats de la planification auto.'
			},
			buttons: {
				planifier: 'Planifier automatiquement',
				planning: 'Voir le planning',
				approuver: 'Approuver la solution'
			},
			popin: {
				approbation: {
					title: 'Approbation de la solution',
					message: 'En cliquant sur confirmer, vous approuvez la solution proposée pour les campagnes en cours ({campagne}).'
				}
			},
			infos: {
				generatedSolution: 'Solution générée par <b>{user}</b> le <b>{date}</b> à <b>{heure}</b>',
				savedSolution: 'Solution approuvée par <b>{user}</b> le <b>{date}</b> à <b>{heure}</b>',
				approuving: '<b>Vérification de la solution en cours ...</b>'
			},
			error: {
				errorSolution: 'Le planificateur a rencontré une erreur et cette solution ne peut ni être approuvée ni visualisée'
			}
		},
		filter: {
			placeholder: 'Cultures qui commencent par',
			ajouter: 'Ajouter',
			lignes: 'lignes',
			periodeWithAlerte: 'Période de récolte avec alerte',
			quantiteWithAlerte: 'Quantité avec alerte'
		},
		unites: {
			kg: 'kg',
			kgParSemaine: 'kg/sem.',
			kgParSemaineFull: 'kg/semaine',
			planches: '{planche, plural, one {planche} other {planches}}',
			semaines: 'semaines'
		},
		date: {
			debut: 'du ',
			fin: ' au '
		},
		recueil: {
			table: {
				headers: {
					culture: 'Culture',
					quantite: 'Quantité',
					periode: 'Période de récolte',
					importance: 'Importance'
				}
			},
			popin: {
				validation: {
					title: 'Lancement de la planification automatique',
					message1: 'En cliquant sur confirmer, vous validez les besoins de production pour <b>les campagnes en cours</b> ({campagnes}).',
					message2: 'Une solution de planning sera automatiquement générée en respectant au maximum ces besoins.'
				},
				commentaire: {
					title: 'Commentaire sur le besoin de production',
					fields: {
						commentaire: 'Commentaire'
					}
				},
				suppression: {
					title: 'Suppression d\'un besoin de production',
					message: 'En cliquant sur supprimer, vous confirmez la suppression du besoin suivant :'
				},
				fields: {
					culture: 'Culture',
					quantite: 'Quantité souhaitée',
					periode: 'Période souhaitée'
				}
			},
			notSaved: 'modifications non enregistrées',
			campagnesCloturees: {
				title: 'Campagnes cloturées',
				message: 'Vous pouvez sélectionner une campagne clôturée pour visualiser ses besoins de production.'
			},
			actions: {
				export: 'Exporter les besoins',
				valider: {
					bouton: 'Enregistrer',
					succes: 'Sauvegarde du besoin réalisé avec succès',
					erreur: 'Veuillez remplir toute les données de chaque ligne'
				},
				annuler: 'Annuler'
			},
			empty: {
				campagneActive: 'Aucun besoin de production, veuillez ajouter des lignes de besoin',
				campagneCloturee: 'Aucun besoin de production'
			}
		},
		resultat: {
			tabs: {
				ECART_CULTURE: 'Écart par culture',
				TAUX_REMPLISSAGE: 'Taux de remplissage',
				RESPECT_CONTRAINTES: 'Respect des contraintes'
			},
			stats: {
				atteinteObjectifQuantite: 'Respect des quantités demandées',
				pourcentageNormalise: '(Pourcentage normalisé)',
				alertesQuantite: 'Alertes sur les quantités',
				alertesPeriode: 'Alertes sur les périodes de récolte',
				tauxRemplissageFerme: 'Taux de remplissage de la ferme'
			},
			table: {
				headers: {
					culture: 'Culture',
					periodePrevue: 'Période de récolte prévue',
					periodeSouhaitee: 'Période de récolte souhaitée',
					quantitePrevue: 'Quantité prévue',
					quantite: 'Quantité souhaitée',
					importance: 'Importance',
					nbSemaines: 'Nombre de semaines',
					periode: 'Période concernée',
					nbPlanches: 'Nombre de planches',
					zone: 'Zone'
				},
				emptyImportance: 'non spécifié dans la demande',
				emptyPeriode: 'pas de période de récolte prévue'
			},
			ecartParCulture: {
				ecart: 'Ecart par culture et par semaine',
				export: 'Exporter le détail par semaine'
			},
			tauxRemplissage: {
				explicationsCalculs : {
					title: 'Explication des calculs de pourcentage',
					repartitionFamille: 'Somme pour toutes les cultures assolées d\'une famille botanique du nombre de semaine d\'occupation d\'une planche permanente pendant la campagne par rapport au total pour toutes les cultures assolées confondues du nombre de semaine d\'occupation d\'une planche permanente pendant la campagne.',
					tauxRemplissage: 'Somme sur toutes les semaines de la campagne du nombre de planches permanentes occupées par une ou plusieurs cultures par rapport au nombre de planches permanentes de la ferme multiplié par le nombre de semaines de la campagne.',
				},
				surfacesSansCultures: 'Surfaces sans culture',
				repartitionFamille: 'Répartition des familles botaniques',
				empty: 'Vide'
			}
		}
	},
	gestionRessource: {
		detailRessource: {
			title: 'Détail d’une ressource - {nom} {prenom}',
			snackbar: {
				success: 'La ressource a bien été mise à jour.',
				error: 'La ressource n\'a pas été mise à jour.'
			},
			onglets: {
				informations: 'INFORMATIONS GÉNÉRALES',
				competences: 'COMPÉTENCES',
				absences: 'ABSENCES',
				contrats: 'CONTRAT'
			},
			fields: {
				isActive: 'Ressource inactive'
			},
			modification: 'modifications non enregistrées',
			inactive: {
				title: 'Ressource affectée',
				infoTop: 'La ressource que vous souhaitez rendre inactive est affectée {quantity, plural, one {à la tâche suivante} other {aux tâches suivantes}} : ',
				infoBottom: 'Afin de rendre la ressource inactive, veuillez tout d’abord supprimer {quantity, plural, one {l\'affectation} other {les affectations}}',
				tache: '<b>{type}</b> - {culture} - {planche} - <b>{date}</b>'
			},
			absences: {
				emptyTable: 'Aucune absence',
				buttonAdd: 'Ajouter une absence',
				popin: {
					titleAdd: 'Ajout d’une absence',
					titleEdit: 'Modification de l’absence',
					titleDelete: 'Confirmation de suppression de l’absence',
					delete: {
						info: 'En cliquant sur supprimer, vous confirmer la suppression de l’absence suivante :',
						periode: 'Période d’absence : du <b>{dateDebut}</b> au <b>{dateFin}</b>',
						jours: 'Nombre de jours : <b>{jours}</b>',
						typeAbsence: 'Type d’absence : <b>{typeAbsence}</b>'
					},
					dateDebutIndispo: 'Date de début *',
					dateFinIndispo: 'Date de fin',
					typeAbsence: 'Type d\'absence *',
					commentaire: 'Commentaire',
					typeAbsences: {
						maladie: 'Maladie',
						conges: 'Congés',
						autres: 'Autres'
					}
				},
				table: {
					dateDebut: 'Date de début',
					dateFin: 'Date de fin',
					typeAbsence: 'Type d\'absence',
					commentaire: 'Commentaire',
					nombreJours: 'Nombre de jours'
				},
				snackbar: {
					successCreate: 'L\'absence a bien été créée.',
					errorCreate: 'L\'absence n\'a pas été créée.',
					successEdit: 'L\'absence a bien été sauvegardée.',
					errorEdit: 'L\'absence n\'a pas été sauvegardée.',
					successDelete: 'L\'absence a bien été supprimée.',
					errorDelete: 'L\'absence n\'a pas été supprimée.'
				}
			}
		},
		ajoutRessource: {
			title: 'Ajout d’une ressource',
			snackbar: {
				success: 'La ressource a bien été créée.',
				error: 'La ressource n\'a pas été créée.'
			},
			onglets: {
				informations: 'Informations générales',
				competences: 'Compétences',
				absences: 'Absences',
				contrats: 'Contrat'
			},
			form: {
				emptyUsers: 'Aucun compte utilisateur disponible, veuillez en créer un avant d\'ajouter une nouvelle ressource',
				competences: {
					recherche: 'Compétences qui commencent par ...',
					participant: 'Compétences participant',
					machiniste: 'Compétences machiniste',
					filtreTitle: 'Compétences :',
					filtreAcquise: 'Acquises',
					filtreNonAcquise: 'Non-acquises'
				},
				titles: {
					informations: 'Informations générales',
					competences: 'Compétences',
					absences: 'Absences',
					contrats: 'Heure de début et temps de travail',
					contratSub: 'Par défaut, les horaires correspondent aux horaires de la ferme.',
					hourDepend: 'Les heures de début de la ressource sont dépendantes des heures de début de la ferme',
					absenceSub: 'Vous pouvez ajouter une période pendant laquelle la ressource sera absente. Elle ne sera affectée à aucune tâche.'
				},
				fields: {
					prenom: 'Prénom *',
					nom: 'Nom *',
					typeContrat: 'Type de contrat *',
					typeRessource: 'Type de ressource *',
					debutContrat: 'Date de début *',
					finContrat: 'Date de fin',
					finContratWithDot: 'Date de fin *',
					debut: 'Heure de debut *',
					tempsTravail: 'Temps de travail *',
					tempsTravailHour: '{hour} par jour',
					minutes: 'Minute(s)',
					user: 'Compte utilisateur lié *',
					ressources: {
						manager: 'Manager RH',
						chef: 'Chef de culture',
						superviseur: 'Superviseur',
						maraicher: 'Maraîcher'
					},
					weekDay: {
						lundi: 'Lundi',
						mardi: 'Mardi',
						mercredi: 'Mercredi',
						jeudi: 'Jeudi',
						vendredi: 'Vendredi',
						samedi: 'Samedi',
						dimanche: 'Dimanche'
					}
				}
			}
		},
		header: {
			ressources: 'ressource(s)',
			duration: '/semaine',
			title: 'Liste de l’ensemble des ressources '
		},
		filter: {
			recherche: 'Nom qui commence par ...',
			contrat: {
				title: 'Type de contrat : ',
				interim: 'Intérim',
				cdi: 'CDI',
				stage: 'Stage',
				cdd: 'CDD',
				groupementEmployeurs: 'Groupement d’employeurs'
			},
			statut: {
				title: 'Statut : ',
				disponible: 'Disponible',
				indisponible: 'Indisponible',
				inactif: 'Inactif'
			}
		},
		table: {
			headers: {
				nom: 'Nom',
				prenom: 'Prénom',
				typeRessource: 'Type de ressource',
				typeContrat: 'Type de contrat',
				debut: 'Début du contrat',
				fin: 'Fin du contrat',
				statut: 'Statut',
				horaire: 'Horaires par défaut',
				dateFin: '(Jusqu\'au {dateFin})',
				finContrat: '(Fin de contrat)',
				inactive: 'Inactif'
			},
			schedule: {
				ferme: 'Ferme',
				ressource: 'Ressource'
			},
			codeProfil: {
				MANAGER: 'Manager',
				MARAICHER: 'Maraîcher',
				SUPERVISEUR: 'Superviseur',
				CHEF_CULTURE: 'Chef de culture'
			}
		},
		snackbar: {
			success: {
				successInit: 'Initialisation de la planification terminée',
				verifRessources: 'Ressources mises à jour',
				verifRessourcesNoChange: 'Vérification terminée sans modification des ressources'
			},
			errors: {
				echecInit: 'Initialisation de la planification impossible, une erreur est survenue',
				verifRessources: 'Vérification impossible, une erreur est survenue',
				initializeBefore: 'Merci d\'initialiser la semaine au préalable.'
			}
		},
		toolbar: {
			ajoutRessource: 'Ajouter une ressource',
			gestionRessource: 'Suivi des ressources'
		}
	},
	gestionTaches: {
		toolbar: {
			text: {
				planificationDesTaches: 'Planification des tâches',
				contentToobar1: 'Contrôle des ressources et calcul des capacités de travail',
				contentToobar2: 'Planification des tâches sur la semaine et contrôles des charges de travail par rapport aux capacités',
				contentToobar3: 'Affectation des tâches quotidiennes et contrôle des charges de travail par rapport à la capacité de chaque ressource',
				contentToobar4: 'Ordonnancement des tâches quotidiennes'
			},
			buttons: {
				ajouterTache: 'Ajouter une tâche',
				validerPlanification: 'Valider la planification',
				autresActions: 'Autres actions',
				verifRessources: 'Vérification des ressources',
				exportPlanning: 'Exporter le planning actuel',
				initialize: 'Initialisation de la planification',
				planification: 'Planifier automatiquement',
				approuver: 'Approuver la solution'
			},
			popin: {
				titre: 'Lancement de la planification automatique',
				description: 'En cliquant sur confirmer, vous allez lancer une planification automatique des tâches. Veuillez sélectionner la période pour laquelle vous souhaitez lancer la planification.',
				switch: {
					week: '3 semaines',
					weekResult: 'Semaines n°{numeroStart} à {numeroEnd} (du {dateStart} au {dateEnd})',
					date: 'A date',
					dateResult: '{dateStart} et {dateEnd}'
				},
				informationNotification: 'Une notification vous sera envoyée lorsque la solution sera disponible.',
				validation: 'Confirmer'
			},
			selecteur: {
				week: '<b>Semaine n°{weekNumber}</b>du {start} au {end}'
			},
			boutons: {
				autresActions: {
					decalerTacheSemaineSuivante: 'Décaler les tâches à la semaine suivante',
					decalerTacheSemainePrecedente: 'Décaler les tâches à la semaine précédente',
					planifierLundi: 'Planifier au Lundi',
					planifierMardi: 'Planifier au Mardi',
					planifierMercredi: 'Planifier au Mercredi',
					planifierJeudi: 'Planifier au Jeudi',
					planifierVendredi: 'Planifier au Vendredi',
					planifierSamedi: 'Planifier au Samedi',
					planifierDimanche: 'Planifier au Dimanche',
					supprimerTache: 'Supprimer les tâches',
					divider: ' '
				}
			},
			actions: {
				move: {
					popin: {
						title: 'Déplacement impossible',
						text: 'Attention, certaines tâches ne peuvent être déplacées. Veuillez modifier votre sélection puis réessayer',
						message: 'Tâches ne pouvant être déplacées :',
						labelInterval: '<b>{type}</b> {culture} ({lieu}) : la tâche doit s’effectuer pendant l\'étape {etape} (<b>{debut}</b> - <b>{fin}</b>)',
						labelEtat: '<b>{type}</b> {culture} ({lieu}) : la tâche est {etat}'
					},
					success: 'Déplacement effectué avec succès',
					error: 'Déplacement impossible, une erreur est survenue'
				},
				delete: {
					popin: {
						error: {
							title: 'Suppression impossible',
							text: 'Attention, certaines tâches ne peuvent être supprimées. Veuillez modifier votre sélection puis réessayer',
							message: 'Tâches ne pouvant être supprimées :',
							label: '<b>{type}</b> {culture} ({lieu}) : la tâche est terminée'
						},
						validation: {
							title: 'Suppression de tâches',
							text: 'Attention, vous êtes sur le point de supprimer des tâches. Cette suppression est définitive.',
							message: 'Tâches récurrentes :',
							label: {
								semaine: '<b>{type}</b> {culture} ({lieu}) : Récurrence les <b>{jours}</b> toutes les <b>{nombre} semaine(s)</b> du <b>{debut}</b> au <b>{fin}</b>',
								mois: '<b>{type}</b> {culture} ({lieu}) : Récurrence tous les <b>{nombre} mois</b> du <b>{debut}</b> au <b>{fin}</b>'
							},
							form: {
								title: 'Supprimer',
								choixTache: 'Uniquement ces tâches',
								choixRecurrence: 'Ces tâches et toutes les suivantes'
							}
						}
					},
					success: 'Suppression enregistrée',
					error: 'Suppression impossible, une erreur est survenue'
				}
			}
		},
		ecranSynthese: {
			week: 'Semaine n°{weekNumber}',
			error: 'Attention {semaines} {quantity, plural, one {n’a pas été initialisée} other {n’ont pas été initialisées}}. Cela peut provoquer des données vide dans les indicateurs.',
			errorDetail: 'la semaine n°{semaine}',
			stats: {
				capaciteTotale: 'Capacité de travail totale',
				chargeTotale: 'Charge de travail totale',
				ressourcesDispo: 'Ressources disponibles',
				ressourcesPlanifiees: 'Ressource planifiées',
				tachesPrevues: 'Tâches prévues',
				tachesPlanifiees: 'Tâches planifiées'
			},
			planificationVsCharge: {
				title: 'Planification vs. Charge de travail',
				subtitle: 'Le besoin en travail comparé au nombre d\'heures planifiées et à la capacité de travail.',
				graph: {
					chargeTravail: 'Charge de travail',
					tempsTravail: 'Temps de travail',
					capaciteTravail: 'Capacité de travail',
					nbHeures: 'Nombre d\'heures'
				}
			},
			details: {
				title: 'Détails sur les tâches',
				link: 'Voir le détail',
				type: {
					tachesPlanifiees: 'Tâches planifiées',
					tachesNonPlanifiees: 'Tâches non planifiées'
				}
			},
			tachesReportOuAnticipation: {
				title: 'Tâches par report ou anticipation',
				subtitle: 'Détails des tâches sur les 3 prochaines semaines en fonction de leur nombre de semaine de report ou d’anticipation.',
				type: {
					moinsDeux: '2 semaines d\'anticipation',
					moinsUn: '1 semaine d\'anticipation',
					zero: '0 semaine d\'anticipation',
					plusUn: '1 semaine de report',
					plusDeux: '2 semaines de report',
					plusTrois: '3 semaines de report',
					plusQuatreOuPlus: '4 semaines ou + de report'
				},
				table: {
					semaine: 'Semaines',
					tachesAnticipee: 'Tâches anticipées',
					tachesDansDelais: 'Tâches dans les délais',
					tachesReportees: 'Tâches reportées'
				},
				graph: {
					charge: 'Nombre d\'heures'
				}
			},
			tachesParFamille: {
				title: 'Tâches par famille',
				subtitle: 'Détails des tâches planifiées sur les 3 prochaines semaines en fonction de leur familles de tâche.',
				detail: 'Total sur les 3 semaines',
				famille: {
					DES: 'Désherbage',
					RECOLTE: 'Récolte (R ou FR)',
					SEMIS: 'Semis (SD ou SC)',
					IMPLANTATION: 'Repiquage',
					AUTRES: 'Autres',
					PREP_PLANCHE: 'Préparation de planche',
					SOINS_PROTECTION: 'Soins et protection'
				},
				graph: {
					nbHeures: 'Nombre d\'heures de tâche'
				}
			}
		},
		ecranRessources: {
			header: {
				ressources: 'ressource(s)',
				duration: '/semaine',
				title: 'Contrôle des ressources et calcul des capacités de travail'
			},
			filter: {
				title: 'Filtrer sur : ',
				interim: 'les ressources en intérim',
				cdi: 'les ressources en CDI',
				stage: 'les ressources en stage',
				cdd: 'les ressources en CDD',
				groupementEmployeurs: 'les ressources en groupement d’employeurs'
			},
			table: {
				headers: {
					ressource: 'Ressource',
					total: 'Total hebdomadaire',
					debut: 'début à',
					tempsTravail: 'temps de travail',
					lundi: 'LUNDI',
					mardi: 'MARDI',
					mercredi: 'MERCREDI',
					jeudi: 'JEUDI',
					vendredi: 'VENDREDI',
					samedi: 'SAMEDI',
					dimanche: 'DIMANCHE'
				}
			},
			popin: {
				title: {
					initialisationPlanification: 'Initialisation de la planification'
				},
				content: {
					texte: 'Aucune planification n’existe pour la semaine sélectionnée. L’initialisation de la planification peut durer quelques minutes.'
				},
				boutons: {
					initialize: 'Initialiser'
				}
			},
			snackbar: {
				success: {
					successInit: 'Initialisation de la planification terminée',
					verifRessources: 'Ressources mises à jour',
					verifRessourcesNoChange: 'Vérification terminée sans modification des ressources'
				},
				errors: {
					echecInit: 'Initialisation de la planification impossible, une erreur est survenue',
					verifRessources: 'Vérification impossible, une erreur est survenue',
					initializeBefore: 'Merci d\'initialiser la semaine au préalable.'
				}
			}
		},
		ecranPlanification: {
			header: {
				tachesSelectionnees: 'Charge tâches sélectionnées',
				capaciteTotale: 'Capacité totale',
				chargeTotale: 'Charge de travail totale',
				actions: {
					move: 'Décaler {quantity, plural, one {la tâche} other {les tâches}}',
					addInstruction: 'Ajouter une instruction',
					delete: 'Supprimer {quantity, plural, one {la tâche} other {les tâches}}'
				}
			},
			table: {
				headers: {
					tache: 'Tache',
					lieu: 'Lieu',
					statut: 'Statut',
					priorite: 'Priorité',
					chargeTravail: 'Charge de travail',
					chargeParPersonne: 'Charge par personne',
					nbPersonnes: 'Personnes nécessaires',
					lundi: 'LUNDI',
					mardi: 'MARDI',
					mercredi: 'MERCREDI',
					jeudi: 'JEUDI',
					vendredi: 'VENDREDI',
					samedi: 'SAMEDI',
					dimanche: 'DIMANCHE'
				},
				lieu: {
					ferme: 'Toute la ferme',
					zone: 'Zone {nom}',
					bloc: 'Bloc {nom}',
					surface: 'Surface {nom}'
				}
			},
			ajout: {
				title: 'Ajout d\'une tâche',
				form: {
					fields: {
						categorie: 'Catégorie de tâche',
						famille: 'Famille de la tâche',
						priorite: 'Priorité de la tâche',
						type: 'Type de tâche',
						surface: 'Surface',
						assolement: 'Culture assolée',
						assolementEmpty: 'Aucune culture assolée disponible',
						parametres: {
							divisible: 'Tâche divisible',
							recurrente: 'Tâche récurrente'
						},
						modele: {
							title: 'Modèle de tâche',
							label: {
								id: 'id: ({id})'
							}
						},
						categorieTechnique: 'Catégorie technique: {code}',
						duree: 'Durée: {duree}',
						periodeNombre: 'Tou(te)s les',
						periodeType: 'Période',
						jours: 'Répeter les',
						debut: 'À partir du',
						fin: 'Jusqu\'au',
						date: 'Tâche prévue le',
						lockDate: 'Cette date a été verrouillée, le planificateur ne modifiera pas cette donnée'
					},
					errors: {
						mandatory: 'Ce champ est obligatoire',
						tooLong: 'Le champ dépasse le nombre de {sizeMax} caractères autorisés',
						dateBefore: 'La date doit être après la date de début',
						dateNotInWeek: 'La date doit être dans la semaine sélectionnée',
						wrongFormat: 'Le champ n\'a pas le bon format',
						noModele: 'Aucun modèle de tâche ne correspond'
					},
					detail: {
						title: 'Informations sur le modèle de tâche de référence',
						ressources: '<b>Ressources nécessaires</b> : {nbPersonnes}{flagDivisible, select, true { (tâche divisible)} other {}}',
						duree: '<b>Durée de la tâche :</b> {duree}',
						categorie: 'Catégorie <b>{categorie}</b>',
						materiel: '<b>Matériel indispensable :</b>',
						sensibilite: '<b>Sensibilités pédoclimatiques :</b>',
						competences: '<b>Compétences :</b>',
						description: '<b>Description :</b> {description}',
						error: 'Aucun modèle de tâche ne correspond aux valeurs choisies'
					}
				},
				popin: {
					recurrence: {
						title: 'Ajout de tâches récurrentes',
						texte: 'La tâche que vous souhaitez ajouter est une tâche récurrente. En cliquant sur confirmer, les tâches suivantes vont également être créées pour la culture {culture} sur la surface {surface} :',
						info: '<b>{type}</b> prévu le <b>{date}</b>'
					}
				},
				success: 'Nouvelle(s) tâche(s) enregistrée(s)',
				error: 'Ajout impossible, une erreur est survenue'
			},
			move: {
				title: 'Décalage {quantity, plural, one {de la tâche} other {des tâches}}',
				field: 'Déplacer au'
			},
			filter: {
				fields: {
					culture: 'Culture',
					lieu: 'Lieu',
					tache: 'Tâche',
					chargeSup: 'Supérieure à',
					chargeInf: 'Inférieure à',
					statut: 'Statut',
					chargeTravail: 'Charge de travail',
					chargePersonne: 'Charge par personne',
					checkbox: 'Afficher uniquement les tâches non affectées',
					retard: 'Tâche en retard'
				},
				title: 'Filtrer les tâches'
			}
		},
		ecranAffectations: {
			header: {
				title: 'Affectation des tâches quotidiennes et contrôle des charges de travail par rapport à la capacité de chaque ressource',
				tachesSelectionnees: 'Charge tâches sélectionnées',
				capaciteTotale: 'Capacité totale / {jour}',
				chargeTotale: 'Charge de travail totale / {jour}'
			},
			table: {
				headers: {
					tache: 'Tache',
					lieu: 'Lieu',
					statut: 'Statut',
					priorite: 'Priorité',
					chargeTravail: 'Charge de travail',
					chargeParPersonne: 'Charge par personne',
					nbPersonnes: 'Personnes nécessaires'
				}
			},
			panels: {
				titre: {
					details1: '{nomTache}',
					details2: '{nomTache} - {nomCulture}',
					suiviTache: 'Suivi de la tâche',
					affectation: 'Modifier les affectations'
				},
				sections: {
					ressources: 'Ressources',
					affectation: 'Affectation',
					taches: 'Tâches concernées',
					infoTache: '{etat}',
					culture: '{culture}',
					surface: 'Surface {surface}',
					materiel: 'Matériel indispensable'
				},
				content: {
					descriptionTache: '{description}',
					realisation: 'Réalisation prévue le {date}',
					recurrenceSemaine: 'Récurrence {jours} toutes les {periode} semaines du {dateDebut} au {dateFin}',
					recurrenceMois: 'Récurrence tous les {periode} mois du {dateDebut} au {dateFin}',
					infosTacheDivisible: 'Tâche divisible - {personnes} minimum',
					infosTacheIndivisible: 'Tâche indivisible - {personnes} minimum',
					estimationTempsTache: 'Temps estimé par tâche : {duree}',
					priorite: 'Priorité de la tâche : <b>{priorite}</b>',
					estimationTempsPersonne: 'Temps estimé par personne : {duree}',
					cultureInfos: '{famille} > {espece} > {variete}',
					surfaceDestination1: '{zone} > Bloc {typeBloc}',
					surfaceDestination1BUT: '{zone}',
					surfaceDestination2: 'Planche {typePlanche} - {aireSurface} m²',
					surfaceDestination2SansSuperficie: 'Planche {typePlanche}',
					itemListePersonne: '{nom} : {tempsPasse} passé',
					itemListePersonneDesaffecte: '{nom} : {tempsPasse} passé (désaffecté)',
					commentaireLabel: 'Commentaire',
					etat: 'État',
					checkboxRealiser: 'À réaliser',
					checkboxEnCours: 'En cours',
					checkboxTermine: 'Terminé',
					tempsPasse: 'Temps passé',
					tempsPasseAdornement: 'Minute(s)',
					affectation: 'Ajouter une ressource',
					desaffectation: 'Supprimer une ressource',
					ressources: '<b>Ressources actuellement affectées :</b> {ressources}'
				},
				buttons: {
					instructions: 'Afficher les instructions',
					suivi: 'Ajouter un suivi'
				},
				multiSelectLabel: {
					userAffectation: 'Utilisateur(s) à affecter',
					userDesaffectation: 'Utilisateur(s) à désaffecter',
					suiviProd: 'Affectée à'
				},
				validate: {
					champVide: 'Ce champ est obligatoire'
				}
			},
			snackbar: {
				success: {
					affectation: 'Affectation enregistrée',
					desaffectation: 'Désaffectation enregistrée',
					suivi: 'Suivi enregistré'
				},
				errors: {
					affectation: 'Affectation impossible, une erreur est survenue',
					desaffectation: 'Désaffectation impossible, une erreur est survenue',
					suivi: 'Échec de la sauvegarde du suivi'
				}
			},
			button: {
				affectation: 'Modifier les affectations'
			}
		},
		ecranOrdonnancement: {
			header: {
				title: 'Ordonnancement des tâches quotidiennes',
				tachesSelectionnees: 'Charge tâches sélectionnées',
				capaciteTotale: 'Capacité totale / {jour}',
				chargeTotale: 'Charge de travail totale / {jour}'
			},
			table: {
				headers: {
					tache: 'Tache',
					lieu: 'Lieu',
					statut: 'Statut',
					materiel: 'Matériel indispensable',
					chargeTravail: 'Charge de travail',
					chargeParPersonne: 'Charge par personne',
					nbPersonnes: 'Personnes nécessaires',
					affectations: 'Utilisateurs affectés à la tache'
				}
			},
			dragging: {
				elements: '{number, plural, one {# element} other {# elements}}'
			},
			validation: {
				snackSuccess: 'Planification enregistrée',
				tacheNonAffecteesPopin: {
					title: 'Validation de la semaine impossible',
					message: 'Certaines tâches de la semaine n’ont pas été affectées, veuillez toutes les affecter afin de pouvoir valider la planification de la semaine.'
				}
			}
		}
	},
	suiviConnexions: {
		title: 'Suivi des connexions smartphone',
		state: {
			online: 'En ligne',
			offline: 'Hors ligne'
		},
		table: {
			header: {
				user: 'Utilisateur',
				alias: 'Alias',
				state: 'État',
				lastConnection: 'Dernière connexion active'
			},
			date: 'Le {jour} à {heure}'
		},
		filter: {
			title: 'Filtrer les utilisateurs',
			reset: 'Réinitialiser les filtres',
			fields: {
				state: 'État',
				checkToday: 'Afficher uniquement les utilisateurs travaillant aujourd’hui'
			},
			submit: 'Filtrer'
		}
	},
	suiviAvancement: {
		toolbar: {
			title: 'Suivi d\'avancement des tâches',
			refreshButton: 'Mettre à jour la page',
			export: {
				titlePopin: 'Export du suivi des tâches',
				debut: 'Début de l\'export',
				fin: 'Fin de l\'export',
				exporter: 'Exporter',
				erreurDebut: 'Veuillez entrer une date valide',
				erreurFin: 'Veuillez entrer une date valide',
				erreurDebutApresFin: 'La date de début doit se situer avant la date de fin de l\'export'
			}
		},
		suiviGlobal: {
			tachePlanifiee: 'Tâches planifiées',
			tacheARealiser: 'Tâches à réaliser',
			tacheEnCours: 'Tâches en cours',
			tacheTerminee: 'Tâches terminées',
			chargeTravailTotalePlanifiee: 'Charge de travail totale planifiée',
			tempsPasse: '(temps passé)',
			chargePlanifiee: '(charge planifiée)',
			title: 'Suivi d\'avancement global de la journée'
		},
		suiviRessources: {
			titleGlobal: 'Suivi d\'avancement par ressource',
			header: {
				ressources: 'Ressources',
				tachePlanifiees: 'Tâches planifiées',
				tacheARealiser: 'Tâches à réaliser',
				tachesEnCours: 'Tâches en cours',
				tachesTerminees: 'Tâches terminées',
				nbTotal: 'NB total',
				nb: 'NB',
				chargeTotale: 'Charge totale',
				temps: 'Temps passé',
				chargePlanifiee: 'Charge planifiée',
				pourcentage: '%',
				tache: 'Tâche',
				lieu: 'Lieu',
				affectations: 'Affectations',
				statut: 'Statut de l\'affectation',
				charge: 'Charge planifiée'
			},
			pourcentage: '{value}%',
			panel: {
				fields: {
					cultureRecoltee: 'La culture a-t-elle été entièrement récoltée ?',
					quantiteCalibree: 'Quantité calibrée (kg)',
					quantiteHorsCalibre: 'Quantité hors calibre (kg)',
					quantiteNonCommercialisable: 'Quantité non commercialisable (kg)',
					errorTri: 'Veuillez remplir au moins l\'un de ces trois champs',
					errorRecolte: 'Ce champ est obligatoire'
				}
			},
			ressource: '<b>{alias}</b> - {prenom} {nom} {role}',
			retour: 'Retour',
			title: 'Suivi des tâches affectées à {alias} ',
			statut: '{statut}'
		}
	},
	tourPlaine: {
		toolbar: {
			title: 'Tour de plaine',
			action: {
				plan: 'Plan de la ferme',
				done: 'Tour de plaine terminé !'
			},
			selector: {
				week: 'Semaine n°{weekNumber} ({start} au {end})'
			}
		},
		tabBar: {
			contextePedoclimatique: 'Contexte Pédoclimatique',
			suiviPepiniere: 'Suivi Pépinière',
			suiviBlock: 'Suivi par bloc',
			suiviMaterials: 'Stock matériels'
		},
		stockMateriel: {
			title: 'Inventaire des outils',
			description: 'Vous pouvez définir ci-dessous la quantité des outils en quantité limitée de la ferme',
			empty: 'Aucun outil en quantité limitée',
			toaster: {
				sucess: 'Sauvegarde du stock réalisé avec succès',
				echec: 'Problème lors de la sauvegarde du stock'
			}
		},
		actions: {
			button: 'Actions',
			addReminder: 'Ajouter note',
			addTask: 'Ajouter tâche',
			others: 'Autres actions',
			details: 'Détails sur {quantity, plural, one {la culture} other {les cultures}}',
			moveToBoard: 'Déplacer l’étape de Préparation de planche',
			moveToSeedling: 'Déplacer l’étape de Semis en Contenant',
			moveTask: 'Décaler {quantity, plural, one {la tâche} other {les tâches}}',
			moveToNextStep: 'Déplacer l’étape à venir',
			moveToHarvestStep: 'Déplacer l’étape Récolte',
			moveToNextImplantStep: 'Déplacer l’étape Implantation',
			addToProd: 'Ajouter un suivi de production',
			adjustDuration: 'Ajuster la durée de récolte',
			addComment: 'Ajouter un commentaire',
			addInstruction: 'Ajouter une instruction',
			confirmHarvest: 'Confirmer la récolte',
			delete: 'Supprimer {quantity, plural, one {la culture} other {les cultures}}',
			deleteTask: 'Supprimer {quantity, plural, one {la tâche} other {les tâches}}',
			addBioAggressor: 'Ajouter des bioagresseurs',
			updateBioAggressor: 'Modifer la pression en bioagresseurs',
			addUnavailableSlot: 'Ajouter une période d’indisponibilité',
			updateUnavailableSlot: 'Modifier une période d’indisponibilité',
			deleteUnavailableSlot: 'Supprimer la période d’indisponiblité',
			deleteUnavailablesSlot: 'Supprimer les périodes d\'indisponibilités',
			cultureCrop: {
				suiviParBloc: 'Culture en cours',
				suiviPepiniere: 'Culture en pépinière'
			},
			nextCrop: {
				suiviParBloc: 'Culture suivante',
				suiviPepiniere: 'Culture sur planche de destination'
			},
			specifyGenericTask: 'Préciser la tâche générique'
		},
		form: {
			reminder: {
				title: 'Ajout d’une note',
				description: 'Cette note apparaîtra sur le tableau de bord du ou des destinataires',
				recipient: 'Destinataire(s) :',
				chief: 'Chef de culture',
				manager: 'Manager RH'
			},
			confirmHarvest: {
				title: 'Confirmation des récoltes',
				sectionTitle: `Culture {cropName} ({board})`,
				sectionDate: 'le {date}',
				otherTask: 'Autres tâches de récoltes de cultures assolées',
				confirmTask: 'Confirmer toutes les autres tâches de cette culture assolée',
				taskAlreadyConfirmed: ' (tâche de récolte déjà confirmée)'
			},
			specifyGenericTask: {
				title: 'Préciser la tâche générique',
				date: 'Prévue le <b>{date}</b>',
				surface: 'Surface <b>{surface}</b>',
				selection: 'Sélection de la variante',
				categorie: 'Catégorie technique <b>{categorie}</b>',
				tempsMoy: '<b>{temps} min/m²</b>',
				personnes: '<b>{nbPersonnes} {nbPersonnes, plural, one {personne} other {personnes}}</b> {nbPersonnes, plural, one {nécessaire} other {nécessaires}} {flagDivisible, select, true {(divisible)} other {(non divisible)}}',
				materiels: 'Materiel nécessaire : {materiels}'
			},
			moveTask: {
				title: {
					step: 'Déplacement de l’étape',
					task: 'Déplacement de la tâche'
				},
				sectionOne: {
					title: {
						step: 'Cultures concernées',
						task: 'Tâches concernées'
					},
					body: {
						harvestBetween: '(Récolte prévue du {start} au {end})',
						taskPlanned: `{task} prévu le {date} - {crop} ({board} - {size}m²)`
					}
				},
				sectionTwo: {
					title: {
						moveToBoard: 'Préparation de planche',
						moveToHarvestStep: 'Récolte',
						moveToNextImplantStep: 'Implantation',
						moveToSeedling: 'Semis en contenant',
						moveTaskPlanif: 'Nouvelle date'
					}
				}
			},
			instruction: {
				title: 'Nouvelle instruction',
				sectionOne: {
					title: 'Tâches concernées',
					body: '{task} - {crop} ({board}) prévu le {date}',
					bodyPlanification: '{task} prévu le {date} - Culture {crop} ({board} - {surface}m²) '
				},
				sectionTwo: {
					title: 'Instruction',
					info: 'Cette nouvelle instruction s’ajoute à celles déjà présentes sur les tâches sélectionnées.'
				}
			},
			updateTask: {
				title: 'Edition d’une tâche',
				deleteAction: 'Supprimer la tâche',
				updateAction: 'Modifier la tâche',
				fields: {
					date: 'Tâche planifiée le',
					priorite: 'Priorité de la tâche',
					instructions: 'Instructions pour les maraîchers',
					ressources: 'Ressources affectées',
					lockDate: 'Cette date a été verrouillée, le planificateur ne modifiera pas cette donnée',
					lockRessources: 'Ces ressources ont été verrouillées, le planificateur ne modifiera pas cette donnée',
					detail: {
						surface: 'Lieu : <b>{surface}</b>',
						ressource: '<b>Ressources nécessaires :</b> {nbPersonnes}',
						flagDivisible: '(tâche divisible)',
						duree: '<b>Durée de la tâche :</b> {duree}',
						anticipation: '<b>Anticipation</b> possible de <b>{nbJours}</b> jours',
						dateInitiale: 'Initialement prévue le <b>{date}</b>',
						categorie: 'Catégorie <b>{categorie}</b>',
						materiel: '<b>Matériel indispensable :</b>',
						sensibilite: '<b>Sensibilités pédoclimatiques :</b>',
						competences: '<b>Compétences :</b>',
						description: '<b>Description :</b> {description}'
					},
					errors: {
						mandatory: 'Ce champ est obligatoire'
					}
				}
			},
			submit: 'Enregistrer'
		},
		dataTable: {
			cell: {
				week: 'Semaine n°{weekNumber}',
				weekYear: 'Semaine n°{weekNumber} ({year})'
			}
		},
		dialog: {
			title: 'Tour de plaine terminé',
			canSaveTP: {
				description: 'Vous allez valider le tour de plaine. Vous pouvez également relancer la planification automatique pour mettre à jour le planning des cultures en fonction de vos modifications.',
				reminder: '<b>Remarque</b> : Si la planification automatique du planning de cultures n’est pas relancé, des incohérences peuvent apparaître entre l’état de la ferme et le planning.',
				checkbox: 'Je souhaite relancer la planification automatique pour le planning des cultures'
			},
			cantSaveTP: {
				description: 'Des informations sont manquantes dans {count, plural, one {l\'onglet suivant} other {les onglets suivants}} :',
				description2: 'Merci de compléter ces informations pour valider le tour de plaine.',
				mandatoryWeek: '(semaines n°{n} et n°{n1} obligatoires)',
				timeline: ' > {step}'
			},
			deleteTask: {
				title: 'Suppression des tâches',
				body: 'Attention, vous êtes sur le point de supprimer définitivement une ou plusieurs tâches : ',
				task: '<b>{type}</b> prévu le <b>{date}</b> - {crop} ({board} - {size}m²)'
			}
		},
		contextePedoclimatique: {
			title: 'Prévisions pédoclimatiques',
			description: 'Vous pouvez paramétrer les prévisions pédoclimatiques de la semaine à venir. Elles auront un impact sur les tâches et sur l’utilisation ou non d’outils spécifiques.',
			action: {
				halfDay: 'Voir à la ½ journée',
				fullDay: 'Voir à la journée',
				day: 'Jour',
				week: 'Semaine'
			},
			selector: {
				week: '<b>Semaine n°{weekNumber}</b>du {start} au {end}'
			},
			zone: 'Zone :',
			tileType: {
				title: 'Catégorie :',
				weather: 'Météo',
				humidity: 'Humidité de l’air',
				wind: 'Vent',
				temperature: 'Température',
				soil: 'Nature du sol'
			},
			icons: {
				weather: {
					pluvieux: {
						name: 'Pluvieux',
						description: 'Pluvieux'
					},
					ensoleille: {
						name: 'Ensoleillé',
						description: 'Ensoleillé'
					},
					nuageux: {
						name: 'Nuageux',
						description: 'Nuageux'
					},
					non_pluvieux: {
						name: 'Non pluvieux',
						description: 'Non pluvieux'
					}
				},
				humidity: {
					sec: {
						name: 'Temps sec',
						description: 'Sec (39-%)'
					},
					humide: {
						name: 'Temps humide',
						description: 'Humide (71+%)'
					},
					moyen: {
						name: 'Moyen',
						description: 'Moyen (40-70%)'
					}
				},
				wind: {
					vent_fort: {
						name: 'Vent fort',
						description: 'Fort ( + de 40km/h)'
					},
					vent_faible: {
						name: 'Vent faible',
						description: 'Faible (- de 40km/h)'
					}
				},
				temperature: {
					chaud: {
						name: 'Temps chaud',
						description: 'Chaud (25°C +)'
					},
					froid: {
						name: 'Temps froid',
						description: 'Froid (0 - 10°C)'
					},
					gel: {
						name: 'Gel',
						description: 'Gel (- 0°C)'
					},
					doux: {
						name: 'Doux',
						description: 'Doux (11 - 24°C)'
					}
				},
				soil: {
					sol_sec: {
						name: 'Sol sec',
						description: 'Sec'
					},
					sol_detrempe: {
						name: 'Sol détrempé',
						description: 'Détrempé'
					},
					sol_gele: {
						name: 'Sol gelé',
						description: 'Gelé'
					},
					sol_bon: {
						name: 'Bon',
						description: 'Bon'
					}
				}
			},
			impact: {
				BLOQUANT: 'Bloquant',
				INDESIRABLE: 'Indésirable',
				PREFERABLE: 'Préférable',
				INDISPENSABLE: 'Indispensable'
			},
			updatePanel: {
				title: 'Conditions pédoclimatiques',
				submit: 'Enregistrer'
			}
		},
		suiviParBloc: {
			title: 'Plan spatial de la ferme',
			description: 'Ci-dessous se trouve un plan de la ferme. Sélectionnez une surface pour saisir vos observations',
			plan: {
				placeholder: 'Afin de visualiser un plan de la ferme, vous pouvez en importer un dans le paramétrage de la ferme (Paramétrage > Gestion des fermes)'
			},
			actionTab: {
				crops: 'Cultures en cours',
				tasks: 'Tâches à venir',
				irrigation: 'Aspersion du bloc',
				bioAggressors: 'Bio-agresseurs',
				boardsUnavailable: 'Planches indisponibles'
			},
			crops: {
				description: 'Ci-dessous les cultures en cours et à venir sur les planches du bloc sélectionné et pour la semaine sélectionnée.',
				description1: 'La colonne Etape affiche, pour la culture en cours, l’étape de l’ITK prévue sur la semaine sélectionnée.',
				description2: 'Le début d’itinéraire de la culture suivante correspond à l’étape Préparation de planche pour la culture à venir.',
				table: {
					headers: {
						board: 'Planche',
						crop: 'Culture en cours',
						step: 'Etape',
						startHarvestDate: 'Début de la récolte',
						endHarvestDate: 'Fin de la récolte',
						nextCrop: 'Culture suivante',
						plannedPrepDate: 'Début de préparation'
					},
					empty: 'Aucune culture en cours ne correspond à vos critères'
				}
			},
			tasks: {
				filter: 'Filtrer sur :',
				harvest: 'les tâches de récolte',
				waiting: 'les tâches en attente d’arbitrage',
				table: {
					headers: {
						family: 'Famille',
						task: 'Tâche',
						crop: 'Culture',
						board: 'Surface',
						plannedDate: 'Date prévue',
						material: ' Matériel(s)',
						arbitrage: 'Arbitrage',
						initialDate: 'Date initiale'
					},
					cells: {
						taskHarvest: 'Récolte à confirmer',
						taskGeneric: 'Tâche générique à préciser',
						taskWaiting: 'Retard à traiter',
						lateWeek: '({nbWeek} semaine{nbWeek, plural, one {} other {s}})'
					},
					empty: 'Aucune tâche à venir ne correspond à vos critères.'
				}
			},
			irrigation: {
				description: 'Les périodes d’irrigation par aspersion du bloc peuvent être paramétrées afin de bloquer la réalisation de tâches sur la surface concernée. Vous pouvez renseigner les créneaux des 3 prochaines semaines.',
				action: {
					duplicate: 'Dupliquer les créneaux de la semaine précédente'
				},
				panel: {
					title: {
						add: 'Ajout d’un créneau d’irrigation',
						delete: 'Suppression d’un créneau d’aspersion'
					},
					datePrefix: 'Le',
					information: 'une irrigation par aspersion aura lieu sur le <b>bloc {blockName}</b> sur les créneaux horaires suivants :',
					submit: 'Enregistrer'
				},
				dialog: {
					title: 'Suppression d\'un créneau d’aspersion',
					description: 'Le créneau d’irrigation suivant sera supprimé :',
					period: 'le <b>{dayLabel}</b> de <b>{start}</b> à <b>{end}</b>'
				}
			},
			bio: {
				title: 'Pression en bioagresseurs',
				description: 'Certaines planches peuvent être sous pression de bio-agresseurs, vous pouvez préciser la surface et la période concernée. Ce paramètre aura un impact sur les cultures assolées',
				filter: 'Filtrer sur :',
				boardUnderPressure: 'les planches sous pression d’un ou plusieurs bioagresseurs',
				panel: {
					title: {
						add: 'Pression en bio-agresseurs',
						update: 'Modification de la pression'
					},
					boards: 'Planche(s) :',
					selectedBoards: 'Planches sélectionnées :',
					bioAggressors: 'Bioagresseur :',
					concernedBoards: 'Planches concernées :',
					listBio: 'Liste des bioagresseurs',
					period: 'Période :',
					date: {
						start: 'du',
						end: 'au'
					},
					submit: 'Enregistrer'
				}
			},
			boardUnavailable: {
				description: 'Chaque planche peut être notée comme indisponible durant une période de temps donnée. Ce paramètre aura un impact sur les cultures assolées.',
				description2: 'Chaque planche ne peux avoir qu\'une seule période d\'indisponibilité.',
				filter: 'Filtrer sur :',
				boardAvailable: 'les planches indisponibles uniquement',
				dialog: {
					singleTitle: 'Suppression de la période d\'indisponibilité',
					title: 'Suppression des périodes d\'indisponibilités',
					singleDescription: 'La période d’indisponibilité suivante sera supprimée :',
					description: 'Les périodes d’indisponibilités suivantes seront supprimées :',
					slots: 'Planche <b>{block}</b> : <b>{start}</b> au <b>{end}</b>'
				},
				popin: {
					title: 'Surface occupée',
					description: 'Attention, la planche que vous souhaitez rendre indisponible sur la période du <b>{dateDebut}</b> au <b>{dateFin}</b> est associée aux cultures suivantes : ',
					descriptionEnCours: 'Attention, la planche que vous souhaitez rendre indisponible sur la période du <b>{dateDebut}</b> au <b>{dateFin}</b> est associée aux cultures en cours suivantes : ',
					infoSuppression: 'En cliquant sur confirmer, vous supprimez aussi les cultures ci-dessus ainsi que les tâches associées.',
					infoEnCours: 'Afin de rendre cette planche indisponible, elle doit tout d\'abord être libérée de ses cultures en cours. Ces modifications peuvent être réalisés depuis le planning ou le tour de plaine.',
					cultureValue: '<b>Culture {culture}</b> : itinéraire du <b>{dateDebut}</b> au <b>{dateFin}</b>',
					buttons: {
						save: 'Confirmer'
					}
				},
				panel: {
					title: {
						add: 'Ajout d’indisponiblité',
						update: 'Modification d’indisponiblité'
					},
					boards: 'Planche(s) :',
					unavailable: 'seront indisponibles',
					selectedBoards: 'Planches sélectionnées :',
					date: {
						start: 'du',
						end: 'au'
					},
					commentLabel: 'Commentaires actuels :',
					commentByBoard: 'Planche {board} : "{comment}"',
					comment: 'Commentaire (facultatif)',
					submit: 'Enregistrer'
				}
			}
		},
		suiviPepiniere: {
			actionTab: {
				crops: 'Cultures en pépinière',
				tasks: 'Tâches à venir'
			},
			crops: {
				title: 'Cultures en pépinière',
				description: 'Ci-dessous sont affichées les cultures en pépinière pour la semaine sélectionnée',
				table: {
					headers: {
						crop: 'Culture en cours',
						step: 'Etape',
						seedlingDate: 'Date de semis',
						board: 'Planche prévue',
						plannedPrepDate: 'Préparation prévue',
						plannedImplantDate: 'Implantation prévue',
						currentHarvest: 'Culture en cours (sur la planche)',
						endHarvestDate: 'Fin de récolte prévue'
					},
					empty: 'Aucune culture en cours ne correspond à vos critères'
				}
			},
			tasks: {
				filter: 'Filtrer sur :',
				transplanting: 'les tâches de repiquage',
				waiting: 'les tâches en attente d’arbitrage',
				table: {
					headers: {
						task: 'Tâche',
						crop: 'Culture',
						seedlingDate: 'Date de semis',
						targetBoard: 'Planche destination',
						plannedDate: 'Tâche prévue le',
						arbitrage: 'Arbitrage',
						initialDate: 'Date initiale'
					},
					cells: {
						week: 'Semaine n°{weekNumber}',
						taskWaiting: 'Retard à traiter',
						lateWeek: '({nbWeek} semaine{nbWeek, plural, one {} other {s}})'
					},
					empty: 'Aucune tâche à venir ne correspond à vos critères.'
				}
			}
		}
	},
	mobile: {
		title: {
			finDeTache: 'Fin de tâche',
			demarrageImpossible: 'Démarrage impossible'
		},
		h1: {
			tacheARealiser: 'Tâche à réaliser : ',
			tachesRestantes: 'Mes tâches restantes :',
			tachesTerminees: 'Mes tâches terminées: '
		},
		content: {
			affectation: '{prenom} ({alias})',
			tempsNecessaire: ' {tempsNecessaire} nécessaires',
			tempsNecessaireCommence: ' {tempsNecessaire} nécessaires - {tempsPasse} passées'
		},
		buttons: {
			demarrageImpossible: 'Démarrage impossible',
			demarrer: 'Démarrer la tâche',
			pause: 'Mettre en pause',
			termine: 'Terminer',
			reprendre: 'Reprendre',
			saisieCommentaire: 'Saisie du commentaire',
			finSaisieCommentaire: 'Stopper l\'enregistrement',
			placeholderCommentTermine: 'Nouveau commentaire',
			placeholderCommentImpossible: 'Raison de l\'impossibilité'
		},
		fields: {
			instructions: 'Instructions : ',
			cultureRecoltee: 'La culture a t-elle été entièrement récoltée ?',
			quantiteCalibre: 'Quantité calibrée (kg)',
			quantiteHorsCalibre: 'Quantité hors calibre (kg)',
			quantiteNonCommercialisable: 'Quantité non commercialisable (kg)'
		},
		error: {
			unDesTroisChampRequired: 'Le total de la quantité triée doit être supérieur à 0'
		},
		snackbar: {
			error: {
				erreurReseau: 'Erreur réseau'
			}
		},
		bandeau: 'Vous n\'êtes plus connecté à internet'
	},
	bo: {
		accueil: {
			title: 'Bienvenue sur le back office de Permasoft'
		},
		utilisateur: {
			title: 'Gestion des utilisateurs',
			newBtn: 'Nouvel utilisateur',
			liste: 'Liste des utilisateurs',
			inputQuery: 'Prénom, Nom ou Alias de l\'utilisateur',
			onlyActif: 'Afficher uniquement les utilisateurs actifs',
			headers: {
				utilisateur: 'Utilisateur',
				alias: 'Alias',
				profil: 'Profil',
				ferme: 'Ferme',
				etat: 'État',
				etatActif: 'Actif',
				etatInactif: 'Inactif'
			},
			codeProfil: {
				MANAGER: 'Manager',
				MARAICHER: 'Maraîcher',
				SUPERVISEUR: 'Superviseur',
				CHEF_CULTURE: 'Chef de culture'
			},
			form: {
				titleNew: 'Nouvel utilisateur',
				titleUpdate: 'Modification de l\'utilisateur',
				contrat: 'Contrat :',
				deleteUser: 'Supprimer l\'utilisateur',
				fields: {
					firstname: 'Prénom',
					lastname: 'Nom',
					alias: 'Alias',
					email: 'Adresse e-mail',
					ferme: 'Ferme',
					profil: 'Profil',
					typeContrat: 'Type de contrat',
					debutContrat: 'Début du contrat',
					finContrat: 'Fin du contrat',
					fin_contrat_connue: 'La date de fin du contrat est connue',
					lundi: 'Lundi',
					mardi: 'Mardi',
					mercredi: 'Mercredi',
					jeudi: 'Jeudi',
					vendredi: 'Vendredi',
					samedi: 'Samedi',
					dimanche: 'Dimanche',
					actif: 'Utilisateur actif'
				},
				champRequis: 'Champ requis',
				submit: 'Enregistrer',
				informationsGenerales: 'Informations générales: ',
				notUniqueAlias: 'Alias déjà utilisé',
				notUniqueEmail: 'Email déjà utilisé',
				erreurDebutApresFin: 'Le date de début de contrat doit être avant la fin'
			}
		},
		blocSurface: {
			title: 'Gestion des blocs et surfaces',
			newBtn: 'Nouvel ajout',
			inputQuery: 'Nom de la surface',
			titreTuileSurface: 'Surface {nom} ({zone})',
			titreTuileBloc: 'Bloc {nom}',
			titreTuileSurfacesSansBlocLie: 'Surfaces non liées à un bloc',
			descriptionSurface: '{aire}m² - {typeSurface}',
			descriptionBloc: '{zone}',
			titreGauche: 'Liste des blocs et surfaces :',
			titreDroit: '{entite} {nom}',
			error: 'Ce champ est obligatoire',
			forms: {
				labels: {
					blocNom: 'Nom du bloc',
					blocZone: 'Zone du bloc',
					blocAspersion: 'Type d\'irrigation du bloc',
					aspersion: {
						ASPERSION: 'Aspersion',
						GAG: 'Gouttes à gouttes',
						ASPERSION_GAG: 'Gouttes à gouttes et aspersion'
					},
					surfaceNom: 'Nom de la surface',
					surfaceCheckBox: 'La surface n\'est pas liée à un bloc',
					surfaceBloc: 'Bloc',
					surfaceZone: 'Zone',
					surfaceType: 'Type de surface',
					surfaceDimension: 'Dimension de la surface',
					surfaceLongueur: 'Longueur de la surface',
					surfaceLargeur: 'Largeur de la surface',
					surfaceInactive: 'Surface désactivée',
					surfaceIndisponible: 'Surface indisponible'
				}
			},
			popin: {
				title: 'Nouvel ajout',
				infos: 'Sélectionner le type d\'ajout puis compléter les informations liées.',
				labels: {
					typeAjout: 'Type d\'ajout',
					infosNouveauBloc: 'Informations du nouveau bloc',
					infosNouvelleSurface: 'Informations de la nouvelle surface'
				}
			},
			snackbar: {
				success: {
					modificationBloc: 'Le bloc a bien été mis à jour',
					modificationSurface: 'La surface a bien été mise à jour',
					creationBloc: 'Le bloc a bien été créé',
					creationSurface: 'La surface a bien été créée',
					suppressionAssolementPlancheIndisponible: 'Les cultures assolées ont bien été supprimées',
					plancheIndisponible: 'Les planches indisponibles ont été mises à jour'
				},
				errors: {
					modificationBlocAssolement: 'Modification de la zone impossible : une culture assolée est lié au bloc',
					modificationSurfaceAssolement: 'Modifications impossibles : une culture assolée est lié à la surface',
					modificationBlocDuplicationNom: 'Modification du nom impossible : un autre bloc porte ce nom',
					modificationSurfaceDuplicationNom: 'Modification du nom impossible : une autre surface porte ce nom',
					creationBlocDuplicationNom: 'Création impossible : un autre bloc porte ce nom',
					creationSurfaceBlocMatriceVide: 'Veuillez mettre à jour la matrice de proximité',
					creationSurfaceDuplicationNom: 'Création impossible : une autre surface porte ce nom',
					plancheIndisponible: 'Impossible de mettre à jour les planches indisponibles'
				}
			}
		},
		cultureItk: {
			title: 'Gestion des cultures et ITKs',
			newBtn: 'Nouvel ajout',
			inputQuery: 'Nom de la culture',
			titleLeft: 'Liste des cultures et ITKs associés',
			alerteBar: 'ITK désactivé pour les campagnes suivantes : {campagnes}',
			popinButton: 'Désactiver l’ITK',
			popinDesactive: {
				title: 'Désactivation d’un ITK',
				description: 'Désactiver l’ITK signifie qu’il ne sera pas utilisé par le planificateur de culture.',
				textValue: 'Désactiver l’ITK pour la {name}',
				confirmation: 'CONFIRMER'
			},
			popin: {
				title: 'Nouvel ajout'
			},
			form: {
				fields: {
					itk: {
						nomItk: 'Nom de l\'ITK',
						numItk: 'Numéro de l\'ITK',
						debutSemis: 'Début de la période de semis (N° de semaine)',
						nbSemainesSemis: 'Durée de la période de semis conseillée',
						nbSemainesSemisAdornment: 'semaines',
						timelineItk: 'Timeline du début de l\'ITK',
						zone: 'Zone(s)',
						surface: 'Type de surface',
						nbRangs: 'Nombre de rang',
						nbRangsAdornment: 'rangs',
						densite: 'Densité',
						densiteAdornment: 'plant/m²',
						espacement: 'Espacement',
						espacementAdornment: 'cm',
						saisonName: 'Saison',
						saison: {
							hiver: 'Hiver',
							ete: 'Été',
							printemps: 'Printemps',
							automne: 'Automne'
						},
						voileThermique: 'Voile thermique requis',
						nbGraines: 'Nombre de graines par trou',
						prodTheo: 'Production théorique maximale',
						prodTheoAdornment: 'kg/graines',
						typeItk: 'Type de l\'ITK',
						debutSemisC: 'Timeline du début de l\'étape Semis en contenant',
						debutSemisD: 'Timeline du début de l\'étape Semis direct',
						debutPreparation: 'Timeline du début de l\'étape Préparation de planche',
						debutImplantation: 'Timeline du début de l\'étape Implantation',
						debutRecolte: 'Timeline du début de l\'étape Récolte',
						dureeRecolte: 'Durée de l\'étape Récolte',
						finRecolte: 'Timeline du début de l\'étape Fin de récolte'
					},
					culture: {
						nom: 'Nom de la culture',
						famille: 'Famille configurée',
						espece: 'Espèce configurée',
						variete: 'Variété',
						cultivar: 'Nom du cultivar',
						prodMino: 'Pourcentage de minoration de production',
						commentaire: 'Commentaire'
					},
					typeAjout: 'Type d\'ajout',
					idCulture: 'Culture associée',
					ajoutOptions: {
						culture: 'Culture',
						itk: 'ITK'
					}
				},
				infoAjout: 'Sélectionner le type d\'ajout puis compléter les informations liées',
				titleNewItk: 'Nouvel ITK',
				titleNewCulture: 'Nouvelle culture',
				errorDebutStep: 'Ce champ doit être supérieur à la timeline de l’étape précédente',
				titleSteps: 'Étapes composants l\'ITK :',
				validation: {
					notUniqueNom: 'Le nom doit être unique',
					notUniqueNum: 'Le numéro d\'ITK doit être unique'
				}
			},
			snackbar: {
				updateCulture: 'La culture a bien été mise à jour',
				updateItk: 'L\'ITK a bien été mis à jour',
				addCulture: 'La culture a bien été crée',
				addItk: 'L\'ITK a bien été créé'
			}
		},
		familleEspece: {
			title: 'Caractéristiques des familles et espèces',
			newBtn: 'Nouvel ajout',
			espece: '{quantity, plural, one {# espèce} other {# espèces}}',
			titleNewEspece: 'Nouvelle configuration d\'espèce',
			titleNewFamille: 'Nouvelle configuration de famille',
			inputQuery: 'Nom de la famille ou espèce',
			titleLeft: 'Liste des familles et espèces',
			empty: 'Aucune famille ou espèce',
			popin: {
				title: 'Nouvelle configuration'
			},
			form: {
				fields: {
					espece: {
						name: 'Nom de l\'espèce',
						codeEspece: 'Code de l\'espèce',
						scoreGourmandise: 'Score de gourmandise',
						rotationMinimum: 'Rotation minimum',
						exclusionRotation: 'Espèce non concernée par la contrainte de rotation',
						rotationMinimumAdornment: 'mois',
						tuteuree: 'Espèce tuteurée',
						filetInsectes: 'Filet anti-insectes',
						aspersionInterdite: 'Aspersion interdite',
						nouvelleEspeceWarning: 'Les espèces ci-dessous sont uniquement les espèces pour lesquelles leur famille possède une configuration.'
					},
					famille: {
						name: 'Nom de la famille',
						codeFamille: 'Code de la famille',
						rotationMinimum: 'Rotation minimum',
						rotationMinimumAdornment: 'mois',
						rotationRecommandee: 'Rotation recommandée',
						rotationRecommandeeAdornment: 'mois'
					},
					typeAjout: 'Famille ou espèce',
					idCulture: 'Famille associée',
					ajoutOptions: {
						famille: 'Famille',
						espece: 'Espèce'
					}
				},
				infoAjout: 'Sélectionner l\'entité concernée par la configuration',
				titleNewEspece: 'Nouvelle espèce',
				titleNewFamille: 'Nouvelle famille',
				errorDebutStep: 'Ce champ doit être supérieur à la timeline de l’étape précédente',
				titleSteps: 'Étapes composants l\'espèce :',
				validation: {
					notUniqueNom: 'Le nom doit être unique'
				}
			},
			snackbar: {
				updateFamille: 'La famille a bien été mise à jour',
				updateEspece: 'L\'espèce a bien été mis à jour',
				addFamille: 'La famille a bien été crée',
				addEspece: 'L\'espèce a bien été créé'
			}
		},
		reglagesMateriels: {
			title: 'Gestion des matériels',
			newBtn: 'Nouvel ajout',
			titleNewReglage: 'Nouvel ajout de réglage',
			titleNewMateriel: 'Nouvel ajout de matériel',
			inputQuery: 'Nom du matériel',
			reglage: 'Réglage',
			titleLeft: 'Liste des matériels',
			popin: {
				title: 'Nouvel ajout'
			},
			form: {
				fields: {
					materiel: {
						nom: 'Nom du matériel',
						quantiteLimite: 'En quantité limitée',
						stock: 'Stock',
						categorie: {
							categoriesTechniques: 'Catégories techniques concernées',
							categorieTitle: 'Catégories techniques',
							categorieName: '{name} :'
						}
					},
					reglage: {
						nom: 'Nom du réglage',
						codeReglage: 'Code du réglage',
						description: 'Description',
						urlInstruction: 'Instructions'
					},
					typeAjout: 'Matériel ou réglage',
					idMateriel: 'Matériel associée',
					ajoutOptions: {
						materiel: 'Matériel',
						reglage: 'Réglage'
					}
				},
				infoAjout: 'Sélectionner le type d’ajout puis compléter les informations liées',
				titleNewReglage: 'Informations du nouveau réglage',
				titleNewMateriel: 'Informations du nouveau matériel',
				validation: {
					notUniqueNom: 'Le nom doit être unique'
				}
			},
			snackbar: {
				updateMateriel: 'La matériel a bien été mise à jour',
				updateReglage: 'Le réglage a bien été mis à jour',
				addMateriel: 'Le matériel a bien été crée',
				addReglage: 'Le réglage a bien été créé'
			}
		},
		categoriesTechniques: {
			title: 'Gestion des catégories techniques',
			newBtn: 'Nouvel ajout',
			titleNewCategorie: 'Nouvel ajout de catégorie technique',
			inputQuery: 'Nom de la catégorie',
			category: 'Catégorie',
			titleLeft: 'Liste des catégories',
			popin: {
				title: 'Nouvel ajout'
			},
			form: {
				ajoutSensibilite: 'Ajouter une sensibilité pédoclimatique',
				fields: {
					category: {
						nom: 'Nom de la catégorie technique',
						code: 'Code de la catégorie technique',
						competence: {
							title: 'Compétences requises',
							description: 'Ci-dessous, vous pouvez préciser les compétences requises pour réaliser la tâche.',
							participants: 'Compétences participants',
							specialistes: 'Compétences spécialiste machine'
						},
						sensibilite: {
							title: 'Sensibilités pédoclimatiques',
							description: 'Ci-dessous, vous pouvez préciser les conditions pédoclimatiques qui ont un impact sur la réalisation de la tâche.',
							categorieMeteo: 'Catégorie',
							statutMeteo: 'Valeur',
							impactMeteo: 'Impact',
							values: {
								VENT_FAIBLE: 'Vent faible',
								VENT_FORT: 'Vent fort',
								ENSOLEILLE: 'Ensoleillé',
								PLUVIEUX: 'Pluvieux',
								NON_PLUVIEUX: 'Non pluvieux',
								NUAGEUX: 'Nuageux',
								SEC: 'Sec',
								HUMIDE: 'Humide',
								MOYEN: 'Moyen',
								CHAUD: 'Chaud',
								FROID: 'Froid',
								GEL: 'Gel',
								DOUX: 'Doux',
								SOL_DETREMPE: 'Sol détrempé',
								SOL_GELE: 'Sol gelé',
								SOL_SEC: 'Sol sec',
								SOL_BON: 'Sol bon',
								VENT: 'Vent',
								METEO: 'Météo',
								HUMIDITE_AIR: 'Humidité de l\'air',
								TEMPERATURE: 'Température',
								NATURE_SOL: 'Nature du sol',
								BLOQUANT: 'Bloquant',
								INDESIRABLE: 'Indésirable',
								PREFERABLE: 'Préférable',
								INDISPENSABLE: 'Indispensable'
							}
						},
						materiel: {
							title: 'Matériel requis',
							sansReglage: 'Matériel sans réglage',
							avecReglage: 'Matériel avec réglage'
						}
					}
				},
				infoAjout: 'Sélectionner le type d’ajout puis compléter les informations liées',
				titleNewCategory: 'Informations de la nouvelle catégorie',
				validation: {
					notUniqueNom: 'Le nom doit être unique'
				}
			},
			snackbar: {
				updateCategory: 'La catégorie a bien été mise à jour',
				addCategory: 'La catégorie a bien été crée'
			}
		},
		groupeCulture: {
			title: 'Gestion des groupes de culture',
			newBtn: 'Nouvel ajout',
			inputQuery: 'Culture composant le groupe',
			titleLeft: 'Liste des groupes de culture :',
			deleteButton: {
				groupe: 'Supprimer le groupe de culture',
				assolement: 'Supprimer la culture'
			},
			form: {
				fields: {
					nom: 'Nom du groupe de culture',
					zone: 'Zone compatible',
					description: 'Description',
					surface: 'Type surface',
					typeAjout: 'Type d\'ajout',
					idCulture: 'Culture associée',
					idGroup: 'Groupe de culture associé',
					idItk: 'ITK de référence',
					ajoutOptions: {
						assolement: 'Culture',
						groupe: 'Groupe de culture'
					}
				},
				infoAjout: 'Sélectionner le type d’ajout puis compléter les informations liées',
				titleNewGroupe: 'Nouvel groupe de culture',
				titleNewAssolement: 'Caractéristiques de la culture assolée',
				errors: {
					mandatory: 'Ce champ est obligatoire',
					tooLong: 'Le champ dépasse le nombre de {sizeMax} caractères autorisés',
					notUniqueNom: 'Nom du groupe déjà utilisé'
				}
			},
			snackbar: {
				creation: {
					successGroupe: 'Le groupe de culture a bien été créé',
					successAssolement: 'Le groupe de culture a bien été créé',
					error: 'Création impossible, une erreur s\'est produite'
				},
				update: {
					successGroupe: 'Le groupe de culture a bien été mis à jour',
					successAssolement: 'Le groupe de culture a bien été mis à jour',
					error: 'Modification impossible, une erreur s\'est produite'
				},
				delete: {
					success: 'Suppression effectuée',
					error: 'Suppression impossible, une erreur s\'est produite'
				}
			},
			popin: {
				creation: {
					title: 'Nouvel ajout'
				},
				delete: {
					title: 'Suppression du groupe de culture',
					text: 'Attention, la suppression du groupe de culture {nom} est définitive.'
				}
			}
		},
		modeleTache: {
			title: 'Paramétrage des modèles de tâche',
			newBtn: 'Nouveau modèle',
			inputQuery: 'Type, culture ou étape',
			liste: 'Liste des modèles de tâches',
			headers: {
				type: 'Type',
				famille: 'Famille',
				typeEntity: 'Entité liée',
				categorieTechnique: 'Catégorie technique',
				culture: 'Culture',
				etape: 'Étape'
			},
			emptyCategory: 'Aucune catégorie',
			form: {
				titleNew: 'Nouveau modèle de tâche',
				titleUpdate: 'Modification du modèle de tâche n°{moTacheId}',
				caracteristiquesGenerales: 'Caractéristiques générales',
				informationsPratiques: 'Informations pratiques',
				recurrence: 'Récurrence de la tâche',
				dependance: 'Dépendance de la tâche',
				typeTache: 'Tâche générique et variante',
				creationAutomatique: 'Création automatique',
				invalidCodeTache: 'Un modèle de tâche similaire existe déjà',
				descriptionTropLongue: 'Description trop longue',
				semaineTropGrande: 'Le numéro de semaine ne peut excéder 52',
				aucuneDependance: 'Aucune tâche ne peux être dépendante.',
				aucuneVariante: 'Aucune tâche ne peux être variante.',
				fields: {
					typeEntity: 'Entité liée à la tâche',
					famille: 'Famille de la tâche',
					type: 'Type de tâche',
					itk: 'ITK associé',
					flagCreation: 'Tâche générée à la création de la culture assolée',
					flagControleEtape: 'La tâche doit s\'effectuer au sein de l\'étape',
					timeline: 'La tâche doit démarrer (en semaine)',
					debutFin: 'Par rapport',
					description: 'Description',
					materiels: 'Matériel indispensable',
					nbPersonnes: 'Nombre de personnes nécessaires',
					flagDivisible: 'La tâche est-elle divisible',
					flagAnticipable: 'La tâche est-elle anticipable',
					joursAnticipable: 'Nombre de jours anticipables',
					tempsMoyM2: 'Durée de la tâche en min/m²',
					tempsMoyFixe: 'Durée de la tâche en min',
					flagRecurrence: 'La tâche est-elle récurrente',
					dureeRecurrence: 'Durée de la récurrence (en semaine)',
					periodeNb: 'Récurrence tou(te)s les',
					periodeType: 'Période',
					jours: 'Les',
					flagCreationCampagne: 'Création lors de l\'ouverture de la campagne',
					surfaces: 'Sélectionner les surfaces',
					blocs: 'Sélectionner les blocs',
					idItkDetail: 'Étape',
					urlDocInstruction: 'Instructions : ',
					creationSemaineN: 'Semaine de création',
					categorieTechnique: 'Catégorie technique de la tâche',
					priorite: 'Priorité de la tâche',
					flagDependance: 'La tâche dépend d’une autre tâche',
					realiseeAvant: 'Tâche qui doit être réalisée avant',
					idTacheGenerique: 'Tâche générique de référence',
					reglages: 'Réglages du modèle de tâche',
					modele: {
						independante: 'Modèle de tâche <b>indépendante</b>',
						generique: 'Modèle de tâche <b>générique</b> avec des variantes',
						variante: 'Modèle de tâche <b>variante</b> d\'une générique',
						selector: '{famille} - {tache} - {categorie} - (id: {moTache})'
					}
				},
				OUI: 'Oui',
				NON: 'Non',
				debutEtape: 'Au début de l\'étape',
				finEtape: 'À la fin de l\'étape',
				MOIS: 'Mois',
				SEMAINE: 'Semaine',
				JOURS: 'jour(s)'
			}
		},
		planificateur: {
			snackbar: {
				success: 'Les paramètres des contraintes ont bien été mises à jour.'
			},
			title: 'Administration du planificateur',
			notSaved: 'modifications non enregistrées',
			tabs: {
				PLANIFICATEUR_CULTURES: 'Planificateur de cultures',
				PLANIFICATEUR_TACHES: 'Planificateur de taches'
			},
			contraintes: {
				title: 'Administation des contraintes',
				description: 'Les variables administrables utilisées dans les contraintes sont disponibles dans le volet "Paramètrage métier"',
				table: {
					headers: {
						category: 'Catégorie',
						key: 'Clé',
						code: 'Code',
						description: 'Description',
						level: 'Level',
						value: 'Valeur',
						enabled: 'Activée'
					}
				}
			},
			params: {
				title: 'Paramètrage métier',
				table: {
					headers: {
						nom: 'Nom',
						value: 'Valeur',
						description: 'Description'
					},
					param: {
						nbWeekOverlapRewardFiletAntiInsecte: 'nbWeekOverlapRewardFiletAntiInsecte',
						nbWeekOverlapRewardVoileThermique: 'nbWeekOverlapRewardVoileThermique',
						minRatioManquementProductionAcceptable: 'minRatioManquementProductionAcceptable',
						maxRatioSurplusProductionAcceptable: 'maxRatioSurplusProductionAcceptable',
						maxCumulGourmandisePC: 'maxCumulGourmandisePC',
						maxCumulGourmandiseSA: 'maxCumulGourmandiseSA',
						maxNbWeekSuccessionSD: 'maxNbWeekSuccessionSD',
						maxNbWeekSuccessionSDNoPenalty: 'maxNbWeekSuccessionSDNoPenalty',
						maxNbWeekSuccessionSC: 'maxNbWeekSuccessionSC',
						maxNbWeekSuccessionSCNoPenalty: 'maxNbWeekSuccessionSCNoPenalty',
						pourcentageAAjouterPropositionRecolte: 'pourcentageAAjouterPropositionRecolte',
						adornmentWeek: 'Semaine(s)',
						adornmentPourcentage: '%'
					},
					paramDescription: {
						nbWeekOverlapRewardFiletAntiInsecte: 'Nombre de semaines durant lesquelles on souhaite que des espèces avec un filet anti-insectes soient placées sur des planches adjacentes.',
						nbWeekOverlapRewardVoileThermique: 'Nombre de semaines durant lesquelles on souhaite que des ITK avec un voile thermique soient placés sur des planches adjacentes.',
						minRatioManquementProductionAcceptable: 'Ratio acceptable pour un manquement de production',
						maxRatioSurplusProductionAcceptable: 'Ratio acceptable pour un surplus de production',
						maxCumulGourmandisePC: 'Score de gourmandise maximum pour la zone EXT',
						maxCumulGourmandiseSA: 'Score de gourmandise maximum pour la zone SERRE',
						maxNbWeekSuccessionSC: 'Nombre de semaines maximum accepté pour les successions entre un ITK et un ITK SC',
						maxNbWeekSuccessionSCNoPenalty: 'Nombre de semaines pour les successions entre un ITK et un ITK SC à partir duquel on ne pénalise plus',
						maxNbWeekSuccessionSD: 'Nombre de semaines maximum accepté pour les successions entre un ITK et un ITK SD',
						maxNbWeekSuccessionSDNoPenalty: 'Nombre de semaines pour les successions entre un ITK et un ITK SD à partir duquel on ne pénalise plus',
						pourcentageAAjouterPropositionRecolte: 'Aucune description'
					}
				}
			},
			terminationConfig: {
				title: 'Paramétrage technique',
				pourcentageAAjouterPropositionRecolte: {
					name: '<b>Pourcentage de planche à ajouter à la proposition</b>',
					description: 'Coefficient à ajouter à la proposition envoyée au solveur. On va envoyer un nombre de planches + ce pourcentage par semaine'
				},
				solverCultureConstructionSpentLimitSeconds: {
					name: '<b>Durée de la phase construction</b> (solverCultureConstructionSpentLimitSeconds) : ',
					description: 'Temps maximal laissé au solver pour exécuter la phase construction'
				},
				solverCultureLocalSearchLimitSecondsPhase: {
					name: '<b>Durée de la phase Local Search</b> (solverCultureLocalSearchLimitSecondsPhase) : ',
					description: 'Temps maximal laissé au solver pour exécuter la phase Local Search'
				},
				solverCultureLocalSearchLimitSecondsUnimproved: {
					name: '<b>Fin de la phase Local Search si pas d\'améliorations</b> (solverCultureLocalSearchLimitSecondsUnimproved) : ',
					description: 'Temps laissé au solver pour améliorer son score. Si celui-ci n\'est pas amélioré pendant ce temps, il passera à la phase suivante.'
				},
				adornmentSeconde: 'Seconde(s)',
				adornmentPourcentage: '%'
			}
		},
		campagne: {
			title: 'Gestion des campagnes',
			newBtn: 'Nouvelle campagne',
			buttonCloture: 'Clôturer la campagne',
			infosCampagne: 'Du {debut} au {fin}',
			snackbar: {
				cloture: {
					impossible: 'Vous devez d’abord clôturer toutes les campagnes antérieures avant de pouvoir clôturer celle-ci',
					etat: 'Vous ne pouvez pas clôturer une campagne à l’état brouillon',
					success: 'Clôture de la campagne enregistrée',
					error: 'Clôture impossible, une erreur s\'est produite'
				},
				creation: {
					success: 'La campagne a bien été créée',
					error: 'Création impossible, une erreur s\'est produite'
				}
			},
			emptyMessage: 'Aucune campagne disponible pour cette ferme',
			popin: {
				cloture: {
					title: 'Clôture de la campagne',
					text: 'Attention, vous êtes sur le point de clôturer la campagne {nom}. Aucune modification ne pourra alors être effectuée sur cette campagne. Cette action est définitive.',
					button: 'Cloturer'
				},
				creation: {
					title: 'Nouvelle campagne',
					text: 'Sélectionnez la ferme pour laquelle vous souhaitez ouvrir une nouvelle campagne. La date de début et de fin de la nouvelle campagne sera automatiquement généré par rapport à la dernière campagne.',
					fields: {
						ferme: 'Ferme',
						debut: 'Début de la campagne',
						fin: 'Fin de la campagne'
					},
					allFermes: 'Toutes les fermes',
					button: 'Nouvelle campagne',
					error: 'Champ obligatoire'
				}
			}
		},
		filters: {
			ferme: 'Ferme',
			allFerme: 'Toutes les fermes'
		},
		ferme: {
			form: {
				fields: {
					raisonSociale: 'Raison sociale',
					siret: 'SIRET',
					adresse: 'Adresse',
					complementAdresse: 'Complément d\'adresse',
					codePostal: 'Code postal',
					ville: 'Ville',
					telephone: 'Téléphone',
					email: 'Adresse e-mail',
					representant: 'Représentant',
					minoration: 'Minoration de production par défaut',
					fermeToCopy: 'Copier les données de la ferme',
					surfaceTitle: 'Dimensions des planches permanentes',
					hourTitle: 'Heures de début',
					hourSubtitle: 'Les heures enregistrées ci-dessous seront les heures de début par défaut de la ferme.',
					surfaceSubtitle: 'Les données ci-dessous seront enregistrées sur l’ensemble des planches permanentes de la ferme.',
					surfacePlanchePermanente: 'Dimension de la surface',
					longueurPlanchePermanente: 'Longueur de la surface',
					largeurPlanchePermanente: 'Largeur de la surface',
					dontCopyData: 'Ne pas copier de données',
					lundiDebut: 'Lundi',
					mardiDebut: 'Mardi',
					mercrediDebut: 'Mercredi',
					jeudiDebut: 'Jeudi',
					vendrediDebut: 'Vendredi',
					samediDebut: 'Samedi',
					dimancheDebut: 'Dimanche'
				},
				errors: {
					mandatory: 'Ce champ est obligatoire',
					tooLong: 'Le champ dépasse la taille maximale',
					wrongFormat: 'Le champ n\'est pas au bon format'
				}
			},
			snackbar: {
				creation: {
					success: 'La ferme a bien été créée',
					error: 'Création impossible, une erreur s\'est produite'
				},
				update: {
					success: 'La ferme a bien été mise à jour',
					error: 'Modification impossible, une erreur s\'est produite',
					matriceError: 'Modification impossible, la structure de la matrice ne correspond pas'
				}
			},
			plan: {
				title: 'Image du plan de la ferme',
				description: 'L\'image du plan de la ferme sera visible sur l\'écran de tour de plaine pour le chef de culture.',
				apercu: 'Aperçu :',
				size: 'Taille maximum: 2 Mo',
				formats: 'Formats: png, jpg, jpeg',
				load: 'Fichier chargé',
				error: {
					sizeError: 'Taille maximum atteinte',
					formatError: 'Extension incorrecte',
					imgError: 'L\'aperçu de l\'image n\'a pas pu être récupéré, veuillez réessayer ultérieurement'
				}
			},
			matrice: {
				title: 'Matrice de proximité',
				description: 'La matrice de proximité entre les blocs est utilisée dans les contraintes du solveur. Cliquez sur le bouton ci-dessous pour télécharger le document modèle et importer ensuite votre version .',
				import: 'Charger la matrice',
				export: 'Exporter la matrice'
			},
			buttonNew: 'Nouvelle ferme',
			buttonInfo: 'INFORMATIONS GÉNÉRALES',
			buttonPlan: 'PLAN DE LA FERME',
			dropText: 'Lâcher votre fichier pour l’importer',
			uploadText: 'Glissez/déposez un fichier, vous pouvez aussi choisir un fichier dans votre explorateur',
			title: 'Liste des fermes :',
			popin: {
				title: 'Nouvelle ferme',
				subtitle: 'Informations de la nouvelle ferme',
				text: 'Sélectionner la ferme à partir de laquelle vous souhaitez récupérer les données des cultures, groupes de cultures, modèles de tâches, ITK, familles et espèces puis renseigner les informations liées à cette nouvelle ferme. Vous pouvez également partir d’un modèle vierge si nécessaire.'
			}
		}
	}
})
