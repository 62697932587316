import {Grid, Typography, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {BOARD_UNAVAILABLE_FIELDS, BOARD_UNAVAILABLE_FORM_NAME} from '../../tool/suiviParBloc.constants'
import {normalizeDate} from '../../../../../../../utils/utils'
import Input from '../../../../../../../components/form/Input'
import DateFieldCustom from '../../../../../../../components/form/DateFieldCustom'

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		padding: '30px 40px',
		rowGap: 25,
		maxHeight: 'calc(100vh - 220px)'
	},
	rowContainer: {
		columnGap: 25
	},
	label: {
		fontStyle: 'italic',
		fontSize: 16,
		lineHeight: '20px',
		fontWeight: '400',
		color: '#000'
	},
	boardsText: {
		fontSize: 18,
		lineHeight: '22px',
		fontWeight: '600',
		color: '#000'
	},
	dateLabel: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	},
	container: {
		width: '100%'
	}
})

/**
 * Validate
 * @param values
 * @returns {{}}
 */
const validate = (values) => {
	const errors = {}

	if (!values[BOARD_UNAVAILABLE_FIELDS.START]) {
		errors[BOARD_UNAVAILABLE_FIELDS.START] = { id: 'global.errors.mandatory' }
	}
	if (!values[BOARD_UNAVAILABLE_FIELDS.END]) {
		errors[BOARD_UNAVAILABLE_FIELDS.END] = { id: 'global.errors.mandatory' }
	}
	return errors
}

/**
 * BoardUnavailableAddForm
 * @param handleSubmit
 * @param classes
 * @param boards
 * @returns {JSX.Element}
 * @constructor
 */
const BoardUnavailableAddForm = ({ handleSubmit, classes, boards }) => {

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.form}>
				<Grid item container alignItems="center" justify="flex-start" className={classes.rowContainer}>
					<Typography className={classes.label}>
						<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.boards" />
					</Typography>
					<Typography className={classes.boardsText}>
						{boards.map(board => board.label).join(', ')}
					</Typography>
					<Typography className={classes.label}>
						<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.unavailable" />
					</Typography>
				</Grid>
				<Grid item container alignItems="center" justify="space-between">
					<Typography className={classes.dateLabel}>
						<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.date.start" />
					</Typography>
					<Field
						name={BOARD_UNAVAILABLE_FIELDS.START}
						type="text"
						component={DateFieldCustom}
						format={normalizeDate}
						style={{ width: '40%'}}
					/>
					<Typography className={classes.dateLabel}>
						<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.date.end" />
					</Typography>
					<Field
						name={BOARD_UNAVAILABLE_FIELDS.END}
						type="text"
						component={DateFieldCustom}
						format={normalizeDate}
						style={{ width: '40%'}}
					/>
				</Grid>
				<Field
					name={BOARD_UNAVAILABLE_FIELDS.COMMENT}
					component={Input}
					label={<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.comment" />}
					multiline
					containerClassName={classes.container}
				/>
			</Grid>
		</form>
	)
}

BoardUnavailableAddForm.propType = {
	boards: PropTypes.array,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state, { boards, ...props }) => {
	return ({
		boards,
		initialValues: {
			[BOARD_UNAVAILABLE_FIELDS.BOARDS]: boards.map(board => board.id)
		},
		...props
	})
}


export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: BOARD_UNAVAILABLE_FORM_NAME.BOARD_UNAVAILABLE_ADD_FORM,
		destroyOnUnmount: true,
		enableReinitialize: true,
		validate
	}),
	withStyles(styles)
)
(BoardUnavailableAddForm)
