import {Box, Divider, Grid, Typography} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import {dataTableValueSelectors, getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {connect} from 'react-redux'
import {compose} from 'redux'
import FilterIcon from '../../../../../components/icon/FilterIcon'
import AccessChecker from '../../../../../components/security/AccessChecker'
import {ASSOLEMENT_ETAT, colors, ETAPE_ETAT, ETAPE_TYPE, NOM_VUE_LISTE_ASSOLEMENT, PROFILS} from '../../../../../utils/constants'
import {getAccessCheckerRole} from '../../../../../utils/utils'
import {injectAssolementFilter} from '../../../planning/services/actions/filterAssolementInjector'
import {getAssolements} from '../../../planning/services/assolement/assolementSelector'
import {setFilterOpen} from '../../../planning/services/toolbar/toolbarAction'
import {getFilterValues} from '../../../planning/services/toolbar/toolbarSelector'
import CampagneSelect from '../../../planning/component/ToolBar/CampagneSelect'
import ToolBarButton from '../../../planning/component/ToolBar/ToolBarButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ButtonWithMenu from '../../../../../components/ButtonWithMenu'
import {ControlPointDuplicate, DeleteForever, LowPriority, Room} from '@material-ui/icons'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import CommentaireIcon from '../../../../../components/icon/CommentaireIcon'
import {injectCultureDuplicator} from '../../../planning/services/actions/form/duplicateCultureInjector'
import {injectDeleteCulture} from '../../../planning/services/actions/form/deleteCultureInjector'
import {injectEditCulturePlanche} from '../../../planning/services/actions/form/editCulturePlancheInjector'
import {injectCommentaireSuivi} from '../../../planning/services/actions/form/commentInjectors'
import {injectDeplacerEtape} from '../../../planning/services/actions/form/deplacerEtapeInjector'
import {injectModifierEtapeRecolte} from '../../../planning/services/actions/form/allongerReduireEtapeRecolte'
import PeriodSelector from './PeriodSelector'
import moment from 'moment'

const styles = () => getStyles({
	icon: {
		marginLeft: 12,
		marginRight: 12
	},
	toolbar: {
		paddingLeft: 24,
		flexGrow: 0.75
	},
	filters: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center'
	},
	campagne: {
		display: 'flex',
		alignItems: 'center'
	},
	suffix: {
		fontStyle: 'italic',
		fontSize: '0.6em'
	},
	divider: {
		borderBottom: '1px solid colors.placeholder',
		width: '-webkit-fill-available'
	},
	action: {
		minWidth: 110,
		width: 110
	}
})


const ToolBar = ({
					 campagne,
					 selectedRows,
					 setFilterOpen,
					 classes,
					 openCultureDuplicator,
					 openCultureDelete,
					 openPanelCommentaireSuivi,
					 openEditCulturePlanche,
					 deplacerEtape,
					 modifierEtapeRecolte,
					 period,
					 setPeriod,
					 refresh
				 }) => {

	const isLessThan1RowSelected = React.useMemo(() => selectedRows && selectedRows.length < 1, [selectedRows])

	// Conditionne l'affichage de l'action : deplacement des cultures → disabled = valeur
	const isOneOrMoreEtapeSdAnsIDone = React.useMemo(() => {
		if (selectedRows) {
			// Ticket PRM162 : modification de la règle RG_PLALIST_031
			const etatsDisabled = [ETAPE_ETAT.A_REALISER, ETAPE_ETAT.EN_COURS, ETAPE_ETAT.TERMINE]
			return selectedRows.filter(assolement => assolement.assolementEtapeMap)
				.some((assolement) =>
					(assolement.assolementEtapeMap.SD && etatsDisabled.indexOf(assolement.assolementEtapeMap.SD.etat) > -1)
					||
					(assolement.assolementEtapeMap.I && etatsDisabled.indexOf(assolement.assolementEtapeMap.I.etat) > -1)
				)
		}
		return true
	}, [selectedRows])

	// Conditionne l'affichage de l'action : deplacement des recoltes → enabled = valeur
	const isAllEtapeRecolteDefine = React.useMemo(() => {
		if (selectedRows) {
			return selectedRows.filter(assolement => assolement.assolementEtapeMap)
				.every((assolement) =>
					(assolement.assolementEtapeMap.R && !assolement.assolementEtapeMap.R.deleted)
				)
		}
		return true
	}, [selectedRows])

	const isOneOrMoreEtapeIDone = React.useMemo(() => {
		if (selectedRows) {
			const etatsDisabled = [ETAPE_ETAT.A_REALISER, ETAPE_ETAT.EN_COURS, ETAPE_ETAT.TERMINE]
			return selectedRows.filter(assolement => assolement.assolementEtapeMap)
				.some((assolement) =>
					((assolement.assolementEtapeMap.I && etatsDisabled.indexOf(assolement.assolementEtapeMap.I.etat) > -1) || !(assolement.assolementEtapeMap.I) || !(assolement.assolementEtapeMap.I && !assolement.assolementEtapeMap.I.deleted))
				)
		}
		return true
	}, [selectedRows])

	const isOneOrMoreEtapeRDone = React.useMemo(() => {
		if (selectedRows) {
			return selectedRows.filter(assolement => assolement.assolementEtapeMap)
				.some((assolement) =>
					(assolement.assolementEtapeMap.R && assolement.assolementEtapeMap.R.etat === ETAPE_ETAT.TERMINE)
				)
		}
		return true
	}, [selectedRows])

	const isRevise = React.useMemo(() => campagne.etat === ASSOLEMENT_ETAT.REVISE, [campagne])

	const bornes = React.useMemo(() => ({
		dateDebut: campagne.dateDebut,
		dateFin: campagne.dateFin
	}), [campagne])

	const initialPeriod = React.useMemo(() => ({
		dateDebut: moment(period.dateDebut).toISOString(),
		dateFin: moment(period.dateFin).toISOString()
	}), [period])

	const updatePeriod = React.useCallback((values) => setPeriod({
		dateDebut: moment(values.dateDebut).format(moment.HTML5_FMT.DATE),
		dateFin: moment(values.dateFin).format(moment.HTML5_FMT.DATE),
	}), [setPeriod])

	return (
		<React.Fragment>
			<Grid container direction="row" justify="space-between" className="c-toolbar">
				<Grid item container xs direction="row" justify="flex-start" alignItems="center"
					  className={classes.toolbar}>
					<Box p={1}>
						<Typography variant="h2" className={classes.campagne}>
							<CampagneSelect/>
							<span className={classes.suffix}><FormattedMessage
								id={`planning.titleSuffix${isRevise ? 'Revise' : 'Initial'}`}/></span>
						</Typography>
					</Box>
				</Grid>

				<Grid item container xs direction="row" justify="flex-end" alignItems="center">
					{
						!campagne.finished &&
						<>
							<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>

								<Box p={1}>
									<ButtonWithMenu
										type="primary"
										disabled={isLessThan1RowSelected}
										aria-haspopup="true"
										variant="contained"
										className={classes.action}
										items={[
											{
												label: <FormattedMessage
													id="listAssolement.actionButtonMenu.deplacerCultures"/>,
												icon: <LowPriority color={'primary'}/>,
												disabled: isOneOrMoreEtapeSdAnsIDone,
												onClick: (() => {
													openEditCulturePlanche(selectedRows)
												})
											}, {
												label: <FormattedMessage
													id="listAssolement.actionButtonMenu.dupliquerCultures"/>,
												icon: <ControlPointDuplicate color={'primary'}/>,
												onClick: (() => {
													openCultureDuplicator(selectedRows, refresh)
												})
											}, {
												label: <FormattedMessage
													id="listAssolement.actionButtonMenu.supprimerCultures"/>,
												icon: <DeleteForever color={'primary'}/>,
												onClick: (() => {
													openCultureDelete(selectedRows)
												})
											}, {
												label: <FormattedMessage
													id="listAssolement.actionButtonMenu.ajoutSuiviProd"/>,
												icon: <CommentaireIcon color={colors.primary}/>,
												onClick: (() => {
													openPanelCommentaireSuivi(selectedRows)
												})
											}, {
												label: <Divider className={classes.divider}/>,
												icon: <Divider className={classes.divider}/>
											}, {
												label: <FormattedMessage
													id="listAssolement.actionButtonMenu.deplacerEtapeImplantation"/>,
												icon: <Room color={'primary'}/>,
												disabled: isOneOrMoreEtapeIDone,
												onClick: (() => {
													deplacerEtape(selectedRows, ETAPE_TYPE.IMPLANTATION)
												})
											}, {
												label: <FormattedMessage
													id="listAssolement.actionButtonMenu.deplacerEtapeRecolte"/>,
												icon: <Room color={'primary'}/>,
												disabled: !isAllEtapeRecolteDefine,
												onClick: (() => {
													deplacerEtape(selectedRows, ETAPE_TYPE.RECOLTE)
												})
											}, {
												label: <FormattedMessage
													id="listAssolement.actionButtonMenu.modifEtapeRecolte"/>,
												icon: <SettingsEthernetIcon color={'primary'}/>,
												disabled: isOneOrMoreEtapeRDone,
												onClick: (() => {
													modifierEtapeRecolte(selectedRows)
												})
											}
										]}

										startIcon={<MoreHorizIcon/>}>
										<FormattedMessage id="listAssolement.buttons.action"/>
									</ButtonWithMenu>
								</Box>
							</AccessChecker>

							<AccessChecker access={PROFILS.MARAICHER}>
								<Box p={1}>
									<ButtonWithMenu
										type="primary"
										disabled={isLessThan1RowSelected}
										aria-haspopup="true"
										variant="contained"
										className={classes.action}
										items={[
											{
												label: <FormattedMessage
													id="listAssolement.actionButtonMenu.ajoutSuiviProd"/>,
												icon: <CommentaireIcon color={colors.primary}/>,
												onClick: (() => {
													openPanelCommentaireSuivi(selectedRows)
												})
											}
										]}
										startIcon={<MoreHorizIcon/>}>
										<FormattedMessage id="listAssolement.buttons.action"/>
									</ButtonWithMenu>
								</Box>
							</AccessChecker>
						</>
					}

					<Box p={1} className={classes.filters}>
						<PeriodSelector
							onSubmit={updatePeriod}
							initialValues={initialPeriod}
							period={period}
							bornes={bornes}
							withTitle
							formName="AssolementListPeriodFilter"
							isInput={false}
						/>
						<ToolBarButton type="filter" onClick={setFilterOpen}>
							<FilterIcon color="currentColor"/>
						</ToolBarButton>
					</Box>
				</Grid>
			</Grid>

		</React.Fragment>
	)
}

ToolBar.propTypes = {
	campagne: PropTypes.object,
	submitting: PropTypes.bool,
	accessAssolement: PropTypes.func,
	openCultureDetail: PropTypes.func,
	submitNewCulture: PropTypes.func,
	classes: PropTypes.object,
	assolements: PropTypes.object,
	rows: PropTypes.array,
	openCultureDuplicator: PropTypes.func,
	openCultureDelete: PropTypes.func,
	openEditCulturePlanche: PropTypes.func,
	deplacerEtape: PropTypes.func,
	refresh: PropTypes.func
}

const mapStateToProps = (state) => ({
	assolements: getAssolements(state),
	filterValues: getFilterValues(state),
	selectedRows: dataTableValueSelectors.getDataTableSelectedRows(state)(NOM_VUE_LISTE_ASSOLEMENT.LISTE_ASSOLEMENT) || []
})

const actions = {
	setFilterOpen
}

export default compose(
	connect(mapStateToProps, actions),
	injectAssolementFilter,
	injectCultureDuplicator,
	injectDeleteCulture,
	injectEditCulturePlanche,
	injectCommentaireSuivi,
	injectDeplacerEtape,
	injectModifierEtapeRecolte,
	withStyles(styles)
)(ToolBar)
