import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../../../components/Button'
import CommentSuiviList from '../../../../../../components/CommentSuiviList'
import CommentaireOutlinedIcon from '../../../../../../components/icon/CommentaireOutlinedIcon'
import FertilisationIcon from '../../../../../../components/icon/FertilisationIcon'
import PlaceIcon from '../../../../../../components/icon/PlaceIcon'
import { ActionPanelContext, DetailsPanelContext } from '../../../../../../components/layout/rightPanels'
import { colors, COMMENTAIRE_TYPE_ENTITY } from '../../../../../../utils/constants'
import TypeSurface from '../../../../../common/information/TypeSurface'
import ZoneSurface from '../../../../../common/information/ZoneSurface'
import Detail from '../../../component/detail/Detail'
import Fertilization from '../../../component/detail/Fertilization'
import PreviousCulture from '../../../component/PreviousCulture'
import { DO_NOT_OPEN_PANEL, PANEL_TYPE } from '../../../utils/constant'
import * as assolementsSelectors from '../../assolement/assolementSelector'
import { injectCommentairePlanche } from '../form/commentInjectors'
import { injectDeleteComment } from '../form/deleteCommentInjector'
import { injectFertilizationForm } from '../form/fertilizationFormInjector'

/**
 * Injecteur pour l'affichage du panel de détail de surface
 */
export const injectSurfacePanel = (WrappedComponent) => {
	const SurfaceDetailInjector = ({ panelType, currentAssolement, ...props }) => {
		const { updatePanel: updateDetailsPanel } = React.useContext(DetailsPanelContext)
		const { closePanel: closePanelAction } = React.useContext(ActionPanelContext)
		const { openCommentairePlanche, openCommentDelete, isCompare } = props

		React.useEffect(() => {
			closePanelAction()
			if (currentAssolement && currentAssolement.planche && panelType === PANEL_TYPE.SURFACE_DETAIL) {
				const { campagne, planche } = currentAssolement
				const title = planche.nom
				let buttons = [
					<Button type="secondary" onClick={() => openCommentairePlanche(currentAssolement, planche, campagne, DO_NOT_OPEN_PANEL)} startIcon={<CommentaireOutlinedIcon color="currentColor" />}>
						<FormattedMessage id="planning.leftPanel.menu.surface.short.addComment" />
					</Button>
				]
				// todo vu le 15/07. On retire la possibilité de faire des fertilisations pour le moment. Le sujet sera à supprimer ou
				// reprendre plus tard
				// if(checkFertilization(planche.id, assolements, false))
				// buttons = buttons.concat(<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
				// 	<Button
				// 		type="secondary"
				// 		onClick={() => openFertilizationForm(currentAssolement)}
				// 		startIcon={<FertilisationIcon color="currentColor" height="1.2rem"/>}>
				// 		<FormattedMessage
				// 			id="planning.leftPanel.menu.surface.short.createFertilization"/>
				// 	</Button>
				// </AccessChecker>)
				// else if (checkFertilization(planche.id, assolements, true)){
				// 	buttons = buttons.concat(<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
				// 		<Button
				// 			type="secondary"
				// 			onClick={() => openFertilizationForm(currentAssolement, true)}
				// 			startIcon={<FertilisationIcon color="currentColor" height="1.2rem"/>}>
				// 			<FormattedMessage
				// 				id="planning.leftPanel.menu.surface.addFertilization"/>
				// 		</Button>
				// 	</AccessChecker>)
				// }
				const comments = planche.commentaires

				const sections = [
					{
						icon: <PlaceIcon />,
						title: planche.nom,
						path: <ZoneSurface planche={planche} />,
						content: <>
							<TypeSurface planche={planche} />
							<PreviousCulture campagneDateDebut={campagne.dateDebut} plancheId={planche.id} />
						</>
					},
					{
						icon: <FertilisationIcon />,
						title: <FormattedMessage id="planning.planche.detail.fertilization.title" />,
						content: <Fertilization plancheId={planche.id} />
					},
					{
						icon: <CommentaireOutlinedIcon color={colors.iconeDetail} />,
						title: <FormattedMessage id="planning.planche.detail.comments.title" />,
						content: (comments && comments.length) ?
							<CommentSuiviList comments={comments} editComment={(comment) => openCommentairePlanche(currentAssolement, planche, campagne, DO_NOT_OPEN_PANEL, comment)}
							                  deleteComment={(comment) => openCommentDelete(comment, COMMENTAIRE_TYPE_ENTITY.PLANCHE)} /> :
							<FormattedMessage id="planning.planche.detail.comments.none" />
					}
				]

				if (isCompare || campagne.finished) {
					buttons = []
				}
				return updateDetailsPanel(
					title,
					<Detail sections={sections} />,
					buttons
				)
			}

		}, [currentAssolement, panelType])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		currentAssolement: assolementsSelectors.getCurrentAssolement(state),
		panelType: assolementsSelectors.getDetailPanelType(state),
		isCompare: assolementsSelectors.isCompare(state)
	})

	SurfaceDetailInjector.propTypes = {
		panelType: PropTypes.string,
		currentAssolement: PropTypes.object,
		isCompare: PropTypes.bool
	}

	return compose(
		connect(mapStateToProps),
		injectCommentairePlanche,
		injectDeleteComment,
		injectFertilizationForm
	)(SurfaceDetailInjector)
}
