import React from 'react'
import { ACTION_TYPE } from './utils/constantsSuiviTache'
import Socket from './component/Socket'

const mobileContexte = {
	tache: {},
	setTache: (tache) => {
	},

	tachesNonTerminees: [],
	setTachesNonTerminees: (tachesNonTerminees) => {
	},

	tachesTerminees: [],
	setTachesTerminees: (tachesTerminees) => {
	},

	actionType: null,
	setActionType: (actionType) => {
	},

	initialScreenSize: 0,
	setInitialScreenSize: (initialScreenSize) => {
	}
}

export const MobileProvider = React.createContext(mobileContexte)

export const useMobileContext = () => React.useContext(MobileProvider)

const MobileContextProvider = ({ children }) => {
	const [tache, setTache] = React.useState(undefined)
	const [tachesNonTerminees, setTachesNonTerminees] = React.useState([])
	const [tachesTerminees, setTachesTerminees] = React.useState([])
	const [actionType, setActionType] = React.useState(ACTION_TYPE.NONE)
	const [initialScreenSize, setInitialScreenSize] = React.useState(0)

	return (
		<MobileProvider.Provider value={{
			tache,
			setTache,
			tachesNonTerminees,
			setTachesNonTerminees,
			tachesTerminees,
			setTachesTerminees,
			actionType,
			setActionType,
			initialScreenSize,
			setInitialScreenSize
		}}>
			<Socket />
			{children}
		</MobileProvider.Provider>
	)
}

export default MobileContextProvider