import React from 'react'
import { getStyles } from 'isotope-client'
import { withStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import InfosTache from './InfosTache'
import { colors, ETAT_TACHE_UPPERCASE } from '../../../../utils/constants'
import { getInformationsTache } from '../utils/utilsMobile'
import Button from '../../../../components/Button'
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import DescriptionIcon from '@material-ui/icons/Description'
import NoResultPage from '../../../../components/NoResultPage'
import { etatTacheDemarrerPauseCours } from '../mobileApi'
import { useMobileContext } from '../MobileProvider'
import { ACTION_TYPE } from '../utils/constantsSuiviTache'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import moment from 'moment'
import classnames from 'classnames'

const styles = () => getStyles({
	root: {
		backgroundColor: colors.etapeContrastBackground,
		fontFamily: 'Lato',
		fontSize: 14
	},
	gridPadding: {
		padding: 20
	},
	marginTop: {
		marginTop: 8
	},
	description: {
		textAlign: 'justify',
		marginTop: 13
	},
	icon: {
		width: 17,
		height: 17,
		marginRight: 5
	},
	instructions: {
		textAlign: 'center',
		width: 260,
		height: 80,
		borderRadius: 10,
		marginBottom: 25
	},
	centeredGrid: {
		alignSelf: 'center',
		textAlign: '-webkit-center'
	},
	boutonGrid: {
		marginBottom: 10,
		width: 323,
		height: 81
	},
	affectOutilsTemps: {
		marginBottom: 10,
		marginTop: 20
	},
	bigButton: {
		textAlign: 'center',
		minWidth: 150,
		height: 80,
		borderRadius: 15
	},
	demiWidth: {
		width: 150
	},
	fullWidth: {
		width: '100%'
	},
	insideTextButton: {
		textAlign: 'left',
		fontSize: 16,
		width: 83
	},
	font16: {
		fontSize: 16
	},
	insideTextButtonInstruction: {
		textAlign: 'left',
		fontSize: 16,
		width: 170,
		fontWeight: 'bold'
	},
	infoSurTache: {
		marginTop: 5
	}
})

const MobileTachesView = ({ classes }) => {
	const { tache, setTache, tachesNonTerminees, tachesTerminees, setActionType } = useMobileContext()
	const { snackError } = useSnackbar()
	const classeButton = tache && tache.tacheEnRetard ? classnames(classes.fullWidth, classes.bigButton) : classnames(classes.demiWidth, classes.bigButton)

	return (
		<Grid className={classes.root}>
			<Grid container direction="column" className={classes.gridPadding}>
				<Grid className={classes.font16}>
					<FormattedMessage id="mobile.h1.tacheARealiser" />
				</Grid>

				{/* Tache actuelle */}
				{tache
					? getInformationsTache(classes, tache, true)
					: <NoResultPage mobileListe={ETAT_TACHE_UPPERCASE.EN_COURS} />
				}
			</Grid>

			{/* Bouton instructions */}
			{(tache && tache.doc && tache.doc.trim() !== '') &&
				<Grid className={classes.centeredGrid}>
					<Button
						type="secondary"
						className={classes.instructions}
						startIcon={<DescriptionIcon color="currentColor" style={{ width: 36, height: 36 }} />}
						onClick={() => window.open(tache.doc, '_blank')}
					>
						<Grid className={classes.insideTextButtonInstruction}><FormattedMessage id="gestionTaches.ecranAffectations.panels.buttons.instructions" /></Grid>
					</Button>
				</Grid>
			}

			{/* Boutons actions */}
			{tache &&
				<Grid className={classes.centeredGrid}>
					{tache.etatTacheUser === ETAT_TACHE_UPPERCASE.A_REALISER &&
						<Grid className={classes.boutonGrid} container direction="row" justify="space-between">
							{!tache.tacheEnRetard &&
								<Button className={classeButton} type="secondary"
										startIcon={<PriorityHighRoundedIcon color="currentColor" style={{ width: 36, height: 36 }} />}
										onClick={() => setActionType(ACTION_TYPE.DEMARRAGE_IMPOSSIBLE)}
								>
									<Grid className={classes.insideTextButton}><FormattedMessage id="mobile.buttons.demarrageImpossible" /></Grid>
								</Button>
							}

							<Button
								className={classeButton}
								type="primary"
								startIcon={<PlayCircleOutlineIcon color="currentColor" style={{ width: 36, height: 36 }} />}
								onClick={() => {
									etatTacheDemarrerPauseCours(tache.id)
										.then((res) => setTache(res))
										.catch(() => snackError({ id: 'mobile.snackbar.error.erreurReseau' }))
								}}
							>
								<Grid className={classes.insideTextButton}><FormattedMessage id="mobile.buttons.demarrer" /></Grid>
							</Button>

						</Grid>
					}

					{(tache.etatTacheUser === ETAT_TACHE_UPPERCASE.EN_PAUSE || tache.etatTacheUser === ETAT_TACHE_UPPERCASE.EN_COURS) &&
						<Grid className={classes.boutonGrid} container direction="row" justify="space-between">
							<Button className={classnames(classes.demiWidth, classes.bigButton)} type="primary"
									startIcon={
										tache.etatTacheUser === ETAT_TACHE_UPPERCASE.EN_COURS
											? <PauseCircleOutlineIcon color="currentColor" style={{ width: 36, height: 36 }} />
											: <PlayCircleOutlineIcon color="currentColor" style={{ width: 36, height: 36 }} />
									}
									onClick={() => {
										etatTacheDemarrerPauseCours(tache.id)
											.then((res) => setTache(res))
											.catch(() => snackError({ id: 'mobile.snackbar.error.erreurReseau' }))
									}}
							>
								<Grid className={classes.insideTextButton}>
									{tache.etatTacheUser === ETAT_TACHE_UPPERCASE.EN_COURS
										? <FormattedMessage id="mobile.buttons.pause" />
										: <FormattedMessage id="mobile.buttons.reprendre" />
									}
								</Grid>
							</Button>

							<Button className={classnames(classes.demiWidth, classes.bigButton)} type="primary"
									startIcon={<CheckCircleOutlineIcon color="currentColor" style={{ width: 36, height: 36 }} />}
									onClick={() => setActionType(ACTION_TYPE.TERMINEE)}
							>
								<Grid className={classes.insideTextButton}>
									<FormattedMessage id="mobile.buttons.termine" />
								</Grid>
							</Button>
						</Grid>
					}

				</Grid>
			}

			{/* Taches restantes */}
			<Grid container direction="column" className={classes.gridPadding}>
				<Grid className={classes.font16}>
					<FormattedMessage id="mobile.h1.tachesRestantes" />
				</Grid>

				<Grid className={classes.marginTop}>
					{tachesNonTerminees.length > 0
						? tachesNonTerminees.map((tache) => <InfosTache tache={tache} formatEtiquette />)
						: <NoResultPage mobileListe={ETAT_TACHE_UPPERCASE.A_REALISER} />
					}
				</Grid>
			</Grid>

			{/* Taches terminées */}
			<Grid container direction="column" className={classes.gridPadding}>
				<Grid className={classes.font16}>
					<FormattedMessage id="mobile.h1.tachesTerminees" />
				</Grid>

				<Grid className={classes.marginTop}>
					{tachesTerminees.length > 0
						? tachesTerminees.map((tache) => <InfosTache tache={tache} formatEtiquette />)
						: <NoResultPage mobileListe={ETAT_TACHE_UPPERCASE.TERMINEE} />
					}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default withStyles(styles)(MobileTachesView)
