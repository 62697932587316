import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FIELDS, formattedMessageBase } from '../PopinModeleTache'
import Radio from '../../../../components/form/Radio'
import { TYPE_MO_TACHE } from '../../../fo/mobile/utils/constantsSuiviTache'
import FormattedMessageWithBold from '../../../../components/FormattedMessageWithBold'
import Autocomplete from '../../../../components/form/Autocomplete'
import { injectFamilleTacheValueList, injectTypeTacheValueList } from '../../../common/valueLists/valueListInjectors'

const GeneriqueVarianteSubPanel = ({
	intl,
	moTacheListVariante,
	modele,
	classes,
	familleTacheList,
	typeTacheList
}) => {
	return (
		<ExpansionPanel className={classes.expandPanel}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				className={classes.header}
			>
				<FormattedMessage id={`${formattedMessageBase}.typeTache`} />
			</ExpansionPanelSummary>
			<Grid container item justify={'flex-start'}>
				<Field name={FIELDS.MODELE.name}
					   component={Radio}
					   choices={[{
						   value: TYPE_MO_TACHE.INDEPENDANTE,
						   label: <FormattedMessageWithBold id={`${formattedMessageBase}.fields.modele.independante`} />
					   }, {
						   value: TYPE_MO_TACHE.GENERIQUE,
						   label: <FormattedMessageWithBold id={`${formattedMessageBase}.fields.modele.generique`} />
					   }, {
						   value: TYPE_MO_TACHE.VARIANTE,
						   label: <FormattedMessageWithBold id={`${formattedMessageBase}.fields.modele.variante`} />
					   }]}
					   row
					   style={{ justifyContent: 'space-between' }}
					   containerClass={classes.radio}
				/>
			</Grid>
			{moTacheListVariante.length > 0 ? modele === TYPE_MO_TACHE.VARIANTE && (
				<Grid container item justify={'flex-start'}>
					<Field
						name={FIELDS.ID_TACHE_GENERIQUE.name}
						component={Autocomplete}
						containerClassName={classes.bigRow}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.ID_TACHE_GENERIQUE.name}`} />}
						fromBo
						options={
							moTacheListVariante.map(tache => ({
								code: tache.id,
								label: intl.formatMessage({ id: 'bo.modeleTache.form.fields.modele.selector' }, {
									famille: familleTacheList.find(famille => famille.code === tache?.familleTache)?.label,
									tache: typeTacheList.find(type => type.code === tache?.typeTache)?.label,
									categorie: tache?.categorieTechnique?.nom,
									moTache: tache.id
								})
							}))
						}
					/>
				</Grid>) : modele === TYPE_MO_TACHE.VARIANTE &&
				<Grid container item justify={'center'}>
					<FormattedMessage id={`${formattedMessageBase}.aucuneVariante`} />
				</Grid>
			}
		</ExpansionPanel>
	)
}

GeneriqueVarianteSubPanel.propType = {
	moTacheListVariante: PropTypes.array,
	modele: PropTypes.string,
	classes: PropTypes.object,
	familleTacheList: PropTypes.array,
	typeTacheList: PropTypes.array
}

export default compose(
	injectIntl,
	injectTypeTacheValueList,
	injectFamilleTacheValueList
)(GeneriqueVarianteSubPanel)
