import { Grid, Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { reduxForm, change, Field } from 'redux-form'
import { connect } from 'react-redux'
import { HUMIDITY_STATUS, SOIL_STATUS, TEMPERATURE_STATUS, WEATHER_STATUS, WIND_STATUS, UPDATE_WEATHER_FORM_NAME, WEATHER_TILE_TYPE, UPDATE_WEATHER_FORM_VALUE } from '../tool/contextePedoclimatique.constants'
import { getHumidityIcon, getSoilIcon, getTemperatureIcon, getWeatherIcon, getWindIcon } from '../tool/contextePedoclimatique.utils'
import Button from '../../../../../../components/Button'
import classnames from 'classnames'
import { colors } from '../../../../../../utils/constants'

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		width: '100%',
		padding: 40,
		rowGap: 25,
		maxHeight: 'calc(100vh - 220px)'
	},
	textContainer: {
		columnGap: 25,
		padding: '0px 35px'
	},
	leftText: {
		fontWeight: 400,
		fontSize: 16,
		lineHeight: '20px'
	},
	rightText: {
		fontWeight: 600,
		fontSize: 18,
		lineHeight: '22px'
	},
	descriptionText: {
		fontStyle: 'italic',
		fontWeight: 300,
		fontSize: 12,
		lineHeight: '15px',
		textAlign: 'center'
	},
	iconContainer: {
		rowGap: 25,
		maxHeight: 'calc(100dvh - 365px)',
		overflowY: 'auto'
	},
	actionBtn: {
		minWidth: 140,
		height: 90,
		padding: '8px',
		'&:hover': {
			color: 'white',
			backgroundColor: colors.primary
		},
		'&:active': {
			color: 'white',
			backgroundColor: colors.primary
		},
		color: colors.text,
		fontWeight: 400,
		fontSize: 14,
		lineHeight: '15px',
		textAlign: 'center'
	},
	selectedButton: {
		color: 'white',
		backgroundColor: colors.primary
	},
	inactiveButton: {
		backgroundColor: '#FFF'
	},
	errorText: {
		color: colors.error,
		fontSize: 11,
		padding: '0px 35px'
	}
})

const validate = (values) => {
	const errors = {}

	if (!values[UPDATE_WEATHER_FORM_VALUE]) {
		errors[UPDATE_WEATHER_FORM_VALUE] = { id: 'global.errors.mandatory' }
	}

	return errors
}

/**
 * WeatherUpdateForm
 * @param handleSubmit
 * @param change
 * @param classes
 * @param zone
 * @param type
 * @param value
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const WeatherUpdateForm = ({ handleSubmit, change, classes, zone, type, value, currentValue }) => {

	const getIcon = (iconName) => {
		switch (type) {
			case WEATHER_TILE_TYPE.WEATHER:
				return getWeatherIcon(iconName, 40)
			case WEATHER_TILE_TYPE.HUMIDITY:
				return getHumidityIcon(iconName, 40)
			case WEATHER_TILE_TYPE.WIND:
				return getWindIcon(iconName, 40)
			case WEATHER_TILE_TYPE.TEMPERATURE:
				return getTemperatureIcon(iconName, 40)
			case WEATHER_TILE_TYPE.SOIL:
				return getSoilIcon(iconName, 40)
			default:
				return null
		}
	}

	const renderIconRow = ({ iconName, input }) => {
		const { value, onChange } = input
		const isSelected = iconName === value
		return (
			<Grid key={`${type}.${iconName}`} item container alignItems="center" justify="space-between" className={classes.textContainer}>
				<Button
					type="secondary"
					variant="contained"
					keepCase
					onClick={() => onChange(iconName)}
					className={classnames(classes.actionBtn, isSelected ? classes.selectedButton : classes.inactiveButton)}
				>
					<Grid item xs>
						{getIcon(iconName)}
					</Grid>
					<Grid item xs>
						<FormattedMessage id={`tourPlaine.contextePedoclimatique.icons.${type}.${iconName}.name`} />
					</Grid>
				</Button>
				<Grid item xs>
					<Typography className={classes.descriptionText}>
						<FormattedMessage id={`tourPlaine.contextePedoclimatique.icons.${type}.${iconName}.description`} />
					</Typography>
				</Grid>
			</Grid>
		)
	}

	const renderIcons = ({ input, meta }) => {
		const { error, submitFailed } = meta
		const icons = () => {
			switch (type) {
				case WEATHER_TILE_TYPE.WEATHER:
					return Object.values(WEATHER_STATUS).map(iconName => renderIconRow({ iconName, input, meta }))
				case WEATHER_TILE_TYPE.HUMIDITY:
					return Object.values(HUMIDITY_STATUS).map(iconName => renderIconRow({ iconName, input, meta }))
				case WEATHER_TILE_TYPE.WIND:
					return Object.values(WIND_STATUS).map(iconName => renderIconRow({ iconName, input, meta }))
				case WEATHER_TILE_TYPE.TEMPERATURE:
					return Object.values(TEMPERATURE_STATUS).map(iconName => renderIconRow({ iconName, input, meta }))
				case WEATHER_TILE_TYPE.SOIL:
					return Object.values(SOIL_STATUS).map(iconName => renderIconRow({ iconName, input, meta }))
				default:
					return []
			}
		}

		return (
			<Grid container className={classes.iconContainer}>
				{icons()}
				{(error && submitFailed) && <Typography className={classes.errorText}><FormattedMessage id={error.id} /></Typography>}
			</Grid>
		)
	}

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.form}>
				<Grid item container alignItems="center" justify="flex-start" className={classes.textContainer}>
					<Typography className={classes.leftText}>
						<FormattedMessage id="tourPlaine.contextePedoclimatique.zone" />
					</Typography>
					<Typography className={classes.rightText}>
						<FormattedMessage id={`enums.zoneType.${zone}`} />
					</Typography>
				</Grid>
				<Grid item container alignItems="center" justify="flex-start" className={classes.textContainer}>
					<Typography className={classes.leftText}>
						<FormattedMessage id="tourPlaine.contextePedoclimatique.tileType.title" />
					</Typography>
					<Typography className={classes.rightText}>
						<FormattedMessage id={`tourPlaine.contextePedoclimatique.tileType.${type}`} />
					</Typography>
				</Grid>
				<Field
					name={UPDATE_WEATHER_FORM_VALUE}
					component={renderIcons}
				/>
			</Grid>
		</form>
	)

}

WeatherUpdateForm.propType = {
	dayKey: PropTypes.string,
	dayPeriod: PropTypes.string,
	zone: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.string,
	fullday: PropTypes.bool,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	change: PropTypes.func
}

const mapStateToProps = (state, { dayKey, dayPeriod, zone, type, value, fullday }) => {
	return ({
		initialValues: {
			dayKey,
			dayPeriod,
			zone,
			type,
			value,
			fullday
		}
	})
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		form: UPDATE_WEATHER_FORM_NAME,
		enableReinitialize: true
	}),
	withStyles(styles)
)
(WeatherUpdateForm)
