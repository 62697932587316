import React from 'react';
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { MenuItem } from '@material-ui/core'
import { FIELDS, formattedMessageBase } from '../PopinModeleTache'
import { OUI_NON } from '../../../fo/mobile/utils/constantsSuiviTache'
import Autocomplete from '../../../../components/form/Autocomplete'
import Select from '../../../../components/form/Select'
import { injectFamilleTacheValueList, injectTypeTacheValueList } from '../../../common/valueLists/valueListInjectors'

const DependanceSubPanel = ({
	flagDependance,
	moTacheListDependance,
	classes,
	typeTacheList
}) => {
	return (
		<ExpansionPanel className={classes.expandPanel}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				className={classes.header}
			>
				<FormattedMessage id={`${formattedMessageBase}.dependance`} />
			</ExpansionPanelSummary>
			<Grid container item justify={'flex-start'} alignItems={'center'}>
				<Field
					name={FIELDS.FLAG_DEPENDANCE.name}
					component={Select}
					containerClassName={classes.littleRow}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_DEPENDANCE.name}`} />}
					fromBo
				>
					{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
						{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`} />}
					</MenuItem>)}
				</Field>
				{moTacheListDependance.length > 0 ? flagDependance === OUI_NON.OUI.value && typeTacheList && (
					<Field
						name={FIELDS.REALISEE_AVANT.name}
						component={Autocomplete}
						containerClassName={classes.mediumRow}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.REALISEE_AVANT.name}`} />}
						fromBo
						options={
							moTacheListDependance.map(tache => ({
								code: tache.id,
								label: typeTacheList.find(type => type.code === tache?.typeTache)?.label
							}))
						}
					/>) : flagDependance === OUI_NON.OUI.value &&
					<Grid item className={classes.dependance}>
						<FormattedMessage id={`${formattedMessageBase}.aucuneDependance`} />
					</Grid>
				}
			</Grid>
		</ExpansionPanel>
	)
}

DependanceSubPanel.propType = {
	flagDependance: PropTypes.bool,
	moTacheListDependance: PropTypes.array,
	classes: PropTypes.object,
	typeTacheList: PropTypes.array
}

export default compose(
	injectFamilleTacheValueList,
	injectTypeTacheValueList
)(DependanceSubPanel)
